export const APP_ROUTES = {
  USER: '/user',
  LOGIN: '/login',
  FORGOTPASSWORD: '/forgotpassword',
  HOME: '/home',
  MEETING: '/meeting',
  MEETINGDETAIL: '/meeting/:id',
  VIDEO: '/video',
  PROFILE: '/profile',
  RESETPASSWORD: '/user/change_password',
}

export const COLOR = {
  Primary: '#ed7e24',
  Secondary: '#F9AA2B',
  Success: '#009780',
  SuccessLight: '#e7e1d3',
  Error: '#FF1100',
  ErrorLight: '#FDEDED',
  Focused: '#F57921',
  Hovered: '#ffeee2',
  Black: '#000000',
  White: '#FFFFFF',
  PlaceHolder: '#959697',
  Header: '#222222',
  SubHeader: '#898989',
  TextFieldIcon: '#959697',
  Border: '#D8D8D8'
}