import React, { useEffect, useState } from "react";
import UseToken from "../services/useToken";
import { APP_ROUTES } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import Layout, { PropDialog } from "../components/layout";

export default function Home() {
  const navigate = useNavigate();
  //const { token } = UseToken();
  const [propDialogOpen, setPropDialogOpen] = useState<PropDialog>({ 
    open: false, 
    title: '', 
    name:'',
    content: '', 
    confirmText: '', 
    cancelText: '', 
    onConfirm: () => {}, 
    onCancel: () => {}
  });

  useEffect(() => {
    // Run! Like go get some data from an API.
    // if (!token) {
    //   console.log(`navigate 2 ${token}`);
    //   navigate(APP_ROUTES.LOGIN);
    // }
  }, []);


  return (
    <Layout propDialog={propDialogOpen}>
      <p>hello home</p>
    </Layout>
  );
}
