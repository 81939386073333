import React, { useEffect, useState, useRef } from "react";
import UseToken from "../services/useToken";
import { APP_ROUTES, COLOR } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import Layout, { PropSnackbar, PropDialog } from "../components/layout";
import Table from "@mui/material/Table";
import {
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  Container,
  Divider,
  InputAdornment,
  Switch,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import {
  createMeeting,
  deleteMeeting,
  updateMeeting,
  getMeetingByPage,
} from "../services/api";
import { Meeting as MeetingModel } from "../services/models";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorIcon from "@mui/icons-material/Error";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useMediaQuery } from "@mui/material";
import { resolve } from "dns";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f2f2f2",
    color: COLOR.Black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // borderBottom: "1px solid #d8d8d8",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#f2f2f2",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Meeting() {
  const navigate = useNavigate();
  const { token } = UseToken();
  const [meetingList, setMeetingList] = useState<MeetingModel[] | []>([]);
  const [propSnackbarOpen, setPropSnackbarOpen] = useState<PropSnackbar>({
    open: false,
    type: "success",
    message: "success !!",
  });
  const [propDialogOpen, setPropDialogOpen] = useState<PropDialog>({
    open: false,
    title: "",
    name: "",
    content: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => {},
    onCancel: () => {},
  });

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  const [meetingName, setMeetingName] = useState<string>("");
  const [zoomId, setZoomId] = useState<string>("");
  const [meetingStatus, setMeetingStatus] = useState<string>("");
  const [fullWidth, setFullWidth] = useState<boolean>(true);
  const [onClear, setOnClear] = useState<boolean>(false);

  //create meeting
  const [dialogCreateMeetingOpen, setDialogCreateMeetingOpen] =
    useState<boolean>(false);
  const [meetingTitle, setMeetingTitle] = useState<string>("");
  const [zoomMeetingId, setZoomMeetingId] = useState<string>("");
  const [meetingTitleError, setMeetingTitleError] = useState<boolean>(false);
  const [zoomMeetingIdError, setZoomMeetingIdError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const meetingTitleRef = useRef<HTMLInputElement>(null);
  const zoomMeetingIdRef = useRef<HTMLInputElement>(null);

  const [meetingTitleFocused, setMeetingTitleFocused] = useState<boolean>(false);
  const [zoomMeetingIdFocused, setZoomMeetingIdFocused] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const fetchData = async () => {
    // You can await here
    const _isLoad = isLoading;
    if (!_isLoad) setIsLoading(true);

    console.log("title", meetingName);
    console.log("zoomId", zoomMeetingId);
    console.log("status", meetingStatus);
    const mlist = await getMeetingByPage(
      30,
      page,
      meetingName,
      zoomId,
      meetingStatus
    );
    console.log(mlist);
    if (mlist && !mlist.statusCode) {
      const meetingListPage = mlist.data;
      if (meetingListPage && Array.isArray(meetingListPage)) {
        meetingListPage.sort((a: MeetingModel, b: MeetingModel) =>
          a.id < b.id ? 1 : -1
        );
        setMeetingList(meetingListPage !== null ? meetingListPage : []);
        setTotalPage(mlist.meta.totalPages);
      }
    }
    if (!_isLoad) setIsLoading(false);
  };

  useEffect(() => {
    if (!token) {
      navigate(APP_ROUTES.LOGIN);
    } else {
      fetchData();
    }
  }, [token]);

  const handleOnEditItem = (index: number) => {
    console.log(meetingList[index]);
    //setOpenDialog(true);
    navigate(APP_ROUTES.MEETING + "/" + meetingList[index].id);
  };

  const handleDeleteMeeting = async (id: number) => {
    const result = await deleteMeeting(id);
    if (result) {
      fetchData();
    }
  };

  const handleClickConfirmDialog = (id: number, name: string) => {
    setPropDialogOpen({
      open: true,
      title: "Delete Meeting",
      name: name,
      content: "Do you want to delete the ",
      confirmText: "Delete",
      cancelText: "Cancel",
      onCancel: () => {
        setPropDialogOpen({
          open: false,
          title: "",
          name: "",
          content: "",
          confirmText: "",
          cancelText: "",
          onCancel: () => {},
          onConfirm: () => {},
        });
      },
      onConfirm: () => {
        handleDeleteMeeting(id);
        setPropDialogOpen({
          open: false,
          title: "",
          name: "",
          content: "",
          confirmText: "",
          cancelText: "",
          onCancel: () => {},
          onConfirm: () => {},
        });
      },
    });
  };

  const handlePageChange = (page: any) => {
    setPage(page);
    console.log("page", page);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const handleSearchMeeting = async (e: any) => {
    e.preventDefault();
    console.log("searching...");

    fetchData();
  };

  const handleClearSearch = () => {
    setMeetingName("");
    setZoomId("");
    setMeetingStatus("");
    setOnClear(true);
  };

  useEffect(() => {
    if (onClear) {
      setOnClear(false);
      fetchData();
    }
  }, [onClear]);

  //create meeting
  const handleClickCreateMeeting = () => {
    setMeetingTitle("");
    setZoomMeetingId("");
    setMeetingTitleError(false);
    setZoomMeetingIdError(false);
    setDialogCreateMeetingOpen(true);
  };

  const handleCloseDialogCreateMeeting = () => {
    setDialogCreateMeetingOpen(false);
  };

  const handleSubmitCreateMeeting = async (e: any) => {
    e.preventDefault();
    if (meetingTitle.trim().length < 5 || meetingTitle.trim().length > 50) {
      setMeetingTitleError(true);
      if(meetingTitleRef.current){
        meetingTitleRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }

    if (zoomMeetingId.trim().length < 10 || zoomMeetingId.trim().length > 11) {
      setZoomMeetingIdError(true);
      if(meetingTitle !== ''){
        if(zoomMeetingIdRef.current){
          zoomMeetingIdRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }

    if (
      meetingTitle.trim().length >= 5 &&
      meetingTitle.trim().length <= 50 &&
      zoomMeetingId.trim().length >= 10 &&
      zoomMeetingId.length <= 50
    ) {
      setIsLoading(true);
      const meeting: MeetingModel = {
        id: 0,
        title: meetingTitle.trim(),
        zoomMeetingID: zoomMeetingId.trim(),
        createdBy: 0,
        status: "",
        autoNext: true,
        active: true,
        error: "",
        message: "",
        statusCode: 0,
        isSoundNotify: true,
        soundNotifyTime: 300,
      };
      const res = await createMeeting(meeting);
      if (res && !res.statusCode) {
        console.log("meeting id:", res.id);
        fetchData();
        setPropSnackbarOpen({
          open: true,
          message: "Create meeting success",
          type: "success",
        });
        setDialogCreateMeetingOpen(false);
        setIsLoading(false);
        navigate(APP_ROUTES.MEETING + "/" + res.id);
      } else {
        setPropSnackbarOpen({
          open: true,
          message: "Error! " + (res ? res.message : "Create meeting"),
          type: "error",
        });
      }
      setIsLoading(false);
      setTimeout(() => {
        setPropSnackbarOpen({ open: false, type: "success", message: "" });
      }, 3000);
    }
  };

  const validateMeetingTitle = (value: string) => {
    if (value.length < 5 || value.length > 50) {
      setMeetingTitleError(true);
      if(value.length > 50){
        setMeetingTitle(value.substring(0, 50));
      }
    } else {
      setMeetingTitleError(false);
    }
  };

  const validateZoomId = (value: string) => {
    console.log("value:", value);
    const numberRegex = new RegExp("^[0-9]+$");

    if(value.includes('https://zoom.us/j/')){
      const zoomId = value.split('https://zoom.us/j/')[1];
      const endZoomId = zoomId.indexOf('?pwd=');
      if(endZoomId !== 1){
        setZoomMeetingId(zoomId.substring(0, endZoomId));
      }else{
        setZoomMeetingId(zoomMeetingId);
      }
      setZoomMeetingIdError(false);
    }else if(value.includes('https://us') && value.includes('web.zoom.us/j/')){
      const zoomId = value.split('web.zoom.us/j/')[1];
      const endZoomId = zoomId.indexOf('?pwd=');
      if(endZoomId !== -1){
        setZoomMeetingId(zoomId.substring(0, endZoomId).trim());
      }else{
        setZoomMeetingId(zoomId.trim());
      }
      setZoomMeetingIdError(false);
    }else if(value.includes('Meeting ID:')){
      const zoomId = value.split('Meeting ID:')[1].trim();
      const endZoomId = zoomId.indexOf(' Passcode:');
      if(endZoomId !== -1){
        const idZoom = zoomId.substring(0, endZoomId).trim()
        const valueTrim = idZoom.replace(/\s/g, '');
        setZoomMeetingId(valueTrim);
      }else{
        const idZoom = zoomId.trim()
        const valueTrim = idZoom.replace(/\s/g, '');
        setZoomMeetingId(valueTrim);
      }
      setZoomMeetingIdError(false);
    }else {
      const valueTrim = value.replace(/\s/g, '');
      if(!numberRegex.test(valueTrim)){
        setZoomMeetingIdError(true);
        if(valueTrim.length > 11){
          setZoomMeetingId(valueTrim.substring(0, 11));
        }
      }else{
        if (valueTrim.length < 10 || valueTrim.length > 11) {
          if(valueTrim.length > 11){
            setZoomMeetingId(valueTrim.substring(0, 11));
          }
        } else {
          setZoomMeetingIdError(false);
          if(numberRegex.test(valueTrim)){
            setZoomMeetingIdError(false);
            setZoomMeetingId(valueTrim);
          }else{
            setZoomMeetingIdError(true);
          }
        }
      }
    }
    // validateZoomId(zoomMeetingId);
  };

  useEffect(() => {
    if(zoomMeetingId !== ''){
      console.log('zoomMeetingId', zoomMeetingId);
    }
  }, [zoomMeetingId]);

  const handleSwitchChangeActive = async (e: any, meetingData: MeetingModel) => {
    e.preventDefault();
    setIsLoading(true);
    const data:MeetingModel = {
      id: meetingData.id,
      title: meetingData.title,
      zoomMeetingID: meetingData.zoomMeetingID,
      createdBy: meetingData.createdBy,
      status: meetingData.status,
      autoNext: meetingData.autoNext,
      active: e.target.checked,
      error: "",
      message: "",
      statusCode: 0,
      isSoundNotify: meetingData.isSoundNotify,
      soundNotifyTime: meetingData.soundNotifyTime,
    };
    const result = await updateMeeting(data);
    if (result && !result.statusCode) {
      fetchData();
      setPropSnackbarOpen({
        open: true,
        message: "Update meeting success!",
        type: "success",
      });
    } else {
      setPropSnackbarOpen({
        open: true,
        message: "Error! " + (result ? result.message : "Update meeting"),
        type: "error",
      });
    }
    setIsLoading(false);
    setTimeout(() => {
      setPropSnackbarOpen({ open: false, type: "success", message: "" });
    }, 3000);
  };

  return (
    <Layout propSnackbar={propSnackbarOpen} propDialog={propDialogOpen} isLoading={isLoading}>
      <Dialog
        fullWidth={fullWidth}
        open={dialogCreateMeetingOpen}
        PaperProps={{
          style: {
            position: "fixed",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            top: "0",
            borderRadius: "10px",
            maxWidth: "740px",
          },
        }}
      >
        <Container component="main" style={{ maxWidth: "1200px" }}>
          <Box sx={{ mt: "24px", mb: "24px" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bold" }}
              >
                Create Meeting
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialogCreateMeeting}
              >
                <ClearIcon style={{ color: "#959697" }} />
              </IconButton>
            </Box>
            <Divider style={{ marginTop: "16px", marginBottom: "32px" }} />
            <Box
              component="form"
              onSubmit={(e) => handleSubmitCreateMeeting(e)}
              noValidate
            >
              <TextField
                required
                fullWidth
                inputRef={meetingTitleRef}
                name="meetingTitle"
                label="Meeting Title"
                size="medium"
                type="text"
                value={meetingTitle}
                id="meetingTitle"
                onChange={(e) => {
                  if (
                    e.target.value.includes("<") ||
                    e.target.value.includes(">")
                  )
                    return;
                  setMeetingTitle(e.target.value);
                  validateMeetingTitle(e.target.value);
                }}
                autoComplete="off"
                onFocus={() => setMeetingTitleFocused(true)}
                onBlur={() => setMeetingTitleFocused(false)}
                InputLabelProps={{
                  style:{
                    color: meetingTitleError ? COLOR.Error : meetingTitleFocused ? COLOR.Focused : COLOR.PlaceHolder,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: COLOR.White,
                    "&.Mui-focused fieldset": {
                      borderColor: meetingTitleError
                        ? COLOR.Error
                        : COLOR.Primary,
                    },
                    "&:hover fieldset": {
                      borderColor: meetingTitleError
                        ? COLOR.Error
                        : COLOR.Border,
                    },
                    "&.Mui-focused:hover fieldset": {
                      borderColor: meetingTitleError
                        ? COLOR.Error
                        : COLOR.Primary,
                    },
                  },
                  "& label.Mui-focused": {
                    color: meetingTitleError ? COLOR.Error : COLOR.Primary,
                  },
                  "& fieldset": {
                    borderColor: meetingTitleError ? COLOR.Error : COLOR.Border,
                    borderRadius: '6px'
                  },
                  "& .Mui-error": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: COLOR.Error,
                    },
                  },
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: COLOR.Error,
                  },
                  "& input:-webkit-autofill": {
                    WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                  },
                  "& .MuiFormLabel-asterisk":{
                    color: meetingTitleError ? COLOR.Error : meetingTitleFocused ? COLOR.Focused : COLOR.PlaceHolder,
                  },
                }}
                error={!!meetingTitleError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {meetingTitle && (
                        <>
                          {meetingTitleError ? (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setMeetingTitle("");
                                validateMeetingTitle("");
                              }}
                            >
                              <ErrorIcon style={{ color: COLOR.Error }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setMeetingTitle("");
                                validateMeetingTitle("");
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: COLOR.PlaceHolder
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
                helperText={
                  meetingTitleError
                    ? "Meeting title must be more than 5 characters long and no more than 50 characters."
                    : ""
                }
              />
              <TextField
                required
                fullWidth
                inputRef={zoomMeetingIdRef}
                name="zoomMeetingId"
                label="Zoom Meeting ID"
                size="medium"
                type="text"
                value={zoomMeetingId}
                id="zommMeetingId"
                onChange={(e) => {
                  setZoomMeetingId(e.target.value);
                  validateZoomId(e.target.value);
                }}
                onFocus={() => setZoomMeetingIdFocused(true)}
                onBlur={() => setZoomMeetingIdFocused(false)}
                InputLabelProps={{
                  style:{
                    color: zoomMeetingIdError ? COLOR.Error : zoomMeetingIdFocused ? COLOR.Focused : COLOR.PlaceHolder,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: COLOR.White,
                    "&.Mui-focused fieldset": {
                      borderColor: zoomMeetingIdError
                        ? COLOR.Error
                        : COLOR.Primary,
                    },
                    "&:hover fieldset": {
                      borderColor: zoomMeetingIdError
                        ? COLOR.Error
                        : COLOR.Border,
                    },
                    "&.Mui-focused:hover fieldset": {
                      borderColor: zoomMeetingIdError
                        ? COLOR.Error
                        : COLOR.Primary,
                    },
                  },
                  "& label.Mui-focused": {
                    color: zoomMeetingIdError ? COLOR.Error : COLOR.Primary,
                  },
                  "& fieldset": {
                    borderColor: zoomMeetingIdError
                      ? COLOR.Error
                      : COLOR.Border,
                    borderRadius: '6px'
                  },
                  "& .Mui-error": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: COLOR.Error,
                    },
                  },
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: COLOR.Error,
                  },
                  "& input:-webkit-autofill": {
                    WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                  },
                  "& .MuiFormLabel-asterisk":{
                    color: zoomMeetingIdError ? COLOR.Error : zoomMeetingIdFocused ? COLOR.Focused : COLOR.PlaceHolder,
                  },
                  mt: "32px",
                  mb: "32px",
                }}
                error={!!zoomMeetingIdError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {zoomMeetingId && (
                        <>
                          {zoomMeetingIdError ? (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setZoomMeetingId("");
                                validateZoomId("");
                              }}
                            >
                              <ErrorIcon style={{ color: COLOR.Error }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setZoomMeetingId("");
                                validateZoomId("");
                              }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                color: COLOR.PlaceHolder
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </>
                      )}
                    </InputAdornment>
                  ),
                }}
                helperText={
                  zoomMeetingIdError
                    ? "Zoom ID must be valid and contain 10-11 Digits."
                    : ""
                }
              />

              <Grid
                container
                spacing={2}
                direction={isSmallScreen ? "column-reverse" : "row"}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{
                      color: COLOR.Primary,
                      borderColor: COLOR.Primary,
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "17px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      width: isSmallScreen ? "100%" : "80%",
                      borderRadius: '6px'
                    }}
                    onClick={() => handleCloseDialogCreateMeeting()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: COLOR.Primary,
                      color: COLOR.White,
                      textTransform: "none",
                      boxShadow: "none",
                      fontWeight: "bold",
                      fontSize: "17px",
                      paddingTop: "13px",
                      paddingBottom: "13px",
                      width: isSmallScreen ? "100%" : "80%",
                      borderRadius: '6px'
                    }}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Dialog>

      <Grid container spacing={2} style={{ marginBottom: "24px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            color="inherit"
            noWrap
            style={{ fontWeight: "bold" }}
          >
            Meeting
          </Typography>
          <Typography variant="body1" color="inherit" noWrap>
            Meeting
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            textAlign: "right",
            alignSelf: "center",
          }}
        >
          <Button
            fullWidth={isMediumScreen}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClickCreateMeeting}
            style={{
              backgroundColor: COLOR.Primary,
              fontWeight: "bold",
              textTransform: "none",
              boxShadow: "none",
              borderRadius: "5px",
              fontSize: "17px",
              paddingTop: "13px",
              paddingBottom: "13px",
            }}
          >
            Create Meeting
          </Button>
        </Grid>
      </Grid>

      <Box
        component="form"
        style={{ width: "100%", display: "flex", margin: "10px 0" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <TextField
              id="meetingName"
              label="Meeting Name"
              variant="outlined"
              size="medium"
              type="text"
              value={meetingName}
              style={{ width: "100%" }}
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  backgroundColor: COLOR.White,
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset": {
                    borderColor: COLOR.Primary,
                  },
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                "& fieldset": {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
              onChange={(e) => setMeetingName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="zoomID"
              label="Zoom Meeting ID"
              variant="outlined"
              size="medium"
              type="text"
              value={zoomId}
              style={{ width: "100%" }}
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  backgroundColor: COLOR.White,
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset": {
                    borderColor: COLOR.Primary,
                  },
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                "& fieldset": {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
              onChange={(e) => setZoomId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              style={{ width: "100%" }}
              size="medium"
              variant="outlined"
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset": {
                    borderColor: COLOR.Primary,
                  },
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                "& fieldset": {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{
                  fontSize: 16,
                }}
              >
                Status
              </InputLabel>
              <Select
                label="Status"
                value={meetingStatus}
                onChange={(e: SelectChangeEvent) =>
                  setMeetingStatus(e.target.value)
                }
                MenuProps={{
                  style: {
                    marginTop: "4px",
                    
                  },
                  //set background menu color to white
                  MenuListProps: {
                    style: {
                      padding: "0px",
                      backgroundColor: COLOR.White,
                    },
                  },

                }}
              >
                <MenuItem value={"Waiting"}>Created</MenuItem>
                <MenuItem value={"Start"}>In Meeting</MenuItem>
                <MenuItem value={"End"}>Completed</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={1.5}>
            <Button
              variant="contained"
              style={{
                backgroundColor: COLOR.Primary,
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: "none",
                borderRadius: "6px",
                paddingTop: "13px",
                paddingBottom: "13px",
                width: "100%",
                fontSize: "17px",
              }}
              onClick={handleSearchMeeting}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={12} md={1.5}>
            <Button
              variant="outlined"
              style={{
                backgroundColor: COLOR.White,
                borderColor: COLOR.Primary,
                color: COLOR.Primary,
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: "none",
                borderRadius: "6px",
                border: "1px solid",
                paddingTop: "12px",
                paddingBottom: "12px",
                width: "100%",
                fontSize: "17px",
              }}
              onClick={handleClearSearch}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TableContainer component={Paper} style={{ marginTop: "12px", borderRadius: '0px', boxShadow: 'none'}}>
          <Table sx={{ minWidth: 650, borderBottom: '1px solid #D8D8D8' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: "26%", textAlign: "center" }}>
                  Meeting Title
                </StyledTableCell>
                <StyledTableCell style={{ width: "17%", textAlign: "center" }}>
                  Zoom Meeting ID
                </StyledTableCell>
                <StyledTableCell style={{ width: "15%", textAlign: "center" }}>
                  Status
                </StyledTableCell>
                <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
                  Created By
                </StyledTableCell>
                <StyledTableCell style={{ width: "10%", textAlign: "center" }}>
                  Active
                </StyledTableCell>
                <StyledTableCell style={{ width: "12%", textAlign: "center" }}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meetingList ? (
                meetingList.map((row, index) => (
                  <StyledTableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell
                      component="th"
                    >
                      <Typography
                        style={{
                          maxWidth: 250,
                          fontSize: "14px",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                        }}
                      >
                        {row.title}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      {row.zoomMeetingID}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        textAlign: "center",
                        color:
                          row.status.toLowerCase() == "waiting"
                            ? COLOR.Secondary
                            : row.status.toLowerCase() == "start"
                            ? COLOR.Focused
                            : COLOR.Success,
                      }}
                    >
                      {row.status.toLowerCase() === "waiting"
                        ? "Created"
                        : row.status.toLowerCase() === "start"
                        ? "In Meeting"
                        : "Completed"}
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "left" }}>
                       {row.createdUser ? row.createdUser.name : "-"}
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "center" }}>
                      <Switch
                        checked={row.active}
                        onChange={(e) => handleSwitchChangeActive(e, row)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ display: "flex", justifyContent: "center", padding: '25px' }}
                    >
                      {row.status.toLowerCase() === "waiting" ? (
                        <>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleOnEditItem(index)}
                          >
                            <EditIcon style={{ color: COLOR.Primary }} />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleOnEditItem(index)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={totalPage}
          style={{
            display: "flex",
            marginTop: "24px",
          }}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                color: COLOR.White,
                backgroundColor: COLOR.Primary,
              },
            },
            alignSelf: "end",
          }}
          onChange={(event, page) => handlePageChange(page)}
          defaultPage={1}
        />
      </Box>
    </Layout>
  );
}
