import React, { useEffect, useState } from "react";
import UseToken from "../services/useToken";
import { APP_ROUTES, COLOR } from "../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Layout, { PropSnackbar, PropDialog } from "../components/layout";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { getMeetingByID, updateMeeting } from "../services/api";
import { Agenda } from "../services/models";
import AgendaList from "../components/agendaList";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import {
  InputAdornment,
  FormControlLabel,
  Switch,
  useMediaQuery,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InputMask from "react-input-mask";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function MeetingDetail() {
  const navigate = useNavigate();
  const { token } = UseToken();
  const params = useParams();
  const [editMode] = useState<boolean>(params.id !== "new");
  const [meetingID, setMeetingID] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [propSnackbarOpen, setPropSnackbarOpen] = useState<PropSnackbar>({
    open: false,
    type: "",
    message: "",
  });
  const [propDialogOpen, setPropDialogOpen] = useState<PropDialog>({
    open: false,
    title: "",
    name: "",
    content: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => {},
    onCancel: () => {},
  });

  //edit meeting title & zoom id
  const [oldMeetingTitle, setOldMeetingTitle] = useState<string>("");
  const [oldZoomMeetingId, setOldZoomMeetingId] = useState<string>("");
  const [meetingTitle, setMeetingTitle] = useState<string>("");
  const [zoomMeetingId, setZoomMeetingId] = useState<string>("");
  const [meetingTitleError, setMeetingTitleError] = useState<boolean>(false);
  const [zoomMeetingIdError, setZoomMeetingIdError] = useState<boolean>(false);
  const [autoNext, setAutoNext] = useState<boolean>(false);
  const [warningTime, setWarningTime] = useState<number>(0);
  const [soundNotifyTime, setSoundNotifyTime] = useState<string>("00:00:00");
  const [soundNotifyTimeError, setSoundNotifyTimeError] = useState<string>("");
  const [isSoundNotify, setIsSoundNotify] = useState<boolean>(true);

  const [agendaList, setAgendaList] = useState<Agenda[] | []>([]);

  const [meetingTitleFocused, setMeetingTitleFocused] = useState<boolean>(false);
  const [zoomMeetingIdFocused, setZoomMeetingIdFocused] = useState<boolean>(false);
  const [warningTimeFocused, setWarningTimeFocused] = useState<boolean>(false);

  const [active, setActive] = useState<boolean>(true);

  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const ZOOM_APP_PUBLIC_URL = process.env.ZOOM_APP_PUBLIC_URL || "https://raipoong.focalsolution.com/zoomappapi/zoomapp/proxy/"
  
  const [meetingPublicLink, setMeetingPublicLink] = useState<string>("")

  const NotifyTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#1C2025',
      padding: '8px'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#1C2025'
    },
  }));
  const notifyTipText = (
    <Box>
      หมายเหตุ:<br />
      - เมื่อถึงเวลาแจ้งเตือนที่กำหนดไว้ จะมีสัญญาณเตือน 1 ครั้ง<br />
      - เมื่อหมดระยะเวลาวาระการประชุม จะมีสัญญาณเตือน 2 ครั้ง (ยกเว้นวาระออกกำลังกาย)
    </Box>
  );

  const fetchData = async (id: number) => {
    // You can await here
    const meeting = await getMeetingByID(id);
    if (meeting) {
      console.log("meeting", meeting);
      setOldMeetingTitle(meeting.title);
      setOldZoomMeetingId(meeting.zoomMeetingID);
      setMeetingTitle(meeting.title);
      setZoomMeetingId(meeting.zoomMeetingID);
      setMeetingPublicLink(`${ZOOM_APP_PUBLIC_URL}?zoomMeetingID=${meeting.zoomMeetingID}`)
      setAutoNext(meeting.autoNext);
      setActive(meeting.active);
      setIsSoundNotify(meeting.isSoundNotify);
      //notfy time in number
      setWarningTime(meeting.soundNotifyTime);
      
      const time = meeting.soundNotifyTime;
      const hour = Math.floor(time / 3600);
      const minute = Math.floor((time - hour * 3600) / 60);
      const second = time - hour * 3600 - minute * 60;
      const timeStr =
        (hour < 10 ? "0" + hour : hour) +
        ":" +
        (minute < 10 ? "0" + minute : minute) +
        ":" +
        (second < 10 ? "0" + second : second);
      //notify time in format "00:00:00"
      setSoundNotifyTime(timeStr);

      if (meeting.status.toLowerCase() === "waiting") {
        setIsWaiting(true);
      }
    }
  };

  const getAgendaList = (agendas: Agenda[]) =>{
    setAgendaList(agendas ? agendas : []);
  }

  useEffect(() => {
    // Run! Like go get some data from an API.
    if (!token) {
      console.log(`navigate 2 ${token}`);
      navigate(APP_ROUTES.LOGIN);
    }
    if (editMode && params.id) {
      fetchData(parseInt(params.id, 10));
      setMeetingID(parseInt(params.id, 10));
    }
  }, [token]);

  const handleSubmit = async (e: any, auto: boolean, playSound: boolean) => {
    if(!e){
      return;
    }
    e.preventDefault();
    console.log("handlesubmit...");

    if (meetingTitle.trim() === "" && zoomMeetingId.trim() === "") {
      setMeetingTitleError(true);
      setZoomMeetingIdError(true);
    } else if (
      meetingTitle.trim().length < 5 ||
      meetingTitle.trim().length > 50
    ) {
      setMeetingTitleError(true);
    } else if (
      zoomMeetingId.trim().length < 10 ||
      zoomMeetingId.trim().length > 11
    ) {
      setZoomMeetingIdError(true);
    }else if(soundNotifyTimeError !== ""){
      return;
    }else {
      const timeArr = soundNotifyTime.split(":");
      const hour = parseInt(timeArr[0]);
      const minute = parseInt(timeArr[1]);
      const second = parseInt(timeArr[2]);
      const time = hour * 3600 + minute * 60 + second;

      handleSetLoading(true);
      const res = await updateMeeting({
        zoomMeetingID: zoomMeetingId,
        title: meetingTitle,
        id: meetingID,
        createdBy: 0,
        status: "",
        autoNext: auto,
        active: active,
        error: "",
        message: "",
        statusCode: 0,
        isSoundNotify: playSound,
        soundNotifyTime: time,
      });
      if (res && !res.statusCode) {
        console.log("res", res);
        setOldMeetingTitle(meetingTitle);
        setOldZoomMeetingId(zoomMeetingId);
        fetchData(res.id);
        setTimeout(() => {
          setPropSnackbarOpen({
            open: true,
            type: "success",
            message: "Update Meeting Success!",
          });
        }, 500);
      } else {
        console.log("failed");
        setTimeout(() => {
          setPropSnackbarOpen({
            open: true,
            message: "Error! " + (res ? res.message : "Update Meeting"),
            type: "error",
          });
        }, 500);
      }
      handleSetLoading(false);
      // setTimeout(() => {
      //   setPropSnackbarOpen({ open: false, type: "success", message: "" });
      // }, 3000);
    }
  };

  useEffect(() => {
    setPropSnackbarOpen(propSnackbarOpen);
  }, [propSnackbarOpen]);

  const handleAgendaDelete = (prop: PropDialog) => {
    setPropDialogOpen(prop);
  };

  const handleSetLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const handleGetLoading = ():boolean => {
    return isLoading;
  }

  const handleSnackbarAgenda = (prop: PropSnackbar) => {
    setPropSnackbarOpen(prop);
  };

  const validateMeetingTitle = (value: string) => {
    if (value.length < 5 || value.length > 50) {
      setMeetingTitleError(true);
    } else {
      setMeetingTitleError(false);
    }
  };

  const validateZoomId = (value: string) => {
    console.log("value:", value);
    const numberRegex = new RegExp("^[0-9]+$");
    if (value.includes("https://zoom.us/j/")) {
      console.log("have https://zoom.us/j/");
      const zoomId = value.split("https://zoom.us/j/")[1];
      const endZoomId = zoomId.indexOf("?pwd=");
      if (endZoomId !== 1) {
        setZoomMeetingId(zoomId.substring(0, endZoomId));
      } else {
        setZoomMeetingId(zoomMeetingId);
      }
    } else if (
      value.includes("https://us") &&
      value.includes("web.zoom.us/j/")
    ) {
      console.log("have https://us" + numberRegex + "web.zoom.us/j/");
      const zoomId = value.split("web.zoom.us/j/")[1];
      const endZoomId = zoomId.indexOf("?pwd=");
      if (endZoomId !== -1) {
        setZoomMeetingId(zoomId.substring(0, endZoomId).trim());
      } else {
        setZoomMeetingId(zoomId.trim());
      }
    } else if (value.includes("Meeting ID:")) {
      console.log("have Meeting ID:");
      const zoomId = value.split("Meeting ID:")[1].trim();
      const endZoomId = zoomId.indexOf(" Passcode:");
      if (endZoomId !== -1) {
        console.log("endZoomId", endZoomId);
        console.log(zoomId);
        setZoomMeetingId(zoomId.substring(0, endZoomId).trim());
      } else {
        setZoomMeetingId(zoomId.trim());
      }
    } else {
      const valueTrim = value.replace(/\s/g, "");
      if (!numberRegex.test(valueTrim)) {
        setZoomMeetingIdError(true);
      } else {
        if (valueTrim.length < 10 || valueTrim.length > 11) {
          setZoomMeetingIdError(true);
        } else {
          if(numberRegex.test(valueTrim)){
            setZoomMeetingIdError(false);
            setZoomMeetingId(valueTrim);
          }else{
            setZoomMeetingIdError(true);
          }
        }
      }
    }
  };

  const validateWarningTime = (value: string) => {
    console.log('agendalist in validate: ', agendaList);
    const timeArr = value.split(":");
    const hour = parseInt(timeArr[0]);
    const minute = parseInt(timeArr[1]);
    const second = parseInt(timeArr[2]);
    if (isNaN(hour) || isNaN(minute) || isNaN(second)) {
      setSoundNotifyTimeError("Time is invalid format.");
    } else if(value === '00:00:00'){
      setSoundNotifyTimeError("Time cannot be 00:00:00.");
    }else {
      setSoundNotifyTimeError("");
      agendaList.map((agenda: Agenda) =>{
        console.log('agenda.time: ', agenda.time);
        if(agenda.time < hour*3600 + minute*60 + second){
          setSoundNotifyTimeError("Please enter no more than the topic that takes the least time.");
        }else{
          setSoundNotifyTimeError("");
        }
      })
    }
  };

  useEffect(() => {
    console.log("meeting title: ", meetingTitle);
  }, [meetingTitle]);

  useEffect(() => {
    console.log("zoom meeting id: ", zoomMeetingId);
  }, [zoomMeetingId]);

  const handleToggleAutoNext = (auto: boolean, e:any) =>{
    console.log('auto: ', auto);
    handleSubmit(e, auto, isSoundNotify);
  };

  useEffect(() => {
    console.log("autoNext in useEffect: ", autoNext);
    handleToggleAutoNext(autoNext, null);
  }, [autoNext]);

  useEffect(() =>{
    console.log('agenda list:', agendaList? agendaList : []);
  }, agendaList);

  return (
    <Layout
      propSnackbar={propSnackbarOpen}
      propDialog={propDialogOpen}
      isLoading={isLoading}
    >
      <Box>
        <Typography
          variant="h4"
          color="inherit"
          noWrap
          style={{ fontWeight: "bold" }}
        >
          Meeting
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/meeting">
            <Typography variant="subtitle1">Meeting</Typography>
          </Link>
          <Typography variant="subtitle1" color="text.primary">
            {oldMeetingTitle}
          </Typography>
        </Breadcrumbs>
      </Box>

      <Container
        component="main"
        sx={{ mb: 4 }}
        style={{ maxWidth: "100%", padding: "0", marginTop: "24px" }}
      >
        <Paper
          variant="outlined"
          sx={{ p: "24px" }}
          style={{
            minHeight: "72vh",
          }}
        >
          <Box
            component="form"
            onSubmit={(e) => handleSubmit(e, autoNext, isSoundNotify)}
            noValidate
            sx={{ mt: 2}}
            style={{ marginTop: 0 }}
          >
            <Box style={{marginBottom: '24px'}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    style={{ fontWeight: "bold" }}
                  >
                    Meeting Information
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!isWaiting}
                        checked={isSoundNotify}
                        onChange={(e) => {
                          handleSubmit(e, autoNext, !isSoundNotify);
                        }}
                      />
                    }
                    label="Topic notification sound"
                    labelPlacement="start"
                    sx={{ fontSize: "14px", color: COLOR.SubHeader }}
                  />
                </Grid>

                <Grid item xs={12} md={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!isWaiting}
                        checked={autoNext}
                        onChange={(e) => {
                          handleSubmit(e, !autoNext, isSoundNotify);
                        }}
                      />
                    }
                    label="Next topic automatically"
                    labelPlacement="start"
                    sx={{ fontSize: "14px", color: COLOR.SubHeader }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  name="meetingTitle"
                  label="Meeting Title"
                  type="text"
                  value={meetingTitle}
                  id="meetingTitle"
                  disabled={!isWaiting}
                  onChange={(e) => {
                    if (
                      e.target.value.includes("<") ||
                      e.target.value.includes(">")
                    )
                      return;
                    setMeetingTitle(e.target.value);
                    validateMeetingTitle(e.target.value);
                  }}
                  autoComplete="current-meetingTitle"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: COLOR.White,
                      "&.Mui-focused fieldset": {
                        borderColor: meetingTitleError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                      "&:hover fieldset": {
                        borderColor: meetingTitleError
                          ? COLOR.Error
                          : COLOR.Border,
                      },
                      "&.Mui-focused:hover fieldset": {
                        borderColor: meetingTitleError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                    },
                    "& label.Mui-focused": {
                      color: meetingTitleError ? COLOR.Error : COLOR.Primary,
                    },
                    "& fieldset": {
                      borderColor: meetingTitleError
                        ? COLOR.Error
                        : COLOR.Border,
                      borderRadius: '6px'
                    },
                    "& .Mui-error": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR.Error,
                      },
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                      color: COLOR.Error,
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                    },
                    "& .MuiFormLabel-asterisk":{
                      color: meetingTitleError ? COLOR.Error : meetingTitleFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                  error={!!meetingTitleError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isWaiting && (
                          <>
                            {meetingTitleError ? (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  validateMeetingTitle("");
                                  setMeetingTitle("");
                                }}
                              >
                                <ErrorIcon style={{ color: COLOR.Error }} />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  validateMeetingTitle("");
                                  setMeetingTitle("");
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: COLOR.PlaceHolder
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            )}
                          </>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    meetingTitleError
                      ? "Meeting title must be contain 5-50 characters."
                      : ""
                  }
                  onFocus={() => setMeetingTitleFocused(true)}
                  onBlur={(e) => {
                    setMeetingTitleFocused(false);
                    handleSubmit(e, autoNext, isSoundNotify);
                  }}
                  InputLabelProps={{
                    style:{
                      color: meetingTitleError ? COLOR.Error : meetingTitleFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  name="zoomMeetingId"
                  label="Zoom Meeting ID"
                  type="text"
                  value={zoomMeetingId}
                  id="zoomMeetingId"
                  disabled={!isWaiting}
                  onChange={(e) => {
                    setZoomMeetingId(e.target.value);
                    validateZoomId(e.target.value);
                  }}
                  autoComplete="current-zoomId"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: COLOR.White,
                      "&.Mui-focused fieldset": {
                        borderColor: zoomMeetingIdError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                      "&:hover fieldset": {
                        borderColor: zoomMeetingIdError
                          ? COLOR.Error
                          : COLOR.Border,
                      },
                      "&.Mui-focused:hover fieldset": {
                        borderColor: zoomMeetingIdError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                    },
                    "& label.Mui-focused": {
                      color: zoomMeetingIdError ? COLOR.Error : COLOR.Primary,
                    },
                    "& fieldset": {
                      borderColor: zoomMeetingIdError
                        ? COLOR.Error
                        : COLOR.Border,
                      borderRadius: '6px'
                    },
                    "& .Mui-error": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR.Error,
                      },
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                      color: COLOR.Error,
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                    },
                    "& .MuiFormLabel-asterisk":{
                      color: zoomMeetingIdError ? COLOR.Error : zoomMeetingIdFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                  error={!!zoomMeetingIdError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isWaiting && (
                          <>
                            {zoomMeetingIdError ? (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setZoomMeetingId("");
                                  validateZoomId("");
                                }}
                              >
                                <ErrorIcon style={{ color: COLOR.Error }} />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setZoomMeetingId("");
                                  validateZoomId("");
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: COLOR.PlaceHolder
                                }}
                              >
                                <CancelIcon />
                              </IconButton>
                            )}
                          </>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    zoomMeetingIdError
                      ? "Zoom ID must be valid and contain 10-11 Digits."
                      : ""
                  }
                  onFocus={() => setZoomMeetingIdFocused(true)}
                  onBlur={(e) => {
                    setZoomMeetingIdFocused(false);
                    handleSubmit(e, autoNext, isSoundNotify);
                  }}
                  InputLabelProps={{
                    style:{
                      color: zoomMeetingIdError ? COLOR.Error : zoomMeetingIdFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  name="MeetingPublicLink"
                  label="Meeting Public URL"
                  type="text"
                  value={meetingPublicLink}
                  id="meetingPublicUrl"
                  disabled={true}
                  autoComplete="current-zoomId"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: COLOR.White,
                    },
                    "& fieldset": {
                      borderColor: COLOR.Border,
                      borderRadius: '6px'
                    },
                    "& .Mui-error": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR.Error,
                      },
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                      color: COLOR.Error,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center'}}>
                <InputMask
                  mask="99:99:99"
                  value={soundNotifyTime}
                  onChange={(e) => {
                    setSoundNotifyTime(e.target.value);
                    validateWarningTime(e.target.value);
                  }}
                  onFocus={() => setWarningTimeFocused(true)}
                  onBlur={(e) => {
                    setWarningTimeFocused(false);
                    handleSubmit(e, autoNext, isSoundNotify);
                  }}
                  disabled={!isWaiting || !isSoundNotify}
                >
                  <TextField
                    required
                    fullWidth
                    id="warningTime"
                    label="Notification before time out (hh:mm:ss Ex. 00:30:15 (30 Minutes 15 seconds))"
                    name="warningTime"
                    error={!!soundNotifyTimeError}
                    helperText={soundNotifyTimeError}
                    InputLabelProps={{
                      style:{
                        color: soundNotifyTimeError ? COLOR.Error : warningTimeFocused ? COLOR.Focused : COLOR.PlaceHolder,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: COLOR.White,
                        "&.Mui-focused fieldset": {
                          borderColor: soundNotifyTimeError
                            ? COLOR.Error
                            : COLOR.Primary,
                        },
                        "&:hover fieldset": {
                          borderColor: soundNotifyTimeError
                            ? COLOR.Error
                            : COLOR.Border,
                        },
                        "&.Mui-focused:hover fieldset": {
                          borderColor: soundNotifyTimeError
                            ? COLOR.Error
                            : COLOR.Primary,
                        },
                      },
                      "& label.Mui-focused": {
                        color: soundNotifyTimeError ? COLOR.Error : COLOR.Primary,
                      },
                      "& fieldset": {
                        borderColor: soundNotifyTimeError
                          ? COLOR.Error
                          : COLOR.Border,
                        borderRadius: '6px'
                      },
                      "& .Mui-error": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: COLOR.Error,
                        },
                      },
                      "& .MuiFormHelperText-root.Mui-error": {
                        color: COLOR.Error,
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                      },
                      "& .MuiFormLabel-asterisk":{
                        color: soundNotifyTimeError ? COLOR.Error : warningTimeFocused ? COLOR.Focused : COLOR.PlaceHolder,
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          <InputAdornment
                            position="end"
                            style={{ display: soundNotifyTime ? "" : "none" }}
                          >
                            {soundNotifyTime && isSoundNotify &&(
                              <>
                                {soundNotifyTimeError ? (
                                  <IconButton
                                    edge="end"
                                    onClick={() => {
                                      setSoundNotifyTime("00:05:00");
                                      setSoundNotifyTimeError("");
                                    }}
                                  >
                                    <ErrorIcon style={{ color: COLOR.Error }} />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    edge="end"
                                    onClick={() => {
                                      setSoundNotifyTime("00:05:00");
                                      setSoundNotifyTimeError("");
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: COLOR.PlaceHolder
                                    }}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                )}
                              </>
                            )}
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                </InputMask>
                <NotifyTooltip 
                  title={notifyTipText} 
                  placement='top-start' 
                  arrow
                >
                  <InfoOutlinedIcon style={{color: COLOR.Primary}} sx={{mx: '8px'}}/>
                </NotifyTooltip>
              </Grid>
            </Grid>
          </Box>

          {editMode ? (
            <AgendaList
              meetingID={meetingID}
              propSnackbar={handleSnackbarAgenda}
              propConfirmDialog={handleAgendaDelete}
              isWaiting={isWaiting}
              setLoading={handleSetLoading}
              getLoading={handleGetLoading}
              getAgendaList={getAgendaList}
              soundNotifyTime={warningTime}
              isSoundNotify={isSoundNotify}
            ></AgendaList>
          ) : null}
        </Paper>
      </Container>
    </Layout>
  );
}
