import React, { ReactNode, useEffect, useState, useRef } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { menuListItemsSuperAdmin, menuListItemsAdmin } from "./menuListItems";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES, COLOR } from "../utils/constants";
import UseToken from "../services/useToken";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Logo from "../assets/images/logo_thaihealth.png";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { User, User as UserModel } from "../services/models";
import { getUserByLogin, updatePassword, updateUsers } from "../services/api";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import {
  TextField,
  Grid,
  useMediaQuery,
  Divider,
  InputLabel,
  Select,
  Modal,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import { FormControl } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Alert = React.forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const theme = createTheme({
  typography: {
    fontFamily: ["Kanit", "sans-serif"].join(","),
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export interface LayoutProp {
  children?: React.ReactNode;
  propSnackbar?: PropSnackbar;
  propDialog?: PropDialog;
  isLoading?: boolean;
}

export interface PropSnackbar {
  type: string;
  message: string;
  open: boolean;
}
export interface PropDialog {
  open: boolean;
  name: string;
  title: string;
  content: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const Layout: React.FC<LayoutProp> = ({
  children,
  propSnackbar,
  propDialog,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { clearToken } = UseToken();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("Create Success!");
  const [snackbarType, setSnackbarType] = useState("success");
  const [openLogoutDialog, setOpenLogoutDialog] = useState<PropDialog>({
    open: false,
    title: "",
    name: "",
    content: "",
    confirmText: "",
    cancelText: "",
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [logout, setLogout] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const { token } = UseToken();
  const [userData, setUserData] = useState<UserModel>({} as UserModel);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [userId, setUserId] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameFocused, setNameFocused] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  const nameRef = useRef<HTMLInputElement>(null);

  // Change Password
  const [dialogSettingOpen, setDialogSettingOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [oldPasswordError, setOldPasswordError] = useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] =
    useState<boolean>(false);
  const [oldPasswordNotCorrect, setOldPasswordNotCorrect] =
    useState<boolean>(false);

  const [oldPasswordFocused, setOldPasswordFocused] = useState<boolean>(false);
  const [newPasswordFocused, setNewPasswordFocused] = useState<boolean>(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState<boolean>(false);

  const oldPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [changePasswordSuccess, setChangePasswordSuccess] =
    useState<boolean>(false);

  const [isUserProfile, setIsUserProfile] = useState<boolean>(false);
  const [isEditUserProfile, setIsEditUserProfile] = useState<boolean>(false);

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [openLoading, setOpenLoading] = React.useState<boolean>(false);
  useEffect(() => {
    setOpenLoading(isLoading || false)
  }, [isLoading, setOpenLoading])

  const fetchUser = async () => {
    // You can await here
    const userData = await getUserByLogin();
    console.log("get user");
    console.log(userData);
    setUserData(userData ? userData : ({} as UserModel));
    if (userData && userData.id) {
      setUserId(userData.id);
      //username -> email
      setEmail(userData.email);
      setName(userData.name);
      setRole(userData.role);
      setOrganization(userData.organization);
      setActive(userData.active);
      setNewPassword("");
      setConfirmPassword("");
      setOldPassword("");
      setChangePasswordSuccess(false);
    }
  };

  useEffect(() => {
    if (propSnackbar) {
      setSnackbarType(propSnackbar.type);
      setSnackbarMsg(propSnackbar.message);
      setOpenSnackbar(propSnackbar.open);
    }
    if (!token) {
      navigate(APP_ROUTES.LOGIN);
    } else {
      fetchUser();
    }
  }, [propSnackbar, dialogSettingOpen]);

  const handleClickLogout = () => {
    setLogout(true);
    setOpenLogoutDialog({
      open: true,
      title: "Logout",
      name: "logout",
      content: "Are you sure want to Logout?",
      confirmText: "Logout",
      cancelText: "Cancel",
      onCancel: () => {
        setOpenLogoutDialog({
          open: false,
          title: "",
          name: "",
          content: "",
          confirmText: "",
          cancelText: "",
          onCancel: () => {},
          onConfirm: () => {},
        });
      },
      onConfirm: () => {
        handleLogout();
        setOpenLogoutDialog({
          open: false,
          title: "",
          name: "",
          content: "",
          confirmText: "",
          cancelText: "",
          onCancel: () => {},
          onConfirm: () => {},
        });
      },
    });
  };

  const handleLogout = () => {
    clearToken();
    navigate(APP_ROUTES.LOGIN);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  //change password
  const handleClickChangePassword = () => {
    if (!token) {
      navigate(APP_ROUTES.LOGIN);
    } else {
      setIsUserProfile(false);
      setDialogSettingOpen(true);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const handleSubmitChangePassword = async (e: any) => {
    e.preventDefault();
    if (!token) {
      navigate(APP_ROUTES.LOGIN);
    } else {
      if (oldPassword === "") {
        setOldPasswordError(true);
        if(oldPasswordRef && oldPasswordRef.current){
          oldPasswordRef.current.scrollIntoView({ behavior: "smooth", block: 'center'})
        }
      }
      if (newPassword === "") {
        setNewPasswordError(true);
        if(oldPassword !== '' && oldPasswordNotCorrect === false){
          if(newPasswordRef && newPasswordRef.current){
            newPasswordRef.current.scrollIntoView({ behavior: "smooth", block: 'center' })
          }
        }
      }
      if(confirmPassword === "") {
        setConfirmPasswordError(true);
        if((oldPassword !== '' || oldPasswordNotCorrect === false) && newPassword !== ''){
          if(confirmPasswordRef && confirmPasswordRef.current){
            confirmPasswordRef.current.scrollIntoView({ behavior: "smooth", block: 'center' })
          }
        }
      }
      if(oldPassword !== "" && newPassword !== "" && confirmPassword !== "" && newPassword === confirmPassword){
        setOpenLoading(true);
        const data: User = {
          password: oldPassword,
          confirmPassword: confirmPassword,
          id: userData.id,
          email: userData.email,
          name: userData.name,
          role: userData.role,
          organization: userData.organization,
          active: active,
          error:"",
          message:"",
          statusCode:0,
        };
        const res = await updatePassword(data);
        if(res){
          setChangePasswordSuccess(true);
        }else{
          setChangePasswordSuccess(false);
          setOldPasswordNotCorrect(true);
        }
        setOpenLoading(false);
      }
    }
  };

  const setFalseAll = () =>{
    setOldPasswordError(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    setOldPasswordNotCorrect(false);
    setChangePasswordSuccess(false);
    setNameError(false);
    setIsEditUserProfile(false);
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  }

  const validateOldPassword = (value: string) => {
    //validate old password ?
    if (!value) {
      setOldPasswordError(true);
    } else {
      setOldPasswordError(false);
    }
    console.log(oldPasswordError);
  };

  useEffect(() => {
    if(newPassword){
      validateNewPassword(newPassword);
    }
    if(confirmPassword){
      validateConfirmPassword(confirmPassword);
    }
  }, [newPassword, confirmPassword])

  const validateNewPassword = (value: string) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    if (value.length < 8 || !passwordRegex.test(value)) {
      setNewPasswordError(true);
    } else {
      setNewPasswordError(false);
      if(confirmPassword !== ""){
        validateConfirmPassword(confirmPassword);
      }
    }
  };

  const validateConfirmPassword = (value: string) => {
    if (value === "" || value !== newPassword) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
  };

  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleEditUserProfile = async (e: any) => {
    e.preventDefault();

    if (name.trim().length < 5 || name.trim().length > 50) {
      setNameError(true);
      if(nameRef && nameRef.current){
        nameRef.current.scrollIntoView({ behavior: "smooth", block: 'center' });
      }
    } else {
      setOpenLoading(true);
      const data: UserModel = {
        id: userId,
        email: email,
        name: name,
        role: role,
        password: "",
        confirmPassword: "",
        organization: organization,
        active: active,
        error: "",
        message: "",
        statusCode: 0,
      };
      const res = await updateUsers(data);
      if (res) {
        setIsEditUserProfile(false);
        setSnackbarType("success");
        setSnackbarMsg("Update user profile success");
        setOpenSnackbar(true);
        setIsEditUserProfile(false);
        fetchUser();
      } else {
        setIsEditUserProfile(false);
        setSnackbarType("error");
        setSnackbarMsg("Update user profile failed");
        setOpenSnackbar(true);
      }
      setOpenLoading(false);
    }
  };

  const validateName = (value: string) => {
    if (value.trim().length < 5 || value.trim().length > 50) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", paddingRight: "0" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={isSmallScreen ? false : open}
          style={{
            backgroundColor: COLOR.White,
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Toolbar style={{ padding: "0 12px" }}>
            <img src={Logo} style={{ height: "60px", marginRight: "15px" }} />
            <Typography
              component="h1"
              variant="h6"
              color={COLOR.Black}
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Raipoong Backoffice
            </Typography>
            <Box>
              <IconButton
                onClick={handleClickMenu}
                style={{
                  display: "flex",
                  alignItems: "space-between",
                  cursor: "pointer",
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <AccountCircleIcon style={{ color: COLOR.Primary }} />
                <Typography
                  style={{
                    display: isSmallScreen ? "none" : "block",
                    marginLeft: "5px",
                    marginRight: "5px",
                    color: COLOR.Black,
                  }}
                >
                  {userData?.name}
                </Typography>
                <KeyboardArrowDownIcon
                  style={{
                    fontSize: "20px",
                  }}
                />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setDialogSettingOpen(true);
                    setIsUserProfile(true);
                    setAnchorEl(null);
                    setFalseAll();
                  }}
                >
                  <PersonIcon
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      color: COLOR.SubHeader,
                    }}
                  />
                  <Typography style={{ color: COLOR.SubHeader }}>
                    Profile
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClickChangePassword();
                    setAnchorEl(null);
                    setFalseAll();
                  }}
                >
                  <LockIcon
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      color: COLOR.SubHeader,
                    }}
                  />
                  <Typography style={{ color: COLOR.SubHeader }}>
                    Change Password
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClickLogout();
                    setAnchorEl(null);
                  }}
                >
                  <LogoutIcon
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      color: COLOR.Error,
                    }}
                  />
                  <Typography style={{ color: COLOR.Error }}>Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Modal
          open={isSmallScreen ? open? true : false : false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1300,
          }}
          
        >
          <Box
            style={{
              backgroundColor: COLOR.PlaceHolder,
            }}
          >

          </Box>
        </Modal>
        <Drawer
          variant="permanent"
          open={open}
          ModalProps={{ keepMounted: true }}
          sx={{
            "& .MuiDrawer-paper": {
              width: open ? 240 : "89px",
              position: isSmallScreen? open? 'fixed' : 'relative' : 'relative',
              transition: 'width 0.3s',
              zIndex: isSmallScreen? open? 1400 : 0 : 0,
            },
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box sx={{ flex: "1 0 auto" }}>
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "flex-end",
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  <CloseIcon style={{color: COLOR.PlaceHolder}} />
                </IconButton>
              </Toolbar>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                sx={{
                  width: open ? 240 : 85,
                }}
              >
                <IconButton
                  onClick={toggleDrawer}
                  style={{
                    display: open ? "none" : "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    color: COLOR.Primary,
                    marginLeft: "23px",
                  }}
                  sx={{ my: "10px" }}
                >
                  <MenuIcon />
                </IconButton>
                {userData?.role === "admin"
                  ? menuListItemsAdmin
                  : menuListItemsSuperAdmin}
              </List>
            </Box>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container style={{ maxWidth: "2700px", backgroundColor: '#FAFAFA'}} sx={{ py: "24px" }}>
            {children}
          </Container>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
          >
            <Alert severity={snackbarType} sx={{ width: "100%" }}>
              {snackbarMsg}
            </Alert>
          </Snackbar>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => 2000 }}
          open={openLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          fullWidth={fullWidth}
          open={
            propDialog!.open === undefined
              ? false
              : propDialog!.open || openLogoutDialog.open
          }
          PaperProps={{
            style: {
              position: "fixed",
              display: "flex",
              alignItems: "flex-start",
              top: "0",
              maxWidth: "740px",
            },
          }}
        >
          <Container component="main">
            <Box sx={{ mt: "24px", mb: "24px" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontWeight: "bold" }}
                >
                  {openLogoutDialog.title || propDialog!.title}
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={
                    logout ? openLogoutDialog.onCancel : propDialog!.onCancel
                  }
                >
                  <ClearIcon style={{ color: "#959697" }} />
                </IconButton>
              </Box>
              <Divider style={{ marginTop: "15px" }} />
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                  marginBottom: "30px",
                }}
              >
                <Typography>
                  {openLogoutDialog.content || propDialog!.content} &nbsp;
                </Typography>
                {!logout && (
                  <Box style={{ display: "flex" }}>
                    <Typography
                      style={{
                        color: COLOR.Primary,
                      }}
                    >
                      "{propDialog!.name}" &nbsp;
                    </Typography>
                    <Typography>?</Typography>
                  </Box>
                )}
              </Box>
              <Grid
                container
                spacing={2}
                direction={isSmallScreen ? "column-reverse" : "row"}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{
                      color: COLOR.Primary,
                      borderColor: COLOR.Primary,
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "17px",
                      width: isSmallScreen ? "100%" : "80%",
                      borderRadius: '6px'
                    }}
                    sx={{ py: "12px" }}
                    onClick={
                      logout ? openLogoutDialog.onCancel : propDialog!.onCancel
                    }
                  >
                    {propDialog!.cancelText || openLogoutDialog.cancelText}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: COLOR.Error,
                      color: COLOR.White,
                      textTransform: "none",
                      boxShadow: "none",
                      fontWeight: "bold",
                      fontSize: "17px",
                      width: isSmallScreen ? "100%" : "80%",
                      borderRadius: '6px'
                    }}
                    sx={{ py: "13px" }}
                    onClick={
                      logout
                        ? openLogoutDialog.onConfirm
                        : propDialog!.onConfirm
                    }
                  >
                    {propDialog!.confirmText || openLogoutDialog.confirmText}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Dialog>

        <Dialog
          fullWidth={fullWidth}
          open={dialogSettingOpen}
          PaperProps={{
            style: {
              position: "fixed",
              display: "flex",
              alignItems: "flex-start",
              top: "0",
              borderRadius: "10px",
              maxWidth: "740px",
            },
          }}
        >
          <Container component="main" maxWidth="xl">
            <Box sx={{ mt: "24px", mb: "24px" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  component="h1"
                  variant="h6"
                  style={{ fontWeight: "bold" }}
                >
                  {isUserProfile ? "Profile" : "Change password"}
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    changePasswordSuccess
                      ? handleLogout()
                      : setDialogSettingOpen(false)
                  }}
                >
                  <ClearIcon style={{ color: "#959697" }} />
                </IconButton>
              </Box>
              <Divider style={{ marginTop: "16px", marginBottom: "32px" }} />
              {isUserProfile ? (
                <Box
                  component="form"
                  sx={{ mb: "24px" }}
                  onSubmit={handleEditUserProfile}
                  noValidate
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      onClick={() => setIsEditUserProfile(true)}
                      style={{
                        padding: "0px",
                      }}
                      sx={{
                        "&:hover": {
                          backgroundColor: COLOR.White,
                        },
                      }}
                    >
                      <EditIcon
                        style={{ color: COLOR.Primary, fontSize: "20px" }}
                      />
                      <Typography
                        style={{
                          alignItems: "center",
                          marginLeft: "2px",
                          color: COLOR.Primary,
                        }}
                      >
                        Edit Profile
                      </Typography>
                    </IconButton>
                  </Box>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    inputRef={nameRef}
                    autoFocus
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      validateName(e.target.value);
                    }}
                    disabled={!isEditUserProfile}
                    error={!!nameError}
                    helperText={
                      nameError
                        ? "Name must be more than 5 characters and no more than 50 characters"
                        : ""
                    }
                    onFocus={() => setNameFocused(true)}
                    onBlur={() => setNameFocused(false)}
                    InputLabelProps={{
                      style:{
                        color: nameError ? COLOR.Error : nameFocused ? COLOR.Focused : COLOR.PlaceHolder,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: COLOR.White,
                        "&.Mui-focused fieldset": {
                          borderColor: nameError ? COLOR.Error : COLOR.Primary,
                        },
                        "&:hover fieldset": {
                          borderColor: nameError ? COLOR.Error : COLOR.Border,
                        },
                        "&.Mui-focused:hover fieldset": {
                          borderColor: nameError ? COLOR.Error : COLOR.Primary,
                        },
                      },
                      "& label.Mui-focused": {
                        color: nameError ? COLOR.Error : COLOR.Primary,
                      },
                      "& fieldset": {
                        borderColor: nameError ? COLOR.Error : COLOR.Border,
                        borderRadius: '6px'
                      },
                      "& .Mui-error": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: COLOR.Error,
                        },
                      },
                      "& .MuiFormHelperText-root.Mui-error": {
                        color: COLOR.Error,
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                      },
                      "& .MuiFormLabel-asterisk":{
                        color: nameError ? COLOR.Error : nameFocused ? COLOR.Focused : COLOR.PlaceHolder,
                      },
                      mb: "32px",
                    }}
                  />

                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    value={email}
                    disabled
                    sx={{
                      "& fieldset": {
                        borderColor: COLOR.Border,
                        borderRadius: '6px'
                      },
                      mb: "32px",
                    }}

                  />

                  <TextField
                    required
                    fullWidth
                    id="organization"
                    label="Organization"
                    name="organization"
                    value={organization}
                    disabled
                    sx={{
                      "& fieldset": {
                        borderColor: COLOR.Border,
                      },
                      mb: "32px",
                    }}
                  />

                  <FormControl
                    fullWidth
                    disabled
                    sx={{
                      "& fieldset": {
                        borderColor: COLOR.Border,
                        borderRadius: '6px'
                      },
                      mb: isEditUserProfile ? "32px" : "8px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={role}
                      label="Role"
                    >
                      <MenuItem value={"admin"}>Admin</MenuItem>
                      <MenuItem value={"super_admin"}>Super Admin</MenuItem>
                    </Select>
                  </FormControl>
                  {isEditUserProfile && (
                    <Grid
                      container
                      spacing={2}
                      direction={isSmallScreen ? "column-reverse" : "row"}
                    >
                      <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{
                            color: COLOR.Primary,
                            borderColor: COLOR.Primary,
                            textTransform: "none",
                            fontWeight: "bold",
                            fontSize: "17px",
                            width: isSmallScreen ? "100%" : "80%",
                            borderRadius: '6px'
                          }}
                          sx={{ py: "12px" }}
                          onClick={() => setDialogSettingOpen(false)}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          style={{
                            backgroundColor: COLOR.Primary,
                            color: COLOR.White,
                            textTransform: "none",
                            boxShadow: "none",
                            fontWeight: "bold",
                            fontSize: "17px",
                            width: isSmallScreen ? "100%" : "80%",
                            borderRadius: '6px'
                          }}
                          sx={{ py: "13px" }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              ) : !changePasswordSuccess ? (
                <Box>
                  {oldPasswordNotCorrect && (
                    <Box
                      style={{
                        display: "flex",
                        backgroundColor: COLOR.ErrorLight,
                        alignItems: "center",
                        width: "100%",
                        padding: "10px 20px",
                        borderRadius: "6px",
                        marginBottom: '32px'
                      }}
                    >
                      <ErrorIcon style={{ color: COLOR.Error }} />
                      <Typography
                        style={{
                          color: COLOR.Error,
                          marginLeft: "10px",
                          fontSize: "14px",
                        }}
                      >
                        Unable to reset password. Please try again
                      </Typography>
                    </Box>
                  )}
                  <Box
                    component="form"
                    onSubmit={(e) => handleSubmitChangePassword(e)}
                    noValidate
                  >
                    <TextField
                      required
                      fullWidth
                      inputRef={oldPasswordRef}
                      name="oldPassword"
                      label="Old password"
                      type={showOldPassword ? "text" : "password"}
                      value={oldPassword}
                      id="oldPassword"
                      onChange={(e) => {
                        if (e.target.value.includes(" ")) {
                          return;
                        }
                        setOldPassword(e.target.value);
                        validateOldPassword(e.target.value);
                      }}
                      autoComplete="current-password"
                      onFocus={() => setOldPasswordFocused(true)}
                      onBlur={() => setOldPasswordFocused(false)}
                      InputLabelProps={{
                        style:{
                          color: oldPasswordError || oldPasswordNotCorrect ? COLOR.Error : oldPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: COLOR.White,
                          "&.Mui-focused fieldset": {
                            borderColor: oldPasswordError || oldPasswordNotCorrect
                              ? COLOR.Error
                              : COLOR.Primary,
                          },
                          "&:hover fieldset": {
                            borderColor: oldPasswordError || oldPasswordNotCorrect
                              ? COLOR.Error
                              : COLOR.Border,
                          },
                          "&.Mui-focused:hover fieldset": {
                            borderColor: oldPasswordError || oldPasswordNotCorrect
                              ? COLOR.Error
                              : COLOR.Primary,
                          },
                        },
                        "& label.Mui-focused": {
                          color: oldPasswordError || oldPasswordNotCorrect ? COLOR.Error : COLOR.Primary,
                        },
                        "& fieldset": {
                          borderColor: oldPasswordError || oldPasswordNotCorrect
                            ? COLOR.Error
                            : COLOR.Border,
                          borderRadius: '6px'
                        },
                        "& .Mui-error": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: COLOR.Error,
                          },
                        },
                        "& .MuiFormHelperText-root.Mui-error": {
                          color: COLOR.Error,
                        },
                        "& .MuiFormLabel-asterisk":{
                          color: oldPasswordError || oldPasswordNotCorrect ? COLOR.Error : oldPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                        },
                      }}
                      error={oldPasswordError || oldPasswordNotCorrect}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {oldPasswordError || oldPasswordNotCorrect ? (
                              <ErrorIcon style={{ color: COLOR.Error }} />
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={handleShowOldPassword}
                                style={{
                                  color: COLOR.PlaceHolder
                                }}
                              >
                                {showOldPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        oldPasswordError
                          ? "Please enter a valid old password."
                          : oldPasswordNotCorrect
                          ? "Old password is incorrect."
                          : ""
                      }
                    />
                    <TextField
                      required
                      fullWidth
                      inputRef={newPasswordRef}
                      name="newPassword"
                      label="New password"
                      type={showNewPassword ? "text" : "password"}
                      value={newPassword}
                      id="newPassword"
                      onChange={(e) => {
                        if (e.target.value.includes(" ")) {
                          return;
                        }
                        setNewPassword(e.target.value);
                        validateNewPassword(e.target.value);
                      }}
                      autoComplete="current-password"
                      onFocus={() => setNewPasswordFocused(true)}
                      onBlur={() => setNewPasswordFocused(false)}
                      InputLabelProps={{
                        style:{
                          color: newPasswordError ? COLOR.Error : newPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: COLOR.White,
                          "&.Mui-focused fieldset": {
                            borderColor: newPasswordError
                              ? COLOR.Error
                              : COLOR.Primary,
                          },
                          "&:hover fieldset": {
                            borderColor: newPasswordError
                              ? COLOR.Error
                              : COLOR.Border,
                          },
                          "&.Mui-focused:hover fieldset": {
                            borderColor: newPasswordError
                              ? COLOR.Error
                              : COLOR.Primary,
                          },
                        },
                        "& label.Mui-focused": {
                          color: newPasswordError ? COLOR.Error : COLOR.Primary,
                        },
                        "& fieldset": {
                          borderColor: newPasswordError
                            ? COLOR.Error
                            : COLOR.Border,
                          borderRadius: '6px'
                        },
                        "& .Mui-error": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: COLOR.Error,
                          },
                        },
                        "& .MuiFormHelperText-root.Mui-error": {
                          color: COLOR.Error,
                        },
                        "& .MuiFormLabel-asterisk":{
                          color: newPasswordError ? COLOR.Error : newPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                        },
                        mt: "32px",
                      }}
                      error={newPasswordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {newPasswordError ? (
                              <ErrorIcon style={{ color: COLOR.Error }} />
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={handleShowNewPassword}
                                style={{
                                  color: COLOR.PlaceHolder
                                }}
                              >
                                {showNewPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        newPasswordError
                          ? "Password must has at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number."
                          : ""
                      }
                    />
                    <TextField
                      required
                      fullWidth
                      inputRef={confirmPasswordRef}
                      name="confirmPassword"
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      id="confirmPassword"
                      onChange={(e) => {
                        if (e.target.value.includes(" ")) {
                          return;
                        }
                        setConfirmPassword(e.target.value);
                        validateConfirmPassword(e.target.value);
                      }}
                      autoComplete="current-password"
                      onFocus={() => setConfirmPasswordFocused(true)}
                      onBlur={() => setConfirmPasswordFocused(false)}
                      InputLabelProps={{
                        style:{
                          color: confirmPasswordError ? COLOR.Error : confirmPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: COLOR.White,
                          "&.Mui-focused fieldset": {
                            borderColor: confirmPasswordError
                              ? COLOR.Error
                              : COLOR.Primary,
                          },
                          "&:hover fieldset": {
                            borderColor: confirmPasswordError
                              ? COLOR.Error
                              : COLOR.Border,
                          },
                          "&.Mui-focused:hover fieldset": {
                            borderColor: confirmPasswordError
                              ? COLOR.Error
                              : COLOR.Primary,
                          },
                        },
                        "& label.Mui-focused": {
                          color: confirmPasswordError
                            ? COLOR.Error
                            : COLOR.Primary,
                        },
                        "& fieldset": {
                          borderColor: confirmPasswordError
                            ? COLOR.Error
                            : COLOR.Border,
                          borderRadius: '6px'
                        },
                        "& .Mui-error": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: COLOR.Error,
                          },
                        },
                        "& .MuiFormHelperText-root.Mui-error": {
                          color: COLOR.Error,
                        },
                        "& .MuiFormLabel-asterisk":{
                          color: confirmPasswordError ? COLOR.Error : confirmPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                        },
                        mt: "32px",
                        mb: "32px",
                      }}
                      error={confirmPasswordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {confirmPasswordError ? (
                              <ErrorIcon style={{ color: COLOR.Error }} />
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={handleShowConfirmPassword}
                                style={{
                                  color: COLOR.PlaceHolder
                                }}
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        confirmPasswordError ? "Password does not match." : ""
                      }
                    />

                    <Grid
                      container
                      spacing={2}
                      direction={isSmallScreen ? "column-reverse" : "row"}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{
                            color: COLOR.Primary,
                            borderColor: COLOR.Primary,
                            textTransform: "none",
                            fontWeight: "bold",
                            fontSize: "17px",
                            width: isSmallScreen ? "100%" : "80%",
                            borderRadius: '6px'
                          }}
                          sx={{ pt: "12px", pb: "12px" }}
                          onClick={() => setDialogSettingOpen(false)}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          fullWidth
                          type="submit"
                          variant="contained"
                          style={{
                            backgroundColor: COLOR.Primary,
                            color: COLOR.White,
                            textTransform: "none",
                            boxShadow: "none",
                            fontWeight: "bold",
                            fontSize: "17px",
                            width: isSmallScreen ? "100%" : "80%",
                            borderRadius: '6px'
                          }}
                          sx={{ pt: "13px", pb: "13px" }}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ my: 3 }}>
                    <CheckCircleIcon
                      style={{ color: COLOR.Success, fontSize: "80px" }}
                    />
                  </Box>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Password changed !
                  </Typography>
                  <Typography
                    style={{ 
                      color: COLOR.SubHeader, 
                      textAlign: 'center',
                    }}
                    sx={{ my: 2, mx: 2 }}
                  >
                    Your password has been changed successfully. Use your new
                    password to login.
                  </Typography>
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      backgroundColor: COLOR.Primary,
                      color: COLOR.White,
                      textTransform: "none",
                      boxShadow: "none",
                      fontWeight: "bold",
                      width: isSmallScreen ? "100%" : "80%",
                      fontSize: '17px',
                      borderRadius: '6px'
                    }}
                    sx={{
                      mb: 2,
                      py: '13px',
                    }}
                    onClick={handleLogout}
                  >
                    Login Now
                  </Button>
                </Box>
              )}
            </Box>
          </Container>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
