import React, { useEffect, useState } from "react";
import UseToken from "../services/useToken";
import { APP_ROUTES, COLOR } from "../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import Layout, { PropSnackbar, PropDialog } from "../components/layout";
import YouTubeIcon from '@mui/icons-material/YouTube';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {Switch, TextField, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, useMediaQuery, Tooltip } from "@mui/material";
import {styled} from '@mui/material/styles';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Pagination from "@mui/material/Pagination";
// import InputMask from "react-input-mask";

import {
  getExerciseVideoByPage,
  createExerciseVideo,
  updateExerciseVideo,
  deleteExerciseVideo,
  updatePublicExerciseVideo,
  createVideoThumbnail
} from "../services/api";
import VideoDetail from "../components/videoDetail";
import { ExerciseVideo } from "../services/models";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f2',
    color: COLOR.Black,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "1px solid #d8d8d8",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#f2f2f2',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Video() {
  const navigate = useNavigate();
  const pathImage = process.env.REACT_APP_API_URL + '/exercise-videos/thumbnail/';
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [fullWidth] = useState<boolean>(true);
  const [exerciseVideoData, setExerciseVideoData] =
    useState<ExerciseVideo | null>(null);
  const { token } = UseToken();
  const [exerciseVideos, setExerciseVideos] = useState<ExerciseVideo[] | []>(
    []
  );
  const [propSnackbarOpen, setPropSnackbarOpen] = useState<PropSnackbar>({
    open: false,
    type: "success",
    message: "success !!",
  });
  const [propDialogOpen, setPropDialogOpen] = useState<PropDialog>({ 
    open: false, 
    title: '', 
    name: '',
    content: '', 
    confirmText: '', 
    cancelText: '', 
    onConfirm: () => {}, 
    onCancel: () => {}
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videoTitle, setVideoTitle] = useState<string>("");
  const [typeVideo, setTypeVideo] = useState<string>("");

  const [videoThumbnails, setVideoThumbnails] = useState<File>();

  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [onClear, setOnClear] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const fetchData = async () => {
    // You can await here
    const _isLoad = isLoading;
    if (!_isLoad) setIsLoading(true);
    console.log('videoTitle', videoTitle);
    console.log('typeVideo', typeVideo);
    const exerciseVideoList = await getExerciseVideoByPage(30, page, videoTitle, typeVideo);
    console.log('video:', exerciseVideoList)
    //sort by id desc
    if(exerciseVideoList && !exerciseVideoList.err){
      console.log(exerciseVideoList);
      const exerciseVideoListPage = exerciseVideoList.data
      if (exerciseVideoListPage && Array.isArray(exerciseVideoListPage)) {
        exerciseVideoListPage.sort((a: ExerciseVideo, b: ExerciseVideo) => {
          return b.id - a.id;
        });
        setExerciseVideos(exerciseVideoListPage);
        setTotalPage(exerciseVideoList.meta.totalPages);
      }
    }else{
      setExerciseVideos([]);
    }

    if (!_isLoad) setIsLoading(false);
  };

  useEffect(() => {
    if (!token) {
      navigate(APP_ROUTES.LOGIN);
    } else {
      fetchData();
    }
  }, []);

  const handleClickOpenDialog = () => {
    setExerciseVideoData(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = (event: any, reason: any) => {
    if (reason == "backdropClick") {
      return;
    }
    setOpenDialog(false);
  };

  const handleClickCancel = () => {
    setOpenDialog(false);
  };

  const handleOnPlayVideo = (index: number) => {
    setExerciseVideoData(exerciseVideos[index]);
  };

  const handleOnPlayYoutube = (index: number) => {
    setExerciseVideoData(exerciseVideos[index]);
  };

  const handleOnEditItem = (index: number) => {
    if (exerciseVideos) {
      console.log(exerciseVideos[index]);
      setExerciseVideoData(exerciseVideos[index]);
      setOpenDialog(true);
    }
  };

  const handleSaveExerciseVideo = async (data: any, thumbnailVideo: File) => {
    setIsLoading(true);
    console.log(`handleSaveExerciseVideo ${JSON.stringify(data)}`);
    setOpenDialog(false);
    if (data.id) {
      console.log('data:', data);
      const exerciseVideo = await updateExerciseVideo(data, data.fileData, data.youtubeUrl);
      
      if (exerciseVideo && (!exerciseVideo.statusCode)) {
        const thumbnailUpdated = await createVideoThumbnail(thumbnailVideo, exerciseVideo.id);
        if(thumbnailUpdated){
          fetchData();
          setPropSnackbarOpen({
            open: true,
            type: "success",
            message: "Update ExerciseVideo Success!",
          });
        }
      } else {
        setPropSnackbarOpen({
          open: true,
          type: "error",
          message: exerciseVideo ? exerciseVideo.message+'' : "Error try again",
        });
      }
    } else {
      const exerciseVideo = await createExerciseVideo(data);
      console.log('videoThumbnail before send api', thumbnailVideo)
      if (exerciseVideo && (!exerciseVideo.statusCode)) {
        const thumbnailCreated = await createVideoThumbnail(thumbnailVideo, exerciseVideo.id);
        if (thumbnailCreated) {
          fetchData();
          setPropSnackbarOpen({
            open: true,
            type: "success",
            message: "Create New ExerciseVideo Success!",
          });
        }
      } else {
        setPropSnackbarOpen({
          open: true,
          type: "error",
          message: exerciseVideo ? exerciseVideo.message+'' : "Error try again.",
        });
      }
    }
    setIsLoading(false);
    setTimeout(() => {
      setPropSnackbarOpen({ open: false, type: "success", message: "" });
    }, 3000);
  };

  const handleDeleteVideo = async (id: number) => {
    setIsLoading(true);
    const result = await deleteExerciseVideo(id);
    if (result) {
      fetchData();
      setPropSnackbarOpen({
        open: true,
        type: "success",
        message: "Delete ExerciseVideo Success!",
      });
    }else{
      setPropSnackbarOpen({
        open: true,
        type: "error",
        message: "Delete ExerciseVideo Failed!",
      });
    }
    setIsLoading(false);
    setTimeout(() => {
      setPropSnackbarOpen({ open: false, type: "success", message: "" });
    }, 3000);
  };

  const handleClickConfirmDialog = (id: number, name: string) =>{
    setPropDialogOpen({
      open: true, 
      title: 'Delete Video', 
      name: name,
      content: 'Do you want to delete the', 
      confirmText: 'Delete', 
      cancelText: 'Cancel',
      onCancel: () => {
        setPropDialogOpen({open: false, title: '', name: '', content: '', confirmText: '', cancelText: '', onCancel: () => {}, onConfirm: () => {}});
      },
      onConfirm: () => {
        handleDeleteVideo(id);
        setPropDialogOpen({open: false, title: '', name: '', content: '', confirmText: '', cancelText: '', onCancel: () => {}, onConfirm: () => {}});
      }
    });
  };

  const handleSwitchChangeActive = async (e: any, videoData: ExerciseVideo) =>{
    setIsLoading(true);
    console.log('handleSwitchChangeActive', e.target.checked, videoData)
    const data:ExerciseVideo = {
      title: videoData.title,
      id: videoData.id,
      videoPath: videoData.videoPath,
      youtubeUrl: videoData.youtubeUrl,
      isPublic: videoData.isPublic,
      fileData: videoData.fileData,
      duration: videoData.duration,
      createdBy: videoData.createdBy,
      videoUrl: videoData.videoUrl,
      videoThumbnail: videoData.videoThumbnail,
      active: e.target.checked,
      error: "",
      message: "",
      statusCode: 0
    }
    console.log('data', data);
    const res = await updateExerciseVideo(data, videoData.fileData, videoData.youtubeUrl);
    if(res){
      setPropSnackbarOpen({
        open: true,
        type: "success",
        message: "Update ExerciseVideo Success!",
      });
      fetchData();
    }else{
      setPropSnackbarOpen({
        open: true,
        type: "error",
        message: "Update ExerciseVideo Fail!",
      });
    }

    setIsLoading(false);

    setTimeout(() => {
      setPropSnackbarOpen({ open: false, type: "success", message: "" });
    }, 3000);
  };

  useEffect(() => {
    setTypeVideo(typeVideo);
  }, [typeVideo])

  const handleSearchVideo = async (e: any) => {
    e.preventDefault();
    fetchData();
  };

  const handleClearSearch = async (e:any) =>{
    e.preventDefault();
    setVideoTitle('');
    setTypeVideo('');
    setOnClear(true);
  };

  useEffect(() =>{
    if(onClear){
      fetchData();
      setOnClear(false);
    }
  }, [onClear]);

  const handlePageChange = (page: number) =>{
    setPage(page);
    console.log('page', page);
  }

  useEffect(() => {
    fetchData();
  }, [page]);

  const handleSetVideoThumbnail = (videoThumbnailFile: File) =>{
    console.log('videoThumbnailFile', videoThumbnailFile);
    setVideoThumbnails(videoThumbnailFile);
  };

  useEffect(() =>{
    console.log('videoThumbnails', videoThumbnails);
  }, [videoThumbnails]);

  return (
    <Layout propSnackbar={propSnackbarOpen} propDialog={propDialogOpen} isLoading={isLoading}>
      <Dialog
        fullWidth={fullWidth}
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            position: "absolute",
            display: "flex",
            alignItems: "flex-start",
            top: "0",
            borderRadius: "10px",
            maxWidth: '740px'
          },
        }}
      >
        <VideoDetail
          saveExerciseVideo={handleSaveExerciseVideo}
          exerciseVideoData={exerciseVideoData}
          onCloseDialog={handleClickCancel}
          handleVideoThumbnail={handleSetVideoThumbnail}
        ></VideoDetail>
      </Dialog>

      <Grid container spacing={2} style={{marginBottom: '24px'}}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" color="inherit" noWrap style={{fontWeight: 'bold'}}>
            Video
          </Typography>
          <Typography variant="body1" color="inherit" noWrap>
            Video
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: "right", alignSelf:'center'}}>
          <Button
            fullWidth={isSmallScreen}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setExerciseVideoData(null)
              setOpenDialog(true)
            }}
            style={{
              backgroundColor: COLOR.Primary,
              fontWeight: "bold",
              textTransform: "none",
              paddingTop: '13px',
              paddingBottom: '13px',
              boxShadow: 'none',
              borderRadius: '5px',
              fontSize: '17px'
            }}
          >
            Create Video
          </Button>
        </Grid>
      </Grid>

      <Box component="form" style={{width: '100%', display:'flex'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <TextField
              id="videoTitle"
              label="Video Title"
              variant="outlined"
              size="medium"
              type="text"
              value={videoTitle}
              style={{ width: "100%"}}
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset":{
                    borderColor: COLOR.Primary,
                  }
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                '& fieldset': {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
              onChange={(e) => setVideoTitle(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl 
              style={{width: '100%'}}
              size='medium'
              variant="outlined"
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset":{
                    borderColor: COLOR.Primary,
                  }
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                '& fieldset': {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
            >
              <InputLabel 
                id="demo-simple-select-label"
              >
                Type Video
              </InputLabel>
              <Select
                label='Type Video'
                value={typeVideo}
                onChange={(e: SelectChangeEvent) => setTypeVideo(e.target.value)}
                MenuProps={{
                  sx:{
                    mt: '4px',
                    '& ul': { bgcolor: COLOR.White },
                  }
                }}
              >
                <MenuItem value={'youtubeUrl'}  >YouTube URL</MenuItem>
                <MenuItem value={'uploadVideo'} >Upload Video</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              style={{
                backgroundColor: COLOR.Primary,
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: 'none',
                borderRadius: '6px',
                paddingTop:'13px',
                paddingBottom:'13px',
                width:'100%',
                fontSize: '17px',
              }}
              onClick={handleSearchVideo}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="outlined"
              style={{
                backgroundColor: COLOR.White,
                borderColor: COLOR.Primary,
                color: COLOR.Primary,
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: 'none',
                borderRadius: '6px',
                border: '1px solid',
                paddingTop:'12px',
                paddingBottom:'12px',
                width:'100%',
                fontSize: '17px'
              }}
              onClick={handleClearSearch}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper} style={{ marginTop: "24px", borderRadius: '0px', boxShadow: 'none' }}>
        <Table sx={{ minWidth: 650, borderBottom: '1px solid #D8D8D8' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{width: '18%', textAlign: 'center'}}>Thumbnail</StyledTableCell>
              <StyledTableCell style={{width: '20%', textAlign: 'center'}}>Video Title</StyledTableCell>
              <StyledTableCell style={{width: '18%', textAlign: 'center'}}>Type Video</StyledTableCell>
              <StyledTableCell style={{width: '20%', textAlign: 'center'}}>Created By</StyledTableCell>
              <StyledTableCell style={{width: '12%', textAlign: 'center'}}>Active</StyledTableCell>
              <StyledTableCell style={{width: '12%', textAlign: 'center'}}>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exerciseVideos.map((row, index) => (
              <StyledTableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell style={{textAlign: 'center'}}>
                  {row.videoThumbnail !== null ? (
                    <img 
                      src={pathImage + row.id + '?' + row.videoThumbnail}
                      style={{height: '48px', width: '48px' , objectFit: 'cover'}} 
                    />
                  ):null
                  }
                </TableCell>
                <TableCell style={{textAlign: 'left'}}>
                  <Typography
                    style={{
                      maxWidth: 200,
                      fontSize: "14px",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                    }}
                  >
                    {row.title}
                  </Typography>
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                  {row.videoPath ? (
                    <Link 
                      to={row.videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Tooltip title='Local video' placement="right-start">
                        <OndemandVideoIcon style={{color: COLOR.Primary}}/>
                      </Tooltip>
                    </Link>
                  ) : (
                      <Link
                        to={row.youtubeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Tooltip title='Youtube video' placement="right-start">
                          <YouTubeIcon style={{color: COLOR.Primary}} />
                        </Tooltip>
                        
                      </Link>
                  )}
                </TableCell>
                <TableCell style={{textAlign: 'left'}}>{row.createdUser? row.createdUser.name : '-'}</TableCell>
                <TableCell style={{textAlign: 'center'}}>
                  <Switch
                    checked={row.active}
                    onChange={(e) => handleSwitchChangeActive(e, row)}
                    inputProps={{ "aria-label": "controlled" }
                    }
                  />
                </TableCell>
                <TableCell align="center" style={{display: 'flex', padding: '25px'}}>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleOnEditItem(index)}
                  >
                    <EditIcon style={{color: COLOR.Primary}} />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      handleClickConfirmDialog(row.id, row.title);
                    }}
                  >
                    <DeleteIcon style={{color: COLOR.Error}} />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Pagination
          count={totalPage}
          style={{
            display:'flex',
            marginTop: "24px",
          }}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              '&.Mui-selected': {
                color: COLOR.White,
                backgroundColor: COLOR.Primary,
              }
            },
            alignSelf: 'end'
          }}
          onChange={(event, page) => handlePageChange(page)}
          defaultPage={1}
        />
      </Box>
    </Layout>
  );
}
