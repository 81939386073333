import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { login, resendEmail } from "../services/api";
import { APP_ROUTES, COLOR } from "../utils/constants";
import { AppBar } from "@mui/material";
import logo from "../assets/images/logo_thaihealth.png";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorIcon from "@mui/icons-material/Error";
import { inputLabelClasses } from "@mui/material";

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Kanit", "sans-serif"].join(","),
  },
});
type LoginProp = {
  setToken: Function;
};
export default function Login({ setToken }: LoginProp) {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isSendEmailSuccess, setIsSendEmailSuccess] = useState<boolean>(false);

  const [emailToResetPassword, setEmailToResetPassword] = useState<string>("");
  const [emailToResetPasswordError, setEmailToResetPasswordError] =
    useState<string>("");

  const [emailFocused, setEmailFocused] = useState<boolean>(false);
  const [passwordFocused, setPasswordFocused] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    console.log("validate...");
    const checkEmail: boolean = validateEmail();
    const checkPassword: boolean = validatePassword();

    if (checkEmail && checkPassword) {
      console.log("handleSubmit");
      const token = await login({
        //username -> email
        email: email,
        password: password,
        id: 0,
        confirmPassword: "",
        name: "",
        role: "",
        organization: "",
        active: true,
        error: "",
        message: "",
        statusCode: 0,
      });
      if (token && token.statusCode) {
        console.log("login failed.");
        setEmailError("Email or password is wrong.");
        setPasswordError("Email or password is wrong.");
        return;
      } else {
        setToken(token);
        navigate(APP_ROUTES.MEETING);
      }
    } else {
      console.log("validate email or password failed.");
      return;
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError("Email is invalid");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validatePassword = () => {
    if (password.length < 8) {
      setPasswordError("Password is wrong");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const handleClearEmail = () => {
    setEmail("");
    setEmailError("");
    console.log(email);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const styles = {
    root: {
      display: "flex",
      height: "100%",
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
      padding: "60px 0",
    },
    container: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      marginTop: "60px",
      maxWidth: "950px",
      width: "950px",
    },
    bodyBox: {
      backgroundColor: COLOR.White,
      display: "flex",
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      padding: "80px 0",
      borderRadius: "20px",
      boxShadow: "0 3px 5px rgba(0,0,0,0.25)",
      width: "100%",
    },
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        style={{
          display: "block",
          height: "100vh",
          width: "100vw",
          minHeight: "800px",
          backgroundColor: COLOR.Hovered,
        }}
      >
        <Box sx={styles.root} component="main">
          <AppBar elevation={0}>
            <Box
              style={{
                display: "flex",
                backgroundColor: COLOR.White,
                boxShadow: "0 3px 5px rgba(0,0,0,0.15)",
              }}
            >
              <img src={logo} style={{ height: "60px", marginLeft: "20px" }} />
            </Box>
          </AppBar>
          <Container sx={styles.container}>
            <CssBaseline />
            <Box sx={styles.bodyBox}>
              <img src={logo} style={{ height: "90px" }} />
              {!isSendEmailSuccess && (
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontWeight: "bold" }}
                  sx={{ mt: "40px", mb: 0 }}
                >
                  Raipoong Backoffice
                </Typography>
              )}
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  // mt: 1,
                  // ml: 1,
                  // mr: 1,
                  width: "100%",
                  px: { lg: "193px", md: "55px", xs: "24px" },
                }}
              >
                {(emailError || passwordError) && (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: COLOR.ErrorLight,
                      alignItems: "center",
                      width: "100%",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      margin: "10px 0",
                    }}
                  >
                    <ErrorIcon style={{ color: COLOR.Error }} />
                    <Typography
                      style={{
                        color: COLOR.Error,
                        marginLeft: "10px",
                        fontSize: "14px",
                      }}
                    >
                      Invalid Email or Password
                    </Typography>
                  </Box>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={email}
                  onFocus={() => setEmailFocused(true)}
                  onBlur={() => setEmailFocused(false)}
                  autoComplete="email"
                  onChange={(e) => {
                    if (e.target.value.length > 50) return;
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                  autoFocus
                  error={!!emailError}
                  InputLabelProps={{
                    style:{
                      color: emailError ? COLOR.Error : emailFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: COLOR.White,
                      "&.Mui-focused fieldset": {
                        borderColor: emailError ? COLOR.Error : COLOR.Primary,
                      },
                      "&:hover fieldset": {
                        borderColor: emailError ? COLOR.Error : COLOR.Border,
                      },
                      "&.Mui-focused:hover fieldset": {
                        borderColor: emailError ? COLOR.Error : COLOR.Primary,
                      },
                    },
                    "& label.Mui-focused": {
                      color: emailError ? COLOR.Error : COLOR.Primary,
                    },
                    "& fieldset": {
                      borderColor: emailError ? COLOR.Error : COLOR.Border,
                      borderRadius: "6px",
                    },
                    "& .Mui-error": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR.Error,
                      },
                      "& .MuiInputLabel-root": {
                        color: COLOR.Error
                      }
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                    },
                    "& .MuiFormLabel-asterisk":{
                      color: emailError ? COLOR.Error : emailFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    }
                  }}
                  style={{ marginTop: "20px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                          {emailError && passwordError ? (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setEmail("");
                                setEmailError("");
                              }}
                            >
                              <ErrorIcon style={{ color: COLOR.Error }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setEmail("");
                                setEmailError("");
                              }}
                              style={{
                                display: email !== '' ? "flex" : "none",
                                alignItems: "center",
                                color: COLOR.PlaceHolder,
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                      </InputAdornment>
                    ),
                    error: !!emailError,
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  id="password"
                  onChange={(e) => {
                    if(e.target.value.length > 50) return;
                    setPassword(e.target.value);
                    setEmailToResetPasswordError("");
                    setPasswordError("");
                  }}
                  error={!!passwordError}
                  onFocus={() => setPasswordFocused(true)}
                  onBlur={() => setPasswordFocused(false)}
                  InputLabelProps={{
                    style:{
                      color: passwordError ? COLOR.Error : passwordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: COLOR.White,
                      "&.Mui-focused fieldset": {
                        borderColor: passwordError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                      "&:hover fieldset": {
                        borderColor: passwordError ? COLOR.Error : COLOR.Border,
                      },
                      "&.Mui-focused:hover fieldset": {
                        borderColor: passwordError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                    },
                    "& label.Mui-focused": {
                      color: passwordError ? COLOR.Error : COLOR.Primary,
                    },
                    "& fieldset": {
                      borderColor: passwordError ? COLOR.Error : COLOR.Border,
                      borderRadius: "6px",
                    },
                    "& .Mui-error": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR.Error,
                      },
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                    },
                    "& .MuiFormLabel-asterisk":{
                      color: passwordError ? COLOR.Error : passwordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {emailError && passwordError ? (
                          <ErrorIcon style={{ color: COLOR.Error }} />
                        ) : (
                          <IconButton
                            edge="end"
                            onClick={handleShowPassword}
                            style={{
                              color: COLOR.PlaceHolder,
                            }}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                    error: !!passwordError,
                  }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      color: COLOR.Primary,
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={() => navigate(APP_ROUTES.FORGOTPASSWORD)}
                  >
                    Forgot password?
                  </Typography>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, padding: "13px 0" }}
                  style={{
                    fontWeight: "bold",
                    backgroundColor: COLOR.Primary,
                    textTransform: "none",
                    fontSize: "17px",
                    boxShadow: "none",
                    borderRadius: "6px",
                  }}
                >
                  Login
                </Button>
              </Box>
              <Box
                style={{
                  // width: '70%',
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                sx={{
                  mt: 1,
                  ml: 1,
                  mr: 1,
                  width: "100%",
                  px: { lg: "193px", md: "55px", xs: "24px" },
                }}
              ></Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

