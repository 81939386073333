import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ExerciseAgenda, ExerciseVideo, User } from "../services/models";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { COLOR } from "../utils/constants";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getExerciseAgendaByAgendaID,
  getPublicExerciseVideo,
} from "../services/api";
import { styled } from "@mui/material/styles";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f2f2f2",
    color: COLOR.Black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "1px solid #d8d8d8",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#f2f2f2",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type ExerciseAgendaListProp = {
  meetingID: number;
  agendaID: number;
  handleExerciseAgendaList: (videoList: ExerciseAgenda[]) => void;
  exerciseAgendaListError: string;
  isWaiting: boolean;
  editMode: boolean;
  isError: boolean;
};

export default function ExerciseAgendaList({
  meetingID,
  agendaID,
  handleExerciseAgendaList,
  exerciseAgendaListError,
  isWaiting,
  editMode,
  isError
}: ExerciseAgendaListProp) {
  const [exerciseVideoList, setExerciseVideoList] = useState<ExerciseVideo[]>();
  const [exerciseAgendaList, setExerciseAgendaList] = useState<
    ExerciseAgenda[] | []
  >();

  const [videoSelect, setVideoSelect] = useState<ExerciseVideo[]>([]);
  const [videoSelectError, setVideoSelectError] = useState<string>("");

  const [exerciseAgendaFocused, setExerciseAgendaFocused] = useState<boolean>(false);

  const exerciseAgendaListRef = useRef<HTMLInputElement>(null);

  const pathImage =
    process.env.REACT_APP_API_URL + "/exercise-videos/thumbnail/";

  const fetchData = async () => {
    // const list = await getPublicExerciseVideo();
    // setExerciseVideoList(list ? list : []);
    getExerciseVideo();

    if (agendaID !== 0) {
      const exlist = await getExerciseAgendaByAgendaID(meetingID, agendaID);
      setExerciseAgendaList(exlist ? exlist : []);
    }
  };

  useEffect(() =>{
    if(isError){
      if(exerciseAgendaListRef && exerciseAgendaListRef.current){
        exerciseAgendaListRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [isError]);

  const getExerciseVideo = async () =>{
    const list = await getPublicExerciseVideo();
    setExerciseVideoList(list ? list : []);
  }

  useEffect(() => {
    // setExerciseVideoList(exerciseVideoList);
    if(exerciseVideoList){
      console.log("exerciseVideoList", exerciseVideoList);
    }
  }, [exerciseVideoList]);

  const getSelectedVideo = () => {
    const selectedVideo: ExerciseVideo[] = [];
    if (exerciseAgendaList) {
      console.log(
        "exerciseAgendaList before set videoSelect",
        exerciseAgendaList
      );
      if (exerciseVideoList) {
        for (let i = 0; i < exerciseAgendaList.length; i++) {
          const video = exerciseVideoList.find(
            (item) => item.id === exerciseAgendaList[i].exerciseVideoID
          );
          if (video) {
            selectedVideo.push(video);
            const index = exerciseVideoList.findIndex(
              (item) => item.id === video.id
            );
            if (index !== -1) {
              exerciseVideoList.splice(index, 1);
              exerciseVideoList.unshift(video);
            }
          }
        }
      }
    }
    setVideoSelect(selectedVideo);
    if (videoSelect.length > 0) {
      setVideoSelectError("");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validateSelectVideo = (newValue: any) => {
    if (newValue.length === 0) {
      setVideoSelectError("Select a exercise video maximum 6 videos");
      setExerciseAgendaList([]);
    } else if (newValue.length > 6) {
      setVideoSelectError("Select a exercise video maximum 6 videos");
      const data: ExerciseAgenda[] = [];
      newValue.map((item: ExerciseVideo) => {
        data.push({
          exerciseVideoID: item.id,
          id: 0,
          agendaID: 0,
          error: "",
          message: "",
          statusCode: 0,
        });
      });

      setExerciseAgendaList(data);
    } else {
      setVideoSelectError("");
      const data: ExerciseAgenda[] = [];
      newValue.map((item: ExerciseVideo) => {
        data.push({
          exerciseVideoID: item.id,
          id: 0,
          agendaID: 0,
          error: "",
          message: "",
          statusCode: 0,
        });
      });

      setExerciseAgendaList(data);
    }
  };

  useEffect(() => {
    if (videoSelect.length > 0) {
      console.log("videoSelect", videoSelect);
    }
  }, [videoSelect]);

  useEffect(() => {
    setExerciseAgendaList(exerciseAgendaList);
    sortByChar(exerciseVideoList ? exerciseVideoList : []);
    getSelectedVideo();
    handleExerciseAgendaList(exerciseAgendaList ? exerciseAgendaList : []);
  }, [exerciseAgendaList]);

  const sortByChar = (exerciseList: ExerciseVideo[]) => {
    return exerciseList.sort((a, b) => {
      const  videoA = a.title.toUpperCase();
      const  videoB = b.title.toUpperCase();
      return videoA.localeCompare(videoB);
    });
  }

  const getVideoDuration = (value: number) => {
    if (value) {
      const duration = dayjs.duration(value, "seconds");
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      return `(${hours > 0 ? hours + " hours" : ""} ${
        minutes > 0 ? minutes + " minutes" : ""
      } ${seconds > 0 ? seconds + " seconds" : ""})`;
    } else {
      return "";
    }
  };

  return (
    <div>
      <Typography
        variant="h6"
        style={{ fontWeight: "bold" }}
        sx={{ mb: "32px" }}
      >
        Exercise Video
      </Typography>

      <Autocomplete
        multiple
        limitTags={6}
        id="checkboxes-tags-demo"
        fullWidth
        disabled={!isWaiting}
        disablePortal={true}
        disableCloseOnSelect
        clearIcon={<CancelIcon style={{color: COLOR.PlaceHolder}} />}
        options={exerciseVideoList ? exerciseVideoList : []}
        onChange={(e: any, newValue: any) => {
          setVideoSelect(newValue);
          validateSelectVideo(newValue);
        }}
        onFocus={() => setExerciseAgendaFocused(true)}
        onBlur={() => setExerciseAgendaFocused(false)}
        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: COLOR.White,
            "&.Mui-focused fieldset": {
              borderColor:
                exerciseAgendaListError || videoSelectError
                  ? COLOR.Error
                  : COLOR.Primary,
            },
            "&:hover fieldset": {
              borderColor:
                exerciseAgendaListError || videoSelectError
                  ? COLOR.Error
                  : COLOR.Border,
            },
            "&.Mui-focused:hover fieldset": {
              borderColor:
                exerciseAgendaListError || videoSelectError
                  ? COLOR.Error
                  : COLOR.Primary,
            },
          },
          "& label.Mui-focused": {
            color:
              exerciseAgendaListError || videoSelectError
                ? COLOR.Error
                : COLOR.Primary,
          },
          "& fieldset": {
            borderColor:
              exerciseAgendaListError || videoSelectError
                ? COLOR.Error
                : COLOR.Border,
            borderRadius: '6px'
          },
          "& .Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: COLOR.Error,
            },
          },
          "& .MuiFormHelperText-root.Mui-error": {
            color: COLOR.Error,
          },
          '& .Mui-Selected fiedlset': {
            borderColor: COLOR.White
          },
          "& input:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
          },
          "& .MuiFormLabel-asterisk":{
            color: !!exerciseAgendaListError || !!videoSelectError ? COLOR.Error : exerciseAgendaFocused ? COLOR.Focused : COLOR.PlaceHolder,
          },
        }}
        value={videoSelect}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Add Exercise Video"
            placeholder={isWaiting ? "Select Video" : ""}
            error={!!exerciseAgendaListError || !!videoSelectError}
            helperText={exerciseAgendaListError || videoSelectError}
            inputRef={exerciseAgendaListRef}
            InputLabelProps={{
              style:{
                color: !!exerciseAgendaListError || !!videoSelectError ? COLOR.Error : exerciseAgendaFocused ? COLOR.Focused : COLOR.PlaceHolder,
              },
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ backgroundColor: COLOR.White }}>
            <Checkbox
              style={{
                color: COLOR.Primary,
              }}
              checked={selected}
              sx={{
                "&.Mui-checked": {
                  color: COLOR.Primary,
                },
              }}
            />
            {option.title}&nbsp;
            <Typography style={{ color: COLOR.PlaceHolder }}>
              {" " + getVideoDuration(option.duration)}
            </Typography>
          </li>
        )}
        ListboxProps={{
          style: {
            maxHeight: "150px",
          },
        }}
      />

      <TableContainer
        component={Paper}
        style={{ marginTop: "24px", marginBottom: "32px" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" style={{ width: "19%" }}>
                #
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "27%" }}>
                Thumbnail
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "27%" }}>
                Video Title
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "27%" }}>
                Type Video
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videoSelect?.length === 0
              ? null
              : videoSelect.map((row, index) => (
                  <StyledTableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {row?.videoThumbnail ? (
                        <img
                          src={pathImage + row?.id + "?" + row?.videoThumbnail}
                          style={{
                            height: "48px",
                            width: "48px",
                            objectFit: "cover",
                          }}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell align="center">{row?.title}</TableCell>
                    <TableCell align="center">
                      {row.videoPath ? (
                        <Link
                          to={row.videoUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <OndemandVideoIcon style={{ color: COLOR.Primary }} />
                        </Link>
                      ) : (
                        <Link
                          to={row.youtubeUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <YouTubeIcon style={{ color: COLOR.Primary }} />
                        </Link>
                      )}
                    </TableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
