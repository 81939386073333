import React, { FC } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import { useLocation } from 'react-router-dom';
import { COLOR } from '../utils/constants';
import Box from "@mui/material/Box";

interface CustomListItemsProps {
  href: string;
  icon: JSX.Element;
  text: string;
}


const CustomListItems: FC<CustomListItemsProps> = ({href, icon, text}: CustomListItemsProps) => {
  const location = useLocation();
  let isActived = location.pathname === href;
  
  if(href === '/meeting' && location.pathname.includes('/meeting/')){
    isActived = true;
  }
  
  const styles = {
    listItems: {
      padding: '0px 15px',
      '& .MuiListItemButton-root': {
        borderRadius: '6px',
        marginBottom: '10px',
      }
    }
  };
  
  return (
    <Box sx={styles.listItems}>
      <ListItemButton
        selected={isActived}
        href={href}
        sx={{
          '&.Mui-selected':{
            backgroundColor: COLOR.Hovered,
            '&:hover':{
              backgroundColor: COLOR.Hovered,
            }
          },
          '&:hover': {
            backgroundColor: COLOR.Hovered,
            '&Mui-selected': {
              backgroundColor: COLOR.Hovered,
            }
          },
        }}
      >
        <ListItemIcon
          sx={{
            '&.MuiListItemIcon-root': {
              color: isActived ? COLOR.Primary : '#959697',
            },
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText 
          primary={text}
          sx={{
            '&.MuiListItemText-root': {
              color: isActived ? COLOR.Primary : '#898989',
            },
          }}
        />
      </ListItemButton>
    </Box>
  )
};

export const menuListItemsSuperAdmin = (
  <>
    <CustomListItems href="/meeting" icon={<VideoChatIcon />} text="Meeting" />
    <CustomListItems href="/video" icon={<OndemandVideoIcon />} text="Video" />
    <CustomListItems href="/user" icon={<PeopleIcon />} text="Users" />
  </>
);

export const menuListItemsAdmin = (
  <>
    <CustomListItems href="/meeting" icon={<VideoChatIcon />} text="Meeting" />
    <CustomListItems href="/video" icon={<OndemandVideoIcon />} text="Video" />
  </>
);