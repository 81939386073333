import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import { Agenda, ExerciseAgenda } from "../services/models";
import { createAgenda, createExerciseAgenda, deleteAgenda, getAgendaByMeeting, updateAgenda, deleteExerciseAgenda, getExerciseAgendaByAgendaID} from "../services/api";
import AgendaDetail from "./agendaDetail";
import { PropSnackbar, PropDialog } from "./layout";
import { useParams } from "react-router-dom";
import { COLOR } from "../utils/constants";
import { 
  DragDropContext, 
  Draggable,
  DraggableProvided, 
  DraggableStateSnapshot, 
  Droppable, 
  DroppableProvided, 
  DropResult,
  ResponderProvided
 } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EventIcon from '@mui/icons-material/Event';
import { styled, useMediaQuery, Container, Divider } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

type AgendaListProp = {
  meetingID: number;
  propSnackbar: (prop: PropSnackbar)=> void;
  propConfirmDialog: (prop: PropDialog) => void;
  isWaiting: boolean;
  setLoading: Function;
  getLoading: Function;
  getAgendaList: Function;
  soundNotifyTime: number;
  isSoundNotify: boolean;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f2f2f2",
    color: COLOR.Black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "1px solid #d8d8d8",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#f2f2f2",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AgendaList({ meetingID, propSnackbar, propConfirmDialog, isWaiting, setLoading, getLoading, getAgendaList, soundNotifyTime, isSoundNotify }: AgendaListProp) {
  const params = useParams();
  const [oldAgendaList, setOldAgendaList] = useState<Agenda[] | []>([]);
  const [agendaData, setAgendaData] = useState<Agenda | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [fullWidth] = useState<boolean>(true);
  const [propSnackbarOpen, setPropSnackbarOpen] = useState<PropSnackbar>({ open: false, type: 'success', message: 'success !!'})
  const [propDialogOpen, setPropDialogOpen] = useState<PropDialog>({ open: false, title: '', name: '', content: '', confirmText: '', cancelText: '', onConfirm: () => {}, onCancel: () => {}});

  const [exerciseAgendaList, setExerciseAgendaList] = useState<ExerciseAgenda[] | []>([]);
  const [agendaList, setAgendaList] = useState<Agenda[] | []>([]);

  const [dialogCheckOpen, setDialogCheckOpen] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  const fetchData = async () => {
    if(params.id) {
      const _isLoad = getLoading();
      if (!_isLoad) setLoading(true);
      const agendas = await getAgendaByMeeting(parseInt(params.id, 10));
      console.log('agendas', agendas);
      setAgendaList(agendas ? agendas : []);
      getAgendaList(agendas ? agendas : []);
      if (!_isLoad) setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    propConfirmDialog(propDialogOpen);
  }, [propDialogOpen]);

  useEffect(() => {
    propSnackbar(propSnackbarOpen);
  });

  const handleClickOpenDialog = () =>{
    setAgendaData(null);
    setOpenDialog(true);
  }

  const handleCloseDialog = (event:any, reason:string) => {
    if (reason == 'backdropClick') {
      return;
    }
    setOpenDialog(false);
  };

  const handleClickCancel = () => {
    setOpenDialog(false);
  }

  const handleOnEditItem = (index: number) => {
    setAgendaData(agendaList[index])
    setOpenDialog(true);
  }

  const handleSaveAgenda = async (data: Agenda) => {
    data.meetingID = meetingID
    setLoading(true);
    let saveAgendaSuccess: boolean = false;
    if(data.id) {
      const agendaCreated = await updateAgenda(data);
      if (agendaCreated) {
        fetchData();
        const agendaVideoList = await getExerciseAgendaByAgendaID(data.meetingID, data.id);
        if(agendaVideoList){
          for(const exerciseAgenda of agendaVideoList){
            await deleteExerciseAgenda(meetingID, data.id, exerciseAgenda.id);
          }
          console.log('delete exercise agenda success...')
        }

        if(exerciseAgendaList.length > 0) {
          exerciseAgendaList.map(async (exercisevideo: ExerciseAgenda) => {
            const exerciseAgenda: ExerciseAgenda = {
              id: 0,
              agendaID: agendaCreated.id,
              exerciseVideoID: exercisevideo.exerciseVideoID,
              error: "",
              message: "",
              statusCode: 0
            }
            const res = await createExerciseAgenda(meetingID, agendaCreated.id, exerciseAgenda);
            if(res){
              setPropSnackbarOpen({open: true, type: "success", message: "Update Agenda Success!"});
            }
            else{
              setPropSnackbarOpen({open: true, type: "error", message: "Cannot Update Agenda!"});
            }
          })
        }
        setPropSnackbarOpen({open: true, type: "success", message: "Update Agenda Success!"});
        saveAgendaSuccess = true;
      } else {
        setPropSnackbarOpen({open: true, type: "error", message: "Cannot Update Agenda!"});
      }
    } else {
      console.log(`Meeting agenda ${data}`)
      const agendaCreated = await createAgenda(data);
      if (agendaCreated) {
        fetchData();
        setPropSnackbarOpen({open: true, type: "success", message: "Create New Agenda Success!"});

        //add exerciseAgendaList to this agenda
        if(exerciseAgendaList.length > 0) {
          exerciseAgendaList.map(async (exercisevideo: ExerciseAgenda) => {
            const exerciseAgenda: ExerciseAgenda = {
              id: 0,
              agendaID: agendaCreated.id,
              exerciseVideoID: exercisevideo.exerciseVideoID,
              error: "",
              message: "",
              statusCode: 0
            }
            const res = await createExerciseAgenda(meetingID, agendaCreated.id, exerciseAgenda);
            if(res){
              setPropSnackbarOpen({open: true, type: "success", message: "Create New Agenda Success!"});
            }
            else{
              setPropSnackbarOpen({open: true, type: "error", message: "Cannot Create New Agenda!"});
            }
          })
        }
        saveAgendaSuccess = true;
      } else {
        setPropSnackbarOpen({open: true, type: "error", message: "Cannot Create New Agenda!"});
      }
    }
    setOpenDialog(false);
    setLoading(false);
    if(saveAgendaSuccess){
      if(isSoundNotify){
        checkNotifyTime();
      }
    }
    setTimeout(() => {
      return setPropSnackbarOpen({open: false, type: "success", message: ""});
    }, 3000);
  };

  const checkNotifyTime = () =>{
    let flag = 0;
    agendaList.map((agenda: Agenda) => {
      if(agenda.time < soundNotifyTime){
        flag = 1;
      }
    })
    if(flag === 1){
      setDialogCheckOpen(true);
    }
  }

  const handleConfirmDialog = async (meetingID: number, agendaID: number, name:string) => {
    console.log("handleConfirmDialog...");
    confirmDeleteDialog(meetingID, agendaID, name);
  };

  const confirmDeleteDialog = (meetingID: number, agendaID: number, name: string) =>{
    setPropDialogOpen({
      open: true,
      title: "Delete Agenda",
      name: name,
      content: "Do you want to delete the",
      confirmText: "Delete",
      cancelText: "Cancel",
      onConfirm: () => {
        setPropDialogOpen({open: false, title: "", name: "", content: "", confirmText: "", cancelText: "", onConfirm: () => {}, onCancel: () => {}});
        propConfirmDialog(propDialogOpen);
        handleDeleteAgenda(meetingID, agendaID);
      },
      onCancel: () => {
        setPropDialogOpen({open: false, title: "", name: "", content: "", confirmText: "", cancelText: "", onConfirm: () => {}, onCancel: () => {}})
        propConfirmDialog(propDialogOpen);
      },
    });
  }

  const handleDeleteAgenda = async (meetingID: number, agendaID: number) => {
    setLoading(true);
    const result = await deleteAgenda(meetingID, agendaID);
    if (result) {
      fetchData();
      setPropSnackbarOpen({open: true, type: "success", message: "Delete Agenda Success!"});
    }
    setLoading(false);
  };

  const handleDragEnd = (result: DropResult, provided?: ResponderProvided) =>{
    if(!result.destination){
      return;
    }
    if(result.destination.index === result.source.index){
      return;
    }
    const items = Array.from(agendaList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setAgendaList(items);
    //set sequences
    setSequenceAgenda(items);
  }

  const setSequenceAgenda = async (agendaList: Agenda[]) => {
    setLoading(true);
    for (let i = 0; i < agendaList.length; i++) {
      const agenda = agendaList[i];
      agenda.sequence = i + 1;
      const res = await updateAgenda(agenda);
      if(res){
        setPropSnackbarOpen({open: true, type: "success", message: "Update Agenda Success!"});
        // fetchData();
      }else{
        setPropSnackbarOpen({open: true, type: "error", message: "Cannot Update Agenda!"});
      }
    }
    
    setLoading(false);
  };

  const handleSaveExerciseAgendaList = (videoList: ExerciseAgenda[]) =>{
    setExerciseAgendaList(videoList);
  };

  useEffect(() => {
    setExerciseAgendaList(exerciseAgendaList);
  }, [exerciseAgendaList]);

  const getTime = (second: number) => {
    const duration = dayjs.duration(second, "seconds");
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${hours > 0 ? hours + ' hours' : ''} ${minutes > 0 ? minutes + ' minutes' : ''} ${seconds > 0 ? seconds + ' seconds' : ''}`;
  }

  return (
    <Box style={{alignItems:'center', display:'flex', flexDirection:'column'}}>
      <Dialog
        fullWidth
        open={dialogCheckOpen}
        PaperProps={{
          style: {
            position: "fixed",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            top: "0",
            borderRadius: "10px",
            maxWidth: "740px",
          },
        }}
      >
        <Container component="main" style={{ maxWidth: "1200px" }}>
          <Box sx={{ m: "24px 0" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bold" }}
              >
                Notification sound
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setDialogCheckOpen(false);
                }}
              >
                <ClearIcon style={{ color: "#959697" }} />
              </IconButton>
            </Box>
            <Divider style={{ marginTop: "16px", marginBottom: "32px" }} />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <Typography style={{textAlign: 'center'}}>
                  Please change time of notification sound to no more than the topic that takes the least time.
                </Typography>
              </Box>
              <Button
                variant="contained"
                style={{
                  fontWeight: "bold",
                  backgroundColor: COLOR.Primary,
                  textTransform: "none",
                  boxShadow: "none",
                  fontSize: "17px",
                  padding: "13px 0",
                  width: isSmallScreen ? "100%" : "80%",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  setDialogCheckOpen(false);
                }}
              >
                Okay
              </Button>
            </Box>
          </Box>
        </Container>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            position: "fixed",
            display: "flex",
            alignItems: "flex-start",
            top: "0",
            maxWidth: '740px'
          },
        }}
      >
        <AgendaDetail
          saveAgenda={handleSaveAgenda}
          agendaData={agendaData} 
          meetingID={meetingID} 
          onCloseDialog={handleClickCancel}
          handleExerciseAgendaList={handleSaveExerciseAgendaList}
          isWaiting={isWaiting}
          sequenceLength={agendaList.length}
        />
      </Dialog>
      <Grid container spacing={2} sx={{my: '32px'}} style={{display: 'flex'}}>
        <Grid item xs={12} md={6} style={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6" color="inherit" noWrap style={{fontWeight: 'bold'}}>
            Meeting Topic
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'right', display: isWaiting? '' : 'none'}}>
          <Button
            fullWidth={isMediumScreen}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleClickOpenDialog}
            style={{
              backgroundColor: COLOR.Primary,
              textTransform: "none",
              boxShadow: "none",
              fontWeight: "bold",
              fontSize: "17px",
              paddingTop: '13px',
              paddingBottom: '13px',
              marginBottom: isSmallScreen ? '16px' : '0px',
              marginTop: isSmallScreen ? '16px' : '0px',
              borderRadius: '6px'
            }}
          >
            Add Topic
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{borderRadius: '0px', boxShadow: 'none'}}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="id">
            {(droppableProvided: DroppableProvided) => (
              <Table 
                sx={{ minWidth: 650 }} 
                aria-label="simple table"
                style={{
                  borderBottom: '1px solid #D8D8D8'
                }}
                ref={droppableProvided.innerRef} 
                {...droppableProvided.droppableProps}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell style={{width: '10%'}}>#</StyledTableCell>
                    <StyledTableCell align="center">Title Name</StyledTableCell>
                    <StyledTableCell align="center">Time</StyledTableCell>
                    <StyledTableCell align="center">Type</StyledTableCell>
                    <StyledTableCell align="center">Created By</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                  <TableBody >
                    {agendaList && agendaList.length > 0 ? (
                      agendaList.map((row, index) => (
                        <Draggable 
                          key={row.id}
                          draggableId={row.id.toString()}
                          index={index}
                        >
                          {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                            const isEvenRow: boolean = index % 2 === 0;
                            const isFirstRow: boolean = index === 0;
                            return (
                              <StyledTableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                
                                style={{
                                  ...draggableProvided.draggableProps.style,
                                  backgroundColor: snapshot.isDragging ? '#D9D9D9' : (isEvenRow? '#FFFFFF' : "#f2f2f2"),
                                  color: 'white'
                                }}
                                key={1}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              >
                                <StyledTableCell>
                                  <div {...draggableProvided.dragHandleProps} style={{display: isWaiting? '' : 'none'}}>
                                    <DragIndicatorIcon style={{color: COLOR.PlaceHolder}} />
                                  </div>
                                  
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                  {index + 1}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    style={{
                                      maxWidth: 200,
                                      fontSize: "14px",
                                      WebkitLineClamp: 2,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                    }}
                                  >
                                    {row.title}
                                  </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">{getTime(row.time)}</StyledTableCell>
                                <StyledTableCell align="center">{row.type === 'AGENDA' ? 'TOPIC' : row.type}</StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.createdUser ? row.createdUser.name : "-"}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '25px'}}>
                                  {isWaiting ?(
                                    <>
                                      <IconButton
                                        aria-label="edit"
                                        onClick={() => handleOnEditItem(index)}
                                      >
                                        <EditIcon style={{color: COLOR.Primary}} />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                          handleConfirmDialog(row.meetingID, row.id, row.title);
                                        }}
                                      >
                                        <DeleteIcon style={{color: COLOR.Error}} />
                                      </IconButton>
                                    </>
                                  ):(
                                    <IconButton
                                      aria-label="edit"
                                      onClick={() => handleOnEditItem(index)}
                                    >
                                      <Visibility />
                                    </IconButton>
                                  )
                                }
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          }}
                        </Draggable>
                      ))
                    ) : (
                      <StyledTableRow>
                        <TableCell colSpan={7} align="center" style={{height: '250px'}}>
                          <EventIcon style={{color: COLOR.PlaceHolder, fontSize: '36px'}} />
                          <Typography variant='subtitle1' style={{color: COLOR.PlaceHolder}}>
                            No Agenda
                          </Typography>
                        </TableCell>
                      </StyledTableRow>
                    )}
                    {droppableProvided.placeholder}
                  </TableBody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
      </TableContainer>
    </Box>
  );
}
