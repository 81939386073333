import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ExerciseVideo } from "../services/models";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import {
  RadioGroup,
  FormControl,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import { COLOR } from "../utils/constants";
import ImageIcon from "@mui/icons-material/Image";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import YouTube from "react-youtube";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import InputMask from "react-input-mask";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

type ExerciseVideoDetailProp = {
  saveExerciseVideo: Function;
  exerciseVideoData: ExerciseVideo | null;
  onCloseDialog: Function;
  handleVideoThumbnail: Function;
};
export default function VideoDetail({
  saveExerciseVideo,
  exerciseVideoData,
  onCloseDialog,
  handleVideoThumbnail,
}: ExerciseVideoDetailProp) {
  const [saveText, setSaveText] = useState<string>("Save");
  const [pageTitle, setPageTitle] = useState<string>("Add Video");
  const pathImage: string =
    process.env.REACT_APP_API_URL + "/exercise-videos/thumbnail/";

  const [title, setTitle] = useState<string>("");
  const [exerciseVideoId, setExerciseVideoId] = useState<number>(0);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const thumbnailPathRef = useRef<HTMLInputElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const youtubeUrlRef = useRef<HTMLInputElement>(null);
  const videoPathRef = useRef<HTMLInputElement>(null);
  const videoDurationRef = useRef<HTMLInputElement>(null);

  const [active, setActive] = useState<boolean>(true);

  const [typeVideo, setTypeVideo] = useState<string>("youtubeUrl");
  const [youtubeUrl, setYoutubeUrl] = useState<string>("");
  const [youtubeId, setYoutubeId] = useState<string>("");
  const [videoPath, setVideoPath] = useState<string>("");
  const [file, setFile] = useState<File>();

  const [thumbnailVideo, setThumbnailVideo] = useState<string>("");
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [thumbnailImage, setThumbnailImage] = useState<string>("");
  const [thumbnailPath, setThumbnailPath] = useState<string>("");
  const [thumbnailPathError, setThumbnailPathError] = useState<string>("");

  const [titleError, setTitleError] = useState<string>("");
  const [youtubeUrlError, setYoutubeUrlError] = useState<string>("");
  const [videoPathError, setVideoPathError] = useState<string>("");
  const [showUpload, setShowUpload] = useState<boolean>(false);

  const [refUrl, setRefUrl] = useState<string>("");
  const [refUrlError, setRefUrlError] = useState<string>("");

  const [videoDuration, setVideoDuration] = useState<string>("00:00:00");
  const [videoDurationError, setVideoDurationError] = useState<string>("");

  const [titleFocused, setTitleFocused] = useState<boolean>(false);
  const [refUrlFocused, setRefUrlFocused] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      const _file = e.target.files[0];

      const MAX_FILE_SIZE = 100 * 1024; // 100MB

      if (!_file) {
        setVideoPathError("Please choose a file");
        return false;
      }

      const fileSizeKiloBytes = _file.size / 1024;
      console.log(`file size error ${fileSizeKiloBytes}`);
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        setVideoPathError("File size is bigger than 100MB");
        setVideoPath('');
        return false;
      }

      setFile(e.target.files[0]);
      setVideoPath(e.target.files[0].name);
    }
  };

  const stringPad = (ns: number, width: number) => {
    const z = "0";
    const n = ns + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log("validate...");
    if (thumbnailPath === "") {
      setThumbnailPathError("please upload thumbnail");
      if(thumbnailPathRef.current){
        thumbnailPathRef.current.scrollIntoView({ behavior: "smooth", block: "center"});
      }
    }
    if(title.trim().length < 5 || title.trim().length > 50 || title.trim() === ''){
      setTitleError("Video Title must be more than 5 characters and no more than 50 characters");
      if(thumbnailPath !== ''){
        if(titleRef.current){
          titleRef.current.scrollIntoView({ behavior: "smooth", block: "center"});
        }
      }
    }
    if (typeVideo === "youtubeUrl") {
      if (refUrl === "") {
        setRefUrlError("please enter youtube url");
      }
      if(thumbnailPath !== '' && title.length > 5 && title.length < 50){
        if(youtubeUrlRef.current){
          youtubeUrlRef.current.scrollIntoView({ behavior: "smooth", block: "center"});
        }
      }
    } else {
      if (videoPath === "") {
        setVideoPathError("please upload video");
      }
      if(thumbnailPath !== '' && title.length > 5 && title.length < 50){
        if(videoPathRef.current){
          videoPathRef.current.scrollIntoView({ behavior: "smooth", block: "center"});
        }
      }
    }
    if(videoDuration === ''){
      setVideoDurationError("please enter video duration");
      if(thumbnailPath !== '' && title.length > 5 && title.length < 50 && (refUrl !== '' || videoPath !== '')){
        if(videoDurationRef.current){
          videoDurationRef.current.scrollIntoView({ behavior: "smooth", block: "center"});
        }
      }
    }

    if (
      title.trim().length > 5 &&
      title.trim().length < 50 &&
      ((typeVideo === "youtubeUrl" && refUrl.trim().length > 0) ||
        (typeVideo === "uploadVideo" && videoPath.trim().length > 0)) &&
      thumbnailPath.trim().length > 0 &&
      videoDuration.trim().length > 0 &&
      videoDuration.trim().length > 0
    ) {
      console.log("format duration...");
      //format duration to seconds
      const timeArr = videoDuration.split(":");
      const hour = parseInt(timeArr[0]);
      const minute = parseInt(timeArr[1]);
      const second = parseInt(timeArr[2]);
      console.log(hour, minute, second);
      const seconds = hour * 3600 + minute * 60 + second;

      console.log("handleSubmit...");

      if (typeVideo === "youtubeUrl") {
        saveExerciseVideo(
          {
            title,
            id: exerciseVideoId,
            showUpload,
            videoPath: "",
            youtubeUrl,
            isPublic,
            fileData: file,
            duration: seconds,
            active,
          },
          thumbnailFile
        );
      } else {
        saveExerciseVideo(
          {
            title,
            id: exerciseVideoId,
            showUpload,
            videoPath,
            youtubeUrl: "",
            isPublic,
            fileData: file,
            duration: seconds,
            active,
          },
          thumbnailFile
        );
      }
    }
  };

  //validate

  const validateYoutubeUrl = (value: string) => {
    const youtubeReg =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (!youtubeReg.test(value)) {
      setRefUrlError("youtube Url is invalid");
      return false;
    } else {
      setRefUrlError("");
      return true;
    }
  };

  const validateVideoPath = (value: string) => {
    if (value.length === 0) {
      setVideoPathError("please upload video");
      return false;
    } else if (value.split(".").pop() !== "mp4") {
      setVideoPathError("please upload mp4 file");
      return false;
    } else {
      setVideoPathError("");
      return true;
    }
  };

  const validateVideoTitle = (value: string) => {
    if (value.trim().length < 5 || value.trim().length > 50) {
      setTitleError(
        "Video Title must be more than 5 characters and no more than 50 characters"
      );
      return false;
    } else {
      setTitleError("");
      return true;
    }
  };

  const validateThumbnailPath = (value: string) => {
    if (value.length === 0) {
      setThumbnailPathError("please upload thumbnail");
      return false;
    } else {
      setThumbnailPathError("");
      return true;
    }
  };

  useEffect(() => {
    if (exerciseVideoData && exerciseVideoData.id) {
      console.log("exerciseVideoData...", exerciseVideoData);
      setEditMode(true);
      console.log("edit mode in edit mode", editMode);
      setExerciseVideoId(exerciseVideoData.id);
      setTitle(exerciseVideoData.title);
      setIsPublic(exerciseVideoData.isPublic);
      setThumbnailImage(pathImage + exerciseVideoData.id);
      setThumbnailPath(exerciseVideoData.videoThumbnail);
      setActive(exerciseVideoData.active);

      if (exerciseVideoData.duration) {
        const duration = dayjs.duration(exerciseVideoData.duration, "seconds");
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        setVideoDuration(
          `${stringPad(hours, 2)}:${stringPad(minutes, 2)}:${stringPad(
            seconds,
            2
          )}`
        );
      }
      if (
        (exerciseVideoData.videoPath !== "" ||
          exerciseVideoData.videoPath !== null) &&
        (exerciseVideoData.youtubeUrl === "" ||
          exerciseVideoData.youtubeUrl === null)
      ) {
        console.log("upload video");
        setYoutubeUrl("");
        setVideoPath(exerciseVideoData.videoPath);
        setThumbnailVideo(exerciseVideoData.videoUrl);
        setTypeVideo("uploadVideo");
        setShowUpload(true);
        console.log("thumbnail video in edit mode", thumbnailVideo);
      } else {
        console.log("youtube url");
        setYoutubeUrl(exerciseVideoData.youtubeUrl);
        setRefUrl(exerciseVideoData.youtubeUrl);
        setVideoPath("");
        setTypeVideo("youtubeUrl");
        setShowUpload(false);
        console.log("youtube url in edit mode", youtubeUrl);
      }
      console.log("Type video in edit mode", typeVideo);
      console.log("show upload in edit mode", showUpload);
      setSaveText("Save");
      setPageTitle("Update Video");
    } else {
      setEditMode(false);
      setSaveText("Add");
      setPageTitle("Add Video");
    }
  }, []);

  useEffect(() => {
    console.log("typeVideo", typeVideo);
  }, [typeVideo]);

  //get youtube id
  useEffect(() => {
    if (youtubeUrl !== "") {
      console.log("youtube url: ", youtubeUrl);
      if (youtubeUrl.includes("v=")) {
        const videoId = youtubeUrl.split("v=")[1];
        const ampersandPosition = videoId.indexOf("&");
        if (ampersandPosition !== -1) {
          setYoutubeId(videoId.substring(0, ampersandPosition));
        }
        setYoutubeId(videoId);
      } else {
        const videoId = youtubeUrl.split("be/")[1];
        const ampersandPosit = videoId.indexOf("&");
        if (ampersandPosit !== -1) {
          setYoutubeId(videoId.substring(0, ampersandPosit));
        }
        setYoutubeId(videoId);
      }
    }
  }, [youtubeUrl]);

  useEffect(() => {
    if (youtubeId) {
      console.log("youtubeId", youtubeId);
    }
  }, [youtubeId]);

  useEffect(() => {
    console.log("showUpload", showUpload);
    console.log("thumbnailVIdeo", thumbnailVideo);
  }, [showUpload]);

  //handle toggle public
  const handleTogglePublic = (e: any) => {
    setIsPublic(e.target.checked);
  };

  useEffect(() => {
    console.log("isPublic", isPublic);
  }, [isPublic]);

  //handle thumbnail
  const handleThumbnailFileChange = (e: any) => {
    const MAX_FILE_SIZE = 2 * 1024; // 100MB

    if (!e.target.files || !e.target.files[0]) {
      setThumbnailPathError("Please choose a file");
      return false;
    }

    const file = e.target.files[0];
    const fileSizeKiloBytes = file.size / 1024;

    if (e.target.files) {
      const thumbnailRegex = /\.(jpe?g|png)$/i;
      if (!thumbnailRegex.test(e.target.files[0].name)) {
        setThumbnailPathError("please upload image file and correct format.");
        return;
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        setThumbnailPathError("File size is bigger than 2MB");
        return;
      } else {
        setThumbnailFile(e.target.files[0]);
        setThumbnailPath(e.target.files[0].name);
        setThumbnailPathError("");
      }
    }
  };

  useEffect(() => {
    if (thumbnailFile) {
      const thumbnailUrl = URL.createObjectURL(thumbnailFile);
      setThumbnailImage(thumbnailUrl);
    }
  }, [thumbnailPath]);

  useEffect(() => {
    console.log("videoPath", videoPath);
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setThumbnailVideo(videoUrl);
    } else if (videoPath.includes("videos-exercise")) {
      setThumbnailVideo(
        exerciseVideoData!.videoUrl + "?" + exerciseVideoData!.videoPath
      );
    }
  }, [videoPath]);

  const handleChangeTypeVideo = (e: any) => {
    setTypeVideo(e.target.value);
    if (!editMode) {
      if (e.target.value === "youtubeUrl") {
        setShowUpload(false);
        setYoutubeUrl("");
        setYoutubeUrlError("");
        setYoutubeId("");
        setRefUrl("");
        setRefUrlError("");
      } else {
        setThumbnailVideo("");
        setShowUpload(true);
        setVideoPathError("");
        setVideoPath("");
      }
    } else {
      if (e.target.value === "youtubeUrl") {
        setShowUpload(false);
      } else {
        setShowUpload(true);
      }
      if (exerciseVideoData) {
        if (
          (exerciseVideoData.videoPath !== "" ||
            exerciseVideoData.videoPath !== null) &&
          (exerciseVideoData.youtubeUrl === "" ||
            exerciseVideoData.youtubeUrl === null)
        ) {
          setYoutubeUrl("");
          setRefUrl("");
          setVideoPath(exerciseVideoData.videoPath);
          setThumbnailVideo(exerciseVideoData.videoUrl);
        } else {
          setYoutubeUrl(exerciseVideoData.youtubeUrl);
          setRefUrl(exerciseVideoData.youtubeUrl);
          setVideoPath("");
          setThumbnailVideo("");
        }
      }
    }
  };

  const opts = {
    height: "300",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  useEffect(() => {
    if (refUrl && refUrlError === "") {
      setYoutubeUrl(refUrl);
    }
  }, [refUrl, refUrlError]);

  useEffect(() => {
    if (youtubeUrl) {
      console.log("youtubeUrl", youtubeUrl);
    }
  }, [youtubeUrl]);

  const validateVideoDuration = (value: string) => {
    setVideoDurationError("");
    return true;
  };

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ my: "24px" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{ fontWeight: "bold" }}
          >
            {pageTitle}
          </Typography>
          <IconButton onClick={() => onCloseDialog()}>
            <ClearIcon style={{ color: "#959697" }} />
          </IconButton>
        </Box>
        <Divider style={{ marginTop: "16px", marginBottom: "32px" }} />
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: "32px" }}
        >
          <Box style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold" }}>
              Thumbnail &nbsp;
            </Typography>
            <Typography style={{ color: COLOR.SubHeader }}>
              (Support format: JPEG, PNG maximum upload file size 2 mb)
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "16px 0px",
            }}
          >
            <Box
              style={{
                width: "128px",
                height: "128px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: thumbnailPath ? "" : "#FAFAFA",
                border: thumbnailPath ? "" : "1px dashed #D8D8D8",
                borderRadius: "6px",
              }}
            >
              {thumbnailPath ? (
                <img
                  src={thumbnailImage}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <ImageIcon
                  style={{ fontSize: "36px", color: COLOR.TextFieldIcon }}
                />
              )}
            </Box>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                id="thumbnailPath"
                label="Upload Thumbnail"
                inputRef={thumbnailPathRef}
                name="thumbnailPath"
                disabled={true}
                value={thumbnailPath}
                error={!!thumbnailPathError}
                helperText={thumbnailPathError}
                InputLabelProps={{
                  style:{
                    color: thumbnailPathError ? COLOR.Error : COLOR.PlaceHolder,
                  },
                }}
                sx={{
                  "& fieldset": {
                    borderColor: thumbnailPathError
                      ? COLOR.Error
                      : COLOR.Border,
                    borderRadius: '6px'
                  },
                  "& .Mui-error": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: COLOR.Error,
                    },
                  },
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: COLOR.Error,
                  },
                  "& .MuiFormLabel-asterisk":{
                    color: thumbnailPathError ? COLOR.Error : COLOR.PlaceHolder,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                component="label"
                style={{
                  width: "100%",
                  paddingTop: "13px",
                  paddingBottom: "13px",
                  textTransform: "none",
                  backgroundColor: COLOR.Primary,
                  color: COLOR.White,
                  boxShadow: "none",
                  fontWeight: "bold",
                  borderRadius: "6px",
                  fontSize: "17px",
                }}
              >
                Choose file
                <input
                  type="file"
                  hidden
                  accept="image/png, image/jpeg"
                  onChange={handleThumbnailFileChange}
                />
              </Button>
            </Grid>
          </Grid>

          <TextField
            required
            fullWidth
            inputRef={titleRef}
            id="title"
            label="Video Title"
            name="title"
            autoFocus= {exerciseVideoData? false : true}
            onChange={(e) => {
              if (e.target.value.includes("<") || e.target.value.includes(">"))
                return;
              setTitle(e.target.value);
              validateVideoTitle(e.target.value);
            }}
            value={title}
            error={!!titleError}
            helperText={titleError}
            onFocus={() => setTitleFocused(true)}
            onBlur={() => setTitleFocused(false)}
            InputLabelProps={{
              style:{
                color: titleError ? COLOR.Error : titleFocused ? COLOR.Focused : COLOR.PlaceHolder,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {title && (
                    <>
                      {titleError ? (
                        <IconButton
                          edge="end"
                          onClick={() => {
                            setTitle("");
                            validateVideoTitle("");
                          }}
                        >
                          <ErrorIcon style={{ color: COLOR.Error }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          onClick={() => {
                            setTitle("");
                            validateVideoTitle("");
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: COLOR.PlaceHolder
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: COLOR.White,
                "&.Mui-focused fieldset": {
                  borderColor: titleError ? COLOR.Error : COLOR.Primary,
                },
                "&:hover fieldset": {
                  borderColor: titleError ? COLOR.Error : COLOR.Border,
                },
                "&.Mui-focused:hover fieldset": {
                  borderColor: titleError ? COLOR.Error : COLOR.Primary,
                },
              },
              "& label.Mui-focused": {
                color: titleError ? COLOR.Error : COLOR.Primary,
              },
              "& fieldset": {
                borderColor: titleError ? COLOR.Error : COLOR.Border,
                borderRadius: '6px'
              },
              "& .Mui-error": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLOR.Error,
                },
              },
              "& .MuiFormHelperText-root.Mui-error": {
                color: COLOR.Error,
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
              },
              "& .MuiFormLabel-asterisk":{
                color: titleError ? COLOR.Error : titleFocused ? COLOR.Focused : COLOR.PlaceHolder,
              },
              marginTop: "32px",
              paddingTop: 0,
            }}
          />

          <Typography
            style={{ fontWeight: "bold" }}
            sx={{ mt: "32px", mb: "16px" }}
          >
            Type Video
          </Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="typeVideo"
              value={typeVideo}
              name="typeVideo"
              onChange={(e) => handleChangeTypeVideo(e)}
              sx={{ ml: "10px" }}
            >
              <FormControlLabel
                value="youtubeUrl"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: COLOR.Primary,
                      },
                    }}
                  />
                }
                label="YouTube URL"
              />
              <FormControlLabel
                value="uploadVideo"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: COLOR.Primary,
                      },
                    }}
                  />
                }
                label="Upload Video"
              />
            </RadioGroup>
          </FormControl>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            sx={{ mt: "32px", mb: "16px" }}
          >
            <Box style={{ display: "flex" }}>
              <Typography style={{ fontWeight: "bold" }}>
                {showUpload ? "Upload Video" : "Youtube URL"}
              </Typography>
              {showUpload && (
                <Typography style={{ color: COLOR.SubHeader }}>
                  &nbsp; (Support format: MP4 maximum upload file size 100 mb)
                </Typography>
              )}
            </Box>

            <FormControlLabel
              control={
                <Switch
                  checked={isPublic}
                  onChange={(e) => {
                    handleTogglePublic(e);
                  }}
                  sx={{ my: 0 }}
                />
              }
              label="Public"
              labelPlacement="start"
              sx={{ fontSize: "14px", color: COLOR.SubHeader }}
            />
          </Box>

          <Box
            style={{
              display:
                (youtubeUrl && !showUpload) || (videoPath && showUpload)
                  ? "none"
                  : "flex",
              width: "100%",
              border: "1px dashed #D8D8D8",
              borderRadius: "6px",
              backgroundColor: "#FAFAFA",
              height: "200px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <OndemandVideoIcon
              style={{ color: COLOR.PlaceHolder, fontSize: "48px" }}
            />
          </Box>

          {youtubeId && !showUpload && (
            <YouTube videoId={youtubeId} opts={opts} />
          )}

          <TextField
            fullWidth
            required
            style={{
              display: !showUpload ? "block" : "none",
              marginTop: "16px",
            }}
            inputRef={youtubeUrlRef}
            id="youtubeUrl"
            label="Reference URL"
            name="youtubeUrl"
            value={refUrl}
            onChange={(e) => {
              setRefUrl(e.target.value);
              validateYoutubeUrl(e.target.value);
            }}
            error={!!refUrlError}
            helperText={refUrlError}
            onFocus={() => setRefUrlFocused(true)}
            onBlur={() => setRefUrlFocused(false)}
            InputLabelProps={{
              style:{
                color: refUrlError ? COLOR.Error : refUrlFocused ? COLOR.Focused : COLOR.PlaceHolder,
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: COLOR.White,
                "&.Mui-focused fieldset": {
                  borderColor: refUrlError ? COLOR.Error : COLOR.Primary,
                },
                "&:hover fieldset": {
                  borderColor: refUrlError ? COLOR.Error : COLOR.Border,
                },
                "&.Mui-focused:hover fieldset": {
                  borderColor: refUrlError ? COLOR.Error : COLOR.Primary,
                },
              },
              "& label.Mui-focused": {
                color: refUrlError ? COLOR.Error : COLOR.Primary,
              },
              "& fieldset": {
                borderColor: refUrlError ? COLOR.Error : COLOR.Border,
                borderRadius: '6px'
              },
              "& .Mui-error": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: COLOR.Error,
                },
              },
              "& .MuiFormHelperText-root.Mui-error": {
                color: COLOR.Error,
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
              },
              "& .MuiFormLabel-asterisk":{
                color: refUrlError ? COLOR.Error : refUrlFocused ? COLOR.Focused : COLOR.PlaceHolder,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {refUrl && (
                    <>
                      {refUrlError ? (
                        <IconButton
                          edge="end"
                          onClick={() => {
                            setRefUrl("");
                            setYoutubeUrl("");
                            validateYoutubeUrl("");
                          }}
                        >
                          <ErrorIcon style={{ color: COLOR.Error }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          onClick={() => {
                            setRefUrl("");
                            setYoutubeUrl("");
                            validateYoutubeUrl("");
                          }}
                          style={{ display: "flex", color: COLOR.PlaceHolder }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <Box
            style={{
              display: showUpload ? "" : "none",
              marginBottom: "16px",
            }}
          >
            {thumbnailVideo !== "" ? (
              <video width="100%" height="240" controls src={thumbnailVideo} />
            ) : null}
          </Box>

          <Grid
            style={{
              display: showUpload ? "" : "none",
              padding: 0,
            }}
            container
            spacing={1}
          >
            <Grid item xs={12} sm={9} sx={{ padding: 0, margin: 0 }}>
              <TextField
                fullWidth
                required
                id="videoPath"
                label="Video Path"
                inputRef={videoPathRef}
                name="videoPath"
                autoFocus
                disabled={true}
                value={videoPath}
                error={!!videoPathError}
                helperText={videoPathError}
                onChange={(e) => {
                  setVideoPath(e.target.value);
                  validateVideoPath(e.target.value);
                }}
                InputLabelProps={{
                  style:{
                    color: videoPathError ? COLOR.Error : COLOR.PlaceHolder,
                  },
                }}
                sx={{
                  "& fieldset": {
                    borderColor: videoPathError ? COLOR.Error : COLOR.Border,
                    borderRadius: '6px'
                  },
                  "& .Mui-error": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: COLOR.Error,
                    },
                  },
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: COLOR.Error,
                  },
                  "& .MuiFormLabel-asterisk":{
                    color: videoPathError ? COLOR.Error : COLOR.PlaceHolder,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="contained"
                component="label"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "17px",
                  paddingTop: "13px",
                  paddingBottom: "13px",
                  textTransform: "none",
                  boxShadow: "none",
                  backgroundColor: COLOR.Primary,
                  borderRadius: "6px",
                }}
              >
                Choose file
                <input
                  type="file"
                  hidden
                  accept="video/mp4"
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
          </Grid>

          <InputMask
            mask="99:99:99"
            value={videoDuration}
            onChange={(e) => {
              setVideoDuration(e.target.value);
              validateVideoDuration(e.target.value);
            }}
          >
            <TextField
              required
              fullWidth
              inputRef={videoDurationRef}
              id="time"
              label="Time (hh:mm:ss Ex. 00:30:15 (30 Minutes 15 seconds))"
              name="time"
              error={!!videoDurationError}
              helperText={videoDurationError}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: COLOR.White,
                  "&.Mui-focused fieldset": {
                    borderColor: videoDurationError
                      ? COLOR.Error
                      : COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: videoDurationError
                      ? COLOR.Error
                      : COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset": {
                    borderColor: videoDurationError
                      ? COLOR.Error
                      : COLOR.Primary,
                  },
                },
                "& label.Mui-focused": {
                  color: videoDurationError ? COLOR.Error : COLOR.Primary,
                },
                "& fieldset": {
                  borderColor: videoDurationError ? COLOR.Error : COLOR.Border,
                  borderRadius: '6px'
                },
                "& .Mui-error": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLOR.Error,
                  },
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: COLOR.Error,
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
                mt: "32px",
                mb: "32px",
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment
                      position="end"
                      style={{ display: videoDuration ? "" : "none" }}
                    >
                      {videoDuration && (
                        <>
                          {videoDurationError ? (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setVideoDuration("00:01:00");
                                setVideoDurationError("");
                              }}
                            >
                              <ErrorIcon style={{ color: COLOR.Error }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setVideoDuration("00:00:00");
                                setVideoDurationError("");
                              }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: COLOR.PlaceHolder
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          )}
                        </>
                      )}
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </InputMask>

          <Grid
            container
            spacing={2}
            direction={isSmallScreen ? "column-reverse" : "row"}
          >
            <Grid item xs={12} sm={6}>
              <Button
                onClick={() => onCloseDialog()}
                fullWidth
                variant="outlined"
                style={{
                  color: COLOR.Primary,
                  boxShadow: "none",
                  textTransform: "none",
                  borderColor: COLOR.Primary,
                  fontWeight: "bold",
                  fontSize: "17px",
                  borderRadius: '6px'
                }}
                sx={{
                  py: "12px",
                  "&:hover": {
                    backgroundColor: COLOR.White,
                  },
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: COLOR.Primary,
                  color: COLOR.White,
                  boxShadow: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "17px",
                  borderRadius: '6px'
                }}
                sx={{ py: "13px" }}
              >
                {saveText}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
