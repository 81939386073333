import { BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import { APP_ROUTES } from './utils/constants';
import Login from './screen/login';
import Home from './screen/home';
import User from './screen/user';
import Meeting from './screen/meeting';
import UseToken from './services/useToken';
import MeetingDetail from './screen/meetingDetail';
import Video from './screen/video';
import ResetPassword from './screen/resetPassword';
import { ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import ForgotPassword from './screen/forgotPassword';

function App() {
  const { setToken, token, getToken } = UseToken();
  const isAuth = getToken() ? true : false;

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Kanit',
        'sans-serif',
      ].join(','),
    },
  });
  
  return (
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Navigate to={APP_ROUTES.MEETING} />} />
        <Route path={APP_ROUTES.USER} element={isAuth? <User /> : <Login setToken={setToken}/>} />
        <Route path={APP_ROUTES.LOGIN} element={<Login setToken={setToken}/>} />
        <Route path={APP_ROUTES.HOME} element={isAuth? <Home /> : <Login setToken={setToken}/>} />
        <Route path={APP_ROUTES.MEETING} element={isAuth? <Meeting /> : <Login setToken={setToken}/>} />
        <Route path={APP_ROUTES.MEETINGDETAIL} element={isAuth? <MeetingDetail /> : <Login setToken={setToken}/>} />
        <Route path={APP_ROUTES.VIDEO} element={isAuth? <Video /> : <Login setToken={setToken}/>} />
        <Route path={APP_ROUTES.RESETPASSWORD} element={<ResetPassword />} />
        <Route path={APP_ROUTES.FORGOTPASSWORD} element={<ForgotPassword setToken={setToken}/>} />
      </Routes>
    </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;