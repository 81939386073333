import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { Agenda, ExerciseAgenda, ExerciseVideo } from "../services/models";
import ExerciseAgendaList from "./exerciseAgendaList";
import { Divider, IconButton, InputAdornment, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { COLOR } from "../utils/constants";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import InputMask from "react-input-mask";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

type AgendaDetailProp = {
  saveAgenda: Function;
  agendaData: Agenda | null;
  onCloseDialog: Function;
  meetingID: number;
  handleExerciseAgendaList: (videoList: ExerciseAgenda[]) => void;
  isWaiting: boolean;
  sequenceLength: number;
};

export default function AgendaDetail({
  saveAgenda,
  agendaData,
  onCloseDialog,
  meetingID,
  handleExerciseAgendaList,
  isWaiting,
  sequenceLength,
}: AgendaDetailProp) {
  const [title, setTitle] = useState<string>("");
  const [pageTitle] = useState<string>(
    agendaData ? "Update Topic" : "Add Topic"
  );
  const [time, setTime] = useState<string>('00:01:00');
  const [agendaType, setAgendaType] = useState<string>("AGENDA");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [saveText] = useState<string>(agendaData ? "Save" : "Add");

  const [titleError, setTitleError] = useState<string>("");
  const [timeError, setTimeError] = useState<string>("");
  const [titleFocused, setTitleFocused] = useState<boolean>(false);

  const titleRef = useRef<HTMLInputElement>(null);
  const timeRef = useRef<HTMLInputElement>(null);
  const [timeFocused, setTimeFocused] = useState<boolean>(false);

  const [exerciseAgendaList, setExerciseAgendaList] = useState<ExerciseAgenda[]>([]);
  const [exerciseAgendaListError, setExerciseAgendaListError] = useState<string>("");

  const [scrollToError, setScrollToError] = useState<boolean>(false);

  const handleSubmit = async(e: any) => {
    e.preventDefault();
    console.log("handleSubmit");
    console.log('exerciseAgendaList length', exerciseAgendaList.length);
    const timeArr = time.split(":");
    const hour = parseInt(timeArr[0]);
    const minute = parseInt(timeArr[1]);
    const second = parseInt(timeArr[2]);

    if(title.trim() === "" || title.trim().length < 5 || title.trim().length > 50){
      setTitleError("Title Name must be more than 5 characters long and no nore than 50 characters.");
      if(titleRef && titleRef.current){
        titleRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    if(isNaN(hour) || isNaN(minute) || isNaN(second) || time === '00:00:00'){
      setTimeError("Time is invalid format.");
      if(timeRef && timeRef.current){
        timeRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    if(agendaType === 'EXERCISE' && (exerciseAgendaList.length === 0 || exerciseAgendaList.length > 6)){
      setExerciseAgendaListError("Select a exercise video maximum 6 videos.");
    }

    if(
      titleError === '' && 
      timeError === '' && 
      title.trim() !== '' &&
      time !== '00:00:00'
    ){
      if(agendaType === 'EXERCISE' && (exerciseAgendaList.length === 0 || exerciseAgendaList.length > 6)){
        setScrollToError(true);
        return;
      }
      //format time to seconds
      const seconds = hour * 3600 + minute * 60 + second; // TODO: Need to clean up variable or change unit

      const res = await saveAgenda({
        sequence: agendaData ? agendaData.sequence : sequenceLength + 1,
        time: seconds,
        title,
        type: agendaType,
        id: agendaData ? agendaData.id : 0,
        meetingID: agendaData ? agendaData.meetingID : 0,
        createdBy: agendaData ? agendaData.createdBy : 0,
      });
      handleExerciseAgendaList(exerciseAgendaList);
      if(res){
        console.log("save agenda success.");
        onCloseDialog();
      }
      else{
        console.log("save agenda failed.", res);
      }
    }
  };

  const validateTitle = (value: string) => {
    if (value.trim().length < 5 || value.trim().length > 50) {
      setTitleError("Title Name must be more than 5 characters long and no nore than 50 characters.");
    } else {
      setTitleError("");
    }
  };

  const validateTime = (value: string) => {
    const timeArr = value.split(":");
    const hour = parseInt(timeArr[0]);
    const minute = parseInt(timeArr[1]);
    const second = parseInt(timeArr[2]);
    if (isNaN(hour) || isNaN(minute) || isNaN(second)) {
      setTimeError("Time is invalid format.");
    } else if(time === '00:00:00'){
      setTimeError("Time cannot be 00:00:00.");
    }else {
      setTimeError("");
    }
  };

  const stringPad = (ns:number, width:number) => {
    const z = "0";
    const n = ns + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  };

  useEffect(() => {
    if (agendaData && agendaData.id) {
      setEditMode(true);
      setAgendaType(agendaData.type);
      setTitle(agendaData.title);

      if(agendaData.time !== 0){
        const duration = dayjs.duration(agendaData.time, "seconds");
        setTime(`${stringPad(duration.hours(), 2)}:${stringPad(duration.minutes(), 2)}:${stringPad(duration.seconds(), 2)}`);
      }

    } else {
      setEditMode(false);
    }
  }, []);

  const handleChangeAgendaType = (event: any) => {
    setAgendaType(event.target.value);
  };

  const handleSaveExerciseAgendaList = (videoList: ExerciseAgenda[]) =>{
    setExerciseAgendaList(videoList);
  }

  useEffect(() => {
    handleExerciseAgendaList(exerciseAgendaList? exerciseAgendaList : []);
    if(editMode){
      if(exerciseAgendaList && exerciseAgendaList.length !== 0){
        console.log('exerciseAgendaList', exerciseAgendaList);
      }
    }
    if(exerciseAgendaList){
      if(exerciseAgendaList.length < 0 || exerciseAgendaList.length > 6){
        setExerciseAgendaListError("Select a exercise video maximum 6 videos.");
        console.log('exerciseAgendaListError', exerciseAgendaListError);
      }else{
        setExerciseAgendaListError("");
      }
    }
  }, [exerciseAgendaList]);

  return (
    <Container component="main">
      <Box sx={{ my: '24px' }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            component="h6"
            variant="h6"
            style={{ fontWeight: "bold" }}
          >
            {pageTitle}
          </Typography>
          <IconButton aria-label="close" onClick={()=>onCloseDialog()}>
            <ClearIcon style={{ color: "#959697" }} />
          </IconButton>
        </Box>
        <Divider style={{ marginTop: "16px", marginBottom: '32px' }} />
        <Box component="form" onSubmit={handleSubmit} noValidate >
          
         <Typography style={{ fontWeight: 'bold' }} sx={{ mt: '32px', mb: '8px'}}>
            Topic Type
          </Typography>
          
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="agendaType"
              value={agendaType}
              name="typeVideo"
              onChange={handleChangeAgendaType}
              sx={{ ml: '10px', mb: '32px' }}
            >
              <FormControlLabel
                value="AGENDA"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: COLOR.Primary,
                      },
                    }}
                  />
                }
                label="Topic" />
              <FormControlLabel
                value="EXERCISE"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: COLOR.Primary,
                      },
                    }}
                  />
                }
                label="Exercise" />
            </RadioGroup>
          </FormControl>

          <TextField
            disabled={!isWaiting}
            required
            fullWidth
            inputRef={titleRef}
            id="title"
            label="Title Name"
            name="title"
            value={title}
            onChange={(e) => {
              if(e.target.value.match(/[\u0E00-\u0E7Fa-zA-Z0-9']/g) || e.target.value === ''){
                setTitle(e.target.value);
                validateTitle(e.target.value);
              }else{
                return;
              }
            }}
            autoFocus
            error={!!titleError}
            helperText={titleError}
            onFocus={() => setTitleFocused(true)}
            onBlur={() => setTitleFocused(false)}
            InputLabelProps={{
              style:{
                color: titleError ? COLOR.Error : titleFocused ? COLOR.Focused : COLOR.PlaceHolder,
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: COLOR.White,
                "&.Mui-focused fieldset": {
                  borderColor: titleError? COLOR.Error : COLOR.Primary,
                },
                "&:hover fieldset": {
                  borderColor: titleError? COLOR.Error : COLOR.Border,
                },
                "&.Mui-focused:hover fieldset":{
                  borderColor: titleError? COLOR.Error : COLOR.Primary,
                }
              },
              "& label.Mui-focused": {
                color: titleError? COLOR.Error : COLOR.Primary,
              },
              '& fieldset': {
                borderColor: titleError? COLOR.Error : COLOR.Border,
                borderRadius: '6px'
              },
              '& .Mui-error':{
                '& .MuiOutlinedInput-notchedOutline':{
                  borderColor: COLOR.Error,
                },
              },
              '& .MuiFormHelperText-root.Mui-error':{
                color: COLOR.Error,
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
              },
              "& .MuiFormLabel-asterisk":{
                color: titleError ? COLOR.Error : titleFocused ? COLOR.Focused : COLOR.PlaceHolder,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {isWaiting && (
                    <>
                      {title && (
                        <>
                          {titleError ? (
                          <IconButton
                            edge="end"
                            onClick={() => {
                              setTitle('');
                              validateTitle('');
                            }}
                          >
                            <ErrorIcon style={{ color: COLOR.Error}} />
                          </IconButton>
                        ) : (
                          <IconButton
                            edge="end"
                            onClick={() => {
                              setTitle('');
                              validateTitle('');
                            }}
                            style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: COLOR.PlaceHolder}}
                          >
                            <CancelIcon />
                          </IconButton>
                      )}
                        </>
                      )}
                    </>
                  )}
                  
                </InputAdornment>
              ),
            }}
          />


          <InputMask
            mask="99:99:99"
            value={time}
            disabled={!isWaiting}
            onChange={(e) => {
              setTime(e.target.value);
              validateTime(e.target.value);
            }}
            onFocus={() => setTimeFocused(true)}
            onBlur={() => setTimeFocused(false)}
          >
            <TextField 
              disabled={!isWaiting}
              required
              fullWidth
              inputRef={timeRef}
              id="time"
              label="Time (hh:mm:ss Ex. 00:30:15 (30 Minutes 15 seconds))"
              name="time"
              error={!!timeError}
              helperText={timeError}
              InputLabelProps={{
                style:{
                  color: timeError ? COLOR.Error : timeFocused ? COLOR.Focused : COLOR.PlaceHolder,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: COLOR.White,
                  "&.Mui-focused fieldset": {
                    borderColor: timeError? COLOR.Error : COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: timeError? COLOR.Error : COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset":{
                    borderColor: timeError? COLOR.Error : COLOR.Primary,
                  }
                },
                "& label.Mui-focused": {
                  color: timeError? COLOR.Error : COLOR.Primary,
                },
                '& fieldset': {
                  borderColor: timeError? COLOR.Error : COLOR.Border,
                  borderRadius: '6px'
                },
                '& .Mui-error':{
                  '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: COLOR.Error,
                  },
                },
                '& .MuiFormHelperText-root.Mui-error':{
                  color: COLOR.Error,
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
                mt: '32px',
                mb: '32px',
                "& .MuiFormLabel-asterisk":{
                  color: timeError ? COLOR.Error : timeFocused ? COLOR.Focused : COLOR.PlaceHolder,
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end" style={{display: time? '' : 'none'}}>
                      {isWaiting && (
                        <>
                          {time && (
                            <>
                              {timeError ? (
                                <IconButton
                                  edge="end"
                                  onClick={() => {
                                    setTime('00:01:00');
                                    setTimeError('');
                                  }}
                                >
                                  <ErrorIcon style={{ color: COLOR.Error }} />
                                </IconButton>
                              ) : (
                                <IconButton
                                  edge="end"
                                  onClick={() => {
                                    setTime('00:01:00');
                                    setTimeError('');
                                  }}
                                  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: COLOR.PlaceHolder}}
                                >
                                  <CancelIcon />
                                </IconButton>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </InputMask>

          {agendaType === "EXERCISE" && (
            <ExerciseAgendaList
              agendaID={agendaData ? agendaData.id : 0}
              meetingID={meetingID}
              handleExerciseAgendaList={handleSaveExerciseAgendaList}
              exerciseAgendaListError={exerciseAgendaListError}
              isWaiting={isWaiting}
              editMode={editMode}
              isError={scrollToError}
            ></ExerciseAgendaList>
          )}

          {isWaiting && (
            <>
              <Grid container spacing={2} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => onCloseDialog()}
                    fullWidth
                    variant="outlined"
                    style={{
                      textTransform: 'none',
                      color: COLOR.Primary,
                      borderColor: COLOR.Primary,
                      fontWeight: 'bold',
                      fontSize: '17px',
                      borderRadius: '6px'
                    }}
                    sx={{ py: '12px' }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ 
                      textTransform: 'none', 
                      backgroundColor: COLOR.Primary, 
                      color: COLOR.White,
                      fontWeight: 'bold',
                      fontSize: '17px',
                      boxShadow: 'none',
                      borderRadius: '6px'
                    }}
                    sx={{ py: '13px' }}
                  >
                    {editMode ? "Save" : "Add"}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}
