import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  AppBar,
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Dialog,
  Divider,
  useMediaQuery,
  Alert,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Visibility, VisibilityOff} from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { COLOR, APP_ROUTES } from "../utils/constants";
import logo from "../assets/images/logo_thaihealth.png";
import { resetPassword } from "../services/api";

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Kanit", "sans-serif"].join(","),
  },
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [newPasswordError, setNewPasswordError] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");

  const [newPasswordFocused, setNewPasswordFocused] = useState<boolean>(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState<boolean>(false);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [dialogSuccessOpen, setDialogSuccessOpen] = useState(false);
  const [dialogErrorOpen, setDialogErrorOpen] = useState(false);

  const [respError, setRespError] = useState("");

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const styles = {
    root: {
      display: "flex",
      height: "100%",
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
      padding: "60px 0",
    },
    container: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      marginTop: "60px",
      maxWidth: "950px",
      width: "950px",
    },
    bodyBox: {
      backgroundColor: COLOR.White,
      display: "flex",
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      padding: "80px 0",
      borderRadius: "20px",
      boxShadow: "0 3px 5px rgba(0,0,0,0.25)",
      width: "100%",
    },
    textField: {
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: COLOR.Primary,
        },
      },
      "& label.Mui-focused": {
        color: COLOR.Primary,
      },
    },
  };

  useEffect(() => {
    console.log("location", location);
    const email = new URLSearchParams(location.search).get("email");
    const token = new URLSearchParams(location.search).get("token");

    if (email && token) {
      setEmail(email);
      setToken(token);
      console.log("email", email);
      console.log("token", token);
    }
  });

  const handleSubmitResetPassword = async (e: any) => {
    e.preventDefault();

    console.log("handleSubmit");
    if (newPassword === "") {
      setNewPasswordError(
        "Password must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter and 1 number"
      );
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Password does not match");
    }

    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPasswordError === "" &&
      confirmPasswordError === ""
    ) {
      const res = await resetPassword(newPassword, email, token);
      console.log(`change response ${JSON.stringify(res)}`);
      if (res && res.updated) {
        setRespError("");
        setDialogSuccessOpen(true);
      } else {
        setDialogErrorOpen(true);
        setRespError(res.message);
      }
    }
  };

  const validateNewPassword = (value: string) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    if (value.length < 8 || !passwordRegex.test(value)) {
      setNewPasswordError(
        "Password must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter and 1 number"
      );
    } else {
      setNewPasswordError("");
      if (confirmPassword) {
        validateConfirmPassword(confirmPassword);
      }
    }
  };

  const validateConfirmPassword = (value: string) => {
    console.log("validate confirm password");
    if (value === "" || value !== newPassword) {
      setConfirmPasswordError("Password does not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  useEffect(() => {
    if (newPassword) {
      validateNewPassword(newPassword);
    }
    if (confirmPassword) {
      validateConfirmPassword(confirmPassword);
    }
  }, [newPassword, confirmPassword]);

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Dialog
        fullWidth
        open={dialogSuccessOpen}
        PaperProps={{
          style: {
            position: "fixed",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            top: "0",
            borderRadius: "10px",
            maxWidth: "740px",
          },
        }}
      >
        <Container component="main" style={{ maxWidth: "1200px" }}>
          <Box sx={{ m: "24px 0" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bold" }}
              >
                Reset Password
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setDialogSuccessOpen(false);
                  navigate(APP_ROUTES.LOGIN);
                }}
              >
                <ClearIcon style={{ color: "#959697" }} />
              </IconButton>
            </Box>
            <Divider style={{ marginTop: "16px", marginBottom: "32px" }} />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <CheckCircleIcon
                  style={{ color: COLOR.Success, fontSize: "80px" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <Typography style={{textAlign: 'center'}}>
                  Your password has been changed successfully.
                </Typography>
              </Box>
              <Button
                variant="contained"
                style={{
                  fontWeight: "bold",
                  backgroundColor: COLOR.Primary,
                  textTransform: "none",
                  boxShadow: "none",
                  fontSize: "17px",
                  padding: "13px 0",
                  width: isSmallScreen ? "100%" : "80%",
                  borderRadius: "6px",
                }}
                onClick={() => {
                  setDialogSuccessOpen(false);
                  navigate(APP_ROUTES.LOGIN);
                }}
              >
                Login now
              </Button>
            </Box>
          </Box>
        </Container>
      </Dialog>

      <Dialog
        fullWidth
        open={dialogErrorOpen}
        PaperProps={{
          style: {
            position: "fixed",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            top: "0",
            borderRadius: "10px",
            maxWidth: "740px",
          },
        }}
      >
        <Container component="main" style={{ maxWidth: "1200px" }}>
          <Box
            sx={{ m: "24px 0" }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                style={{ fontWeight: "bold" }}
              >
                Reset Password Error
              </Typography>
            </Box>
            <Divider style={{ marginTop: "16px"}} />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "50px",
                marginBottom: "30px",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                }}
              >
                Cannot update your password.
                <br />
                <span>{respError}</span>
              </Typography>
            </Box>
            <Button
              variant="contained"
              style={{
                fontWeight: "bold",
                backgroundColor: COLOR.Primary,
                textTransform: "none",
                boxShadow: "none",
                fontSize: "17px",
                padding: "13px 0",
                width: isSmallScreen ? "100%" : "80%",
                borderRadius: "6px",
              }}
              onClick={() => {
                setDialogErrorOpen(false);
                navigate(APP_ROUTES.FORGOTPASSWORD);
              }}
            >
              Resend reset password.
            </Button>
          </Box>
        </Container>
      </Dialog>

      <Box
        style={{
          display: "block",
          height: "100vh",
          width: "100vw",
          minHeight: "850px",
          backgroundColor: COLOR.Hovered,
        }}
      >
        <Box component="main" sx={styles.root}>
          <AppBar>
            <Box
              style={{
                display: "flex",
                backgroundColor: COLOR.White,
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
              }}
            >
              <img src={logo} style={{ height: "60px", marginLeft: "20px" }} />
            </Box>
          </AppBar>

          <Container sx={styles.container}>
            <CssBaseline />
            <Box sx={styles.bodyBox}>
              <img src={logo} style={{ height: "90px" }} />
              <Typography variant="h4" style={{ fontWeight: "bold" }}>
                Reset Password
              </Typography>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                sx={{
                  px: { lg: "193px", md: "55px", xs: "24px" },
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "40px 0px",
                  }}
                  align="center"
                >
                  Enter a new password to reset the password on your account.
                  <br />
                  We'll ask for this password whenever you login.
                </Typography>
                <TextField
                  id="newPassword"
                  label="New password"
                  value={newPassword}
                  type={showNewPassword ? "text" : "password"}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    validateNewPassword(e.target.value);
                  }}
                  error={!!newPasswordError}
                  helperText={newPasswordError}
                  onFocus={() => setNewPasswordFocused(true)}
                  onBlur={() => setNewPasswordFocused(false)}
                  InputLabelProps={{
                    style:{
                      color: !!newPasswordError ? COLOR.Error : newPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showNewPassword ? (
                          <IconButton
                            onClick={handleShowNewPassword}
                            style={{
                              color: COLOR.PlaceHolder,
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={handleShowNewPassword}
                            style={{
                              color: COLOR.PlaceHolder,
                            }}
                          >
                            <VisibilityOff />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: COLOR.White,
                      "&.Mui-focused fieldset": {
                        borderColor: newPasswordError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                      "&:hover fieldset": {
                        borderColor: newPasswordError
                          ? COLOR.Error
                          : COLOR.Border,
                      },
                      "&.Mui-focused:hover fieldset": {
                        borderColor: newPasswordError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                    },
                    "& label.Mui-focused": {
                      color: newPasswordError ? COLOR.Error : COLOR.Primary,
                    },
                    "& fieldset": {
                      borderColor: newPasswordError
                        ? COLOR.Error
                        : COLOR.Border,
                      borderRadius: "6px",
                    },
                    "& .Mui-error": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR.Error,
                      },
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                      color: COLOR.Error,
                    },
                    "& .MuiFormLabel-asterisk":{
                      color: !!newPasswordError ? COLOR.Error : newPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    }
                  }}
                />
                <TextField
                  id="confirmPassword"
                  label="Confirm new password"
                  value={confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  style={{
                    margin: "40px 0",
                  }}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    validateConfirmPassword(e.target.value);
                  }}
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                  onFocus={() => setConfirmPasswordFocused(true)}
                  onBlur={() => setConfirmPasswordFocused(false)}
                  InputLabelProps={{
                    style:{
                      color: !!confirmPasswordError ? COLOR.Error : confirmPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showConfirmPassword ? (
                          <IconButton
                            onClick={handleShowConfirmPassword}
                            style={{
                              color: COLOR.PlaceHolder,
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={handleShowConfirmPassword}
                            style={{
                              color: COLOR.PlaceHolder,
                            }}
                          >
                            <VisibilityOff />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: COLOR.White,
                      "&.Mui-focused fieldset": {
                        borderColor: confirmPasswordError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                      "&:hover fieldset": {
                        borderColor: confirmPasswordError
                          ? COLOR.Error
                          : COLOR.Border,
                      },
                      "&.Mui-focused:hover fieldset": {
                        borderColor: confirmPasswordError
                          ? COLOR.Error
                          : COLOR.Primary,
                      },
                    },
                    "& label.Mui-focused": {
                      color: confirmPasswordError ? COLOR.Error : COLOR.Primary,
                    },
                    "& fieldset": {
                      borderColor: confirmPasswordError
                        ? COLOR.Error
                        : COLOR.Border,
                    },
                    "& .Mui-error": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: COLOR.Error,
                      },
                    },
                    "& .MuiFormHelperText-root.Mui-error": {
                      color: COLOR.Error,
                    },
                    "& .MuiFormLabel-asterisk":{
                      color: !!confirmPasswordError ? COLOR.Error : confirmPasswordFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    }
                  }}
                />
                <Button
                  variant="contained"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: COLOR.Primary,
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: "17px",
                    padding: "13px 0",
                    borderRadius: "6px",
                  }}
                  onClick={(e) => handleSubmitResetPassword(e)}
                >
                  Reset Password
                </Button>
                <Button
                  variant="text"
                  style={{
                    textTransform: "none",
                    color: COLOR.Primary,
                    marginTop: "24px",
                    padding: "0",
                  }}
                  onClick={() => navigate(APP_ROUTES.LOGIN)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Back to login
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
