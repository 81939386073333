import React, { useEffect, useState } from "react";
import UseToken from "../services/useToken";
import { APP_ROUTES, COLOR } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import Layout, { PropSnackbar, PropDialog } from "../components/layout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { Pagination } from "@mui/material";
import {
  updateUsers,
  deleteUsers,
  getUsersByPage,
  resendEmail,
  createUsers,
} from "../services/api";
import UserDetail from "../components/userDetail";
import { User as UserModel } from "../services/models";
import { styled } from "@mui/material/styles";
import EmailIcon from '@mui/icons-material/Email';
import { TextField, FormControl, Select, SelectChangeEvent, InputLabel, MenuItem, useMediaQuery, Switch } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f2',
    color: COLOR.Black,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: "1px solid #d8d8d8",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#f2f2f2',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function User() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [fullWidth] = useState<boolean>(true);
  const [userData, setUserData] = useState<UserModel|null>(null);
  const { token } = UseToken();
  const [users, setUsers] = useState<UserModel[]|[]>([]);
  const [propSnackbarOpen, setPropSnackbarOpen] = useState<PropSnackbar>({ open: false, type: 'success', message: 'success !!'})
  const [propDialogOpen, setPropDialogOpen] = useState<PropDialog>({ open: false, title: '', name:'', content: '', confirmText: '', cancelText: '', onConfirm: () => {}, onCancel: () => {}});
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  //searching...
  const [email, setEmail] = useState<string>('');
  const [organization, setOrganization] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [onClear, setOnClear] = useState<boolean>(false);
  const [isCreateUserSuccess, setIsCreateUserSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const fetchData = async () => {
    // You can await here
    const _isLoad = isLoading;
    if (!_isLoad) setIsLoading(true);
    const userList = await getUsersByPage(30, page, email, organization, name, role);
    console.log('userList', userList);
    if(userList && !userList.statusCode) {
      const userListPage = userList.data;
      if(userListPage && Array.isArray(userListPage)) {
        userListPage.sort((a: UserModel, b: UserModel) => (a.id < b.id ? 1 : -1))
        setUsers(userListPage ? userListPage : []);
        setTotalPage(userList.meta.totalPages);
      }
    }
    if (!_isLoad) setIsLoading(false);
  };

  useEffect(() => {
    if (!token) {
      navigate(APP_ROUTES.LOGIN);
    } else {
      fetchData();
    }
  }, [token]);

  const handleClickOpenDialog = () => {
    setUserData(null);
    setOpenDialog(true);
  };

  useEffect(()=>{
    console.log(`openDialog ${openDialog}`);
  }, [openDialog]);

  const handleCloseDialog = (event:any, reason:string) => {
    if (reason == 'backdropClick') {
      return;
    }
    setOpenDialog(false);
  };

  const handleClickCancel = (created?: boolean) => {
    setOpenDialog(false);
    if(created){
      fetchData();
    }
  }

  const handleOnEditItem = (index:number) => {
    console.log(users[index]);
    setUserData(users[index]);
    setOpenDialog(true);
  };

  const handleUpdateUser = async(data: UserModel)=>{
    console.log(`handleSaveUser ${JSON.stringify(data)}`);
    setIsLoading(true);
    let _user: any;
    if(data.id){
      console.log('user id:', data.id);
      _user = await updateUsers(data);
    } else {
      _user = await createUsers(data);
    }

    console.log(`Created User ${JSON.stringify(_user)}`)
    if(_user && !_user.statusCode){
      setIsCreateUserSuccess(true);
      setOpenDialog(false);
      fetchData();
      handleOpenSnackbar(true);
    } else {
      handleOpenSnackbar(false);
    }
    setIsLoading(false);
  }

  const handleOpenSnackbar = (success: boolean) =>{
    if(success){
      setPropSnackbarOpen({
        open: true, 
        type: "success", 
        message: userData? 'Update User Success' : "Create New User Success!"
      });
    }else{
      setPropSnackbarOpen({
        open: true, 
        type: "error", 
        message: userData? 'Update User Failed' : "Create New User Failed!"
      });
    }

    setTimeout(() => {
      setPropSnackbarOpen({ open: false, type: "success", message: "" });
    }, 3000);
  }

  const handleDeleteUser = async (id: number) => {
    setIsLoading(true);
    const result = await deleteUsers(id);
    if (result) {
      fetchData();
    }
    setIsLoading(false);
  };

  const handleClickConfirmDialog = (id: number, name: string) => {
    setPropDialogOpen({
      open: true, 
      title: 'Delete User', 
      name: name,
      content: 'Do you want to delete the', 
      confirmText: 'Delete', 
      cancelText: 'Cancel',
      onCancel: () => {
        setPropDialogOpen({open: false, title: '', name: '', content: '', confirmText: '', cancelText: '', onCancel: () => {}, onConfirm: () => {}});
      },
      onConfirm: () => {
        handleDeleteUser(id);
        setPropDialogOpen({open: false, title: '', name: '', content: '', confirmText: '', cancelText: '', onCancel: () => {}, onConfirm: () => {}});
      }
    });
  };

  const handlePageChange = (page: number) =>{
    setPage(page);
    console.log('page', page);
  };

  useEffect(() => {

  }, [page]);

  const handleSearchUser = async (e:any) => {
    e.preventDefault();
    
    fetchData();
  };

  const handleClearSearchUser = () =>{
    setEmail('');
    setOrganization('');
    setName('');
    setRole('');
    setOnClear(true);
  };

  useEffect(() => {
    if(onClear){
      fetchData();
      setOnClear(false);
    }
  }, [onClear]);

  const handleSendEmail = async (email: string) =>{
    setPropDialogOpen({
      open: true, 
      title: 'Resend Email', 
      name: email,
      content: 'Do you want to resend email to', 
      confirmText: 'Resend', 
      cancelText: 'Cancel',
      onCancel: () => {
        setPropDialogOpen({open: false, title: '', name: '', content: '', confirmText: '', cancelText: '', onCancel: () => {}, onConfirm: () => {}});
      },
      onConfirm: async() => {
        setPropDialogOpen({open: false, title: '', name: '', content: '', confirmText: '', cancelText: '', onCancel: () => {}, onConfirm: () => {}});
        setIsLoading(true);
        const result = await resendEmail(email);
        if(result && !result.error){
          setPropSnackbarOpen({open: true, type: "success", message: "Send Email Success!"});
        }else{
          setPropSnackbarOpen({open: true, type: "error", message: "Send Email Failed!"});
        }
        setIsLoading(false);
      }
    });
  }

  const handleSwitchChangeActive = async (e: any, userData: UserModel) => {
    const data = {
      id: userData.id,
      email: userData.email,
      organization: userData.organization,
      name: userData.name,
      role: userData.role,
      active: e.target.checked,
      password: userData.password,
      confirmPassword: userData.confirmPassword,
      error: "",
      message: "",
      statusCode: 0,
    }
    setIsLoading(true);
    const result = await updateUsers(data);
    if (result) {
      fetchData();
      setPropSnackbarOpen({open: true, type: "success", message: "Update User Success!"});
    }else{
      setPropSnackbarOpen({open: true, type: "error", message: "Update User Failed!"});
    }
    setIsLoading(false);
  }

  return (
    <Layout propSnackbar={propSnackbarOpen} propDialog={propDialogOpen} isLoading={isLoading}>
      <Box>
        <Dialog
          fullWidth={fullWidth}
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              position: "fixed",
              display: "flex",
              alignItems: "flex-start",
              top: "0",
              borderRadius: "10px",
              maxWidth: '740px'
            },
          }}
        >
          <UserDetail userData={userData} onUpdateUser={handleUpdateUser} onCloseDialog={handleClickCancel} openSnackbar={handleOpenSnackbar}></UserDetail>
        </Dialog>

        <Grid container spacing={2} style={{ marginBottom: '24px'}}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" color="inherit" noWrap style={{fontWeight: 'bold'}}>
              Users
            </Typography>
            <Typography variant="body1" color="inherit" noWrap>
              User
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right", alignSelf:'center'}}>
            <Button
              fullWidth={isSmallScreen}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleClickOpenDialog}
              style={{
                backgroundColor: COLOR.Primary,
                fontWeight: "bold",
                textTransform: "none",
                paddingTop: '13px',
                paddingBottom: '13px',
                boxShadow: 'none',
                borderRadius: '6px',
                fontSize: '17px'
              }}
            >
              Create User
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="email"
              label="Email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset":{
                    borderColor: COLOR.Primary,
                  }
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                '& fieldset': {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="organization"
              label="Organization"
              name="organization"
              onChange={(e) => setOrganization(e.target.value)}
              value={organization}
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset":{
                    borderColor: COLOR.Primary,
                  }
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                '& fieldset': {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
              />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="name"
              label="name"
              name="name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset":{
                    borderColor: COLOR.Primary,
                  }
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                '& fieldset': {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{mt: '2px'}}>
          <Grid item xs={12} md={4}>
            <FormControl 
              style={{width: '100%'}}
              variant="outlined"
              sx={{
                backgroundColor: COLOR.White,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset":{
                    borderColor: COLOR.Primary,
                  }
                },
                "& label.Mui-focused": {
                  color: COLOR.Primary,
                },
                '& fieldset': {
                  borderColor: COLOR.Border,
                  borderRadius: '6px'
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Role
              </InputLabel>
              <Select
                label='Role'
                value={role}
                onChange={(e: SelectChangeEvent) => setRole(e.target.value)}
                MenuProps={{
                  sx:{
                    mt: '4px',
                    '& ul': { bgcolor: COLOR.White },
                  }
                }}
              >
                <MenuItem value={'super_admin'}  >Super Admin</MenuItem>
                <MenuItem value={'admin'} >Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              style={{
                backgroundColor: COLOR.Primary,
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: 'none',
                borderRadius: '6px',
                paddingTop:'16px',
                paddingBottom:'16px',
                width:'100%'
              }}
              onClick={handleSearchUser}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="outlined"
              style={{
                backgroundColor: COLOR.White,
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: 'none',
                borderRadius: '6px',
                paddingTop:'15px',
                paddingBottom:'15px',
                width:'100%',
                borderColor: COLOR.Primary,
                color: COLOR.Primary
              }}
              onClick={handleClearSearchUser}
            >
              Clear
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} style={{ marginTop: "12px", borderRadius: '0px', boxShadow: 'none' }}>
          <Table sx={{ minWidth: 650, borderBottom: '1px solid #D8D8D8' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{width: '15%'}} align="center">Email</StyledTableCell>
                <StyledTableCell style={{width: '15%'}} align="center">Organization</StyledTableCell>
                <StyledTableCell style={{width: '17%'}} align="center">Name</StyledTableCell>
                <StyledTableCell style={{width: '12%'}} align="center">Role</StyledTableCell>
                <StyledTableCell style={{width: '12%'}} align="center">Created By</StyledTableCell>
                <StyledTableCell style={{width: '10%'}} align="center">Active</StyledTableCell>
                <StyledTableCell style={{width: '12%'}} align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users ? (
                users.map((row, index) => (
                  <StyledTableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell component="th" style={{maxWidth: 170, wordWrap:'break-word'}} align="center">{row.email}</StyledTableCell>
                    <StyledTableCell style={{maxHeight: '200px'}} align="left">
                      <Typography
                        style={{
                          maxWidth: 200,
                          fontSize: "14px",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                        }}
                      >
                        {row.organization}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{maxWidth: 200, wordWrap:'break-word', textOverflow: 'ellipsis'}} align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">{row.role == 'admin' ? "Admin" : "Super Admin"}</StyledTableCell>
                    <StyledTableCell align="left">{row.createdUser? row.createdUser.name :'-'}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Switch
                        checked={row.active}
                        onChange={(e) => handleSwitchChangeActive(e, row)}
                        inputProps={{ "aria-label": "controlled" }
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell 
                      align="center" 
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: '25px'
                      }}
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleOnEditItem(index)}
                      >
                        <EditIcon style={{color: COLOR.Primary}} />
                      </IconButton>
                      <IconButton
                        aria-label="email"
                        onClick={() => handleSendEmail(row.email)}
                      >
                        <EmailIcon style={{color: COLOR.Secondary}} />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          count={totalPage}
          style={{
            display:'flex',
            marginTop: "24px",
            justifyContent: "flex-end",
          }}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiPaginationItem-root": {
              '&.Mui-selected': {
                color: COLOR.White,
                backgroundColor: COLOR.Primary,
              }
            },
          }}
          onChange={(event, page) => handlePageChange(page)}
          defaultPage={1}
        />
      </Box>
    </Layout>
  );
}
