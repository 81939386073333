import { useState } from "react";

export default function UseToken() {
  const getToken = () => {
    console.log(`getToken Call`);
    const tokenString = sessionStorage.getItem("token");
    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      console.log(`getToken userToken ${userToken}`);
      return userToken;
    }
    return "";
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: any) => {
    sessionStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
  };

  const clearToken = () => {
    sessionStorage.removeItem("token");
    setToken("");
  };

  return {
    setToken: saveToken,
    token,
    getToken: getToken,
    clearToken,
  };
}
