import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { login, resendEmail } from "../services/api";
import { APP_ROUTES, COLOR } from "../utils/constants";
import { AppBar } from "@mui/material";
import logo from "../assets/images/logo_thaihealth.png";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorIcon from "@mui/icons-material/Error";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Kanit", "sans-serif"].join(","),
  },
});
type ForgotPasswordProp = {
  setToken: Function;
};
export default function ForgotPassword({ setToken }: ForgotPasswordProp) {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
  const [isSendEmailSuccess, setIsSendEmailSuccess] = useState<boolean>(false);

  const [emailFocused, setEmailFocused] = useState<boolean>(false);

  const [emailToResetPassword, setEmailToResetPassword] = useState<string>("");
  const [emailToResetPasswordError, setEmailToResetPasswordError] =
    useState<string>("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    console.log("validate...");
    const checkEmail: boolean = validateEmail();
    const checkPassword: boolean = validatePassword();

    if (checkEmail && checkPassword) {
      console.log("handleSubmit");
      const token = await login({
        //username -> email
        email: email,
        password: password,
        id: 0,
        confirmPassword: "",
        name: "",
        role: "",
        organization: "",
        active: true,
        error: "",
        message: "",
        statusCode: 0
      });
      if (token && token.statusCode) {
        console.log("login failed.");
        setEmailError("Email or password is wrong.");
        setPasswordError("Email or password is wrong.");
        return;
      } else {
        setToken(token);
        navigate(APP_ROUTES.MEETING);
      }
    } else {
      console.log("validate email or password failed.");
      return;
    }
  };

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError("Email is invalid");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validatePassword = () => {
    if (password.length < 8) {
      setPasswordError("Password is wrong");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const handleClearEmail = () => {
    setEmail("");
    setEmailError("");
    console.log(email);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const styles = {
    root: {
      display: "flex",
      height: "100%",
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
      padding: '60px 0',
    },
    container: {
      display: "flex",
      height: "100%",
      alignItems: "center",
      marginTop: "60px",
      maxWidth: "950px",
      width: "950px",
    },
    bodyBox: {
      backgroundColor: COLOR.White,
      display: "flex",
      height: 'auto',
      flexDirection: "column",
      alignItems: "center",
      padding: "80px 0",
      borderRadius: "20px",
      boxShadow: "0 3px 5px rgba(0,0,0,0.25)",
      width: "100%",
    },
  };

  const handleResetPassword = async(e: any) => {
    e.preventDefault();
    console.log("handle reset password");
    if (emailToResetPassword === "") {
      setEmailToResetPasswordError(
        "Email must be in format (Ex. yourname@example.com)"
      );
    } else {
      //send email to reset password
      setIsSendEmailSuccess(true);
      await resendEmail(emailToResetPassword);
    }
  };

  const validateEmailToResetPassword = (value: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(value)) {
      setEmailToResetPasswordError(
        "Email must be in format (Ex. yourname@example.com)"
      );
    } else {
      setEmailToResetPasswordError("");
    }
  };

  const handleClearEmailToResetPassword = () => {
    setEmailToResetPassword("");
    setEmailToResetPasswordError("");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box 
        style={{ 
          display: "block", 
          height: '100vh',
          width: '100vw',
          backgroundColor: COLOR.Hovered,
          minHeight: '700px',
      }}
      >
        <Box sx={styles.root} component="main">
          <AppBar elevation={0}>
            <Box
              style={{
                display: "flex",
                backgroundColor: COLOR.White,
                boxShadow: "0 3px 5px rgba(0,0,0,0.15)",
              }}
            >
              <img src={logo} style={{ height: "60px", marginLeft: "20px" }} />
            </Box>
          </AppBar>
          <Container sx={styles.container}>
            <CssBaseline />
            <Box sx={styles.bodyBox}>
              <img src={logo} style={{ height: "90px" }} />
              {!isSendEmailSuccess && (
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ fontWeight: "bold" }}
                  sx={{ mt: "40px", mb: 0 }}
                >
                  Forgot password
                </Typography>
              )}
              <Box
                  style={{
                    // width: '70%',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  sx={{
                    mt: 1,
                    ml: 1,
                    mr: 1,
                    width: "100%",
                    px: { lg: "193px", md: "55px", xs: "24px" },
                  }}
                >
                  {isSendEmailSuccess && (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MarkEmailUnreadIcon
                          style={{ fontSize: "53px", color: COLOR.Success }}
                          sx={{ mt: "40px", mb: "26px" }}
                        />
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "bold", color: COLOR.Success }}
                        >
                          You're almost done! Check your email
                        </Typography>
                      </Box>
                    </>
                  )}
                  <Typography variant="body2" sx={{ mt: "40px" }} align='center'>
                    {isSendEmailSuccess
                      ? `Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder or click here to request a new link.`
                      : `Please enter your email address. We'll send you a link to reset your password.`}
                  </Typography>
                  {!isSendEmailSuccess && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="emailToResetPassword"
                      label="Email"
                      name="emailToResetPassword"
                      value={emailToResetPassword}
                      autoComplete="emailToResetPassword"
                      onChange={(e) => {
                        setEmailToResetPassword(e.target.value);
                        validateEmailToResetPassword(e.target.value);
                      }}
                      autoFocus
                      error={!!emailToResetPasswordError}
                      helperText={emailToResetPasswordError}
                      onFocus={() => setEmailFocused(true)}
                      onBlur={() => setEmailFocused(false)}
                      InputLabelProps={{
                        style:{
                          color: !!emailToResetPasswordError ? COLOR.Error : emailFocused ? COLOR.Focused : COLOR.PlaceHolder,
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: COLOR.White,
                          "&.Mui-focused fieldset": {
                            borderColor: emailToResetPasswordError? COLOR.Error : COLOR.Primary,
                          },
                          "&:hover fieldset": {
                            borderColor: emailToResetPasswordError? COLOR.Error : COLOR.Border,
                          },
                          "&.Mui-focused:hover fieldset":{
                            borderColor: emailToResetPasswordError? COLOR.Error : COLOR.Primary,
                          }
                        },
                        "& label.Mui-focused": {
                          color: emailToResetPasswordError? COLOR.Error : COLOR.Primary,
                        },
                        '& fieldset': {
                          borderColor: emailToResetPasswordError? COLOR.Error : COLOR.Border,
                          borderRadius: '6px'
                        },
                        '& .Mui-error':{
                          '& .MuiOutlinedInput-notchedOutline':{
                            borderColor: COLOR.Error,
                          },
                        },
                        '& .MuiFormHelperText-root.Mui-error':{
                          color: COLOR.Error,
                        },
                        "& .MuiFormLabel-asterisk":{
                          color: !!emailToResetPasswordError ? COLOR.Error : emailFocused ? COLOR.Focused : COLOR.PlaceHolder,
                        }
                      }}
                      style={{ marginTop: "40px", marginBottom: 0 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {emailToResetPasswordError ? (
                              <ErrorIcon style={{ color: COLOR.Error }} />
                            ) : (
                              <>
                                {emailToResetPassword && (
                                  <IconButton
                                    edge="end"
                                    onClick={() =>
                                      handleClearEmailToResetPassword
                                    }
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: COLOR.PlaceHolder
                                    }}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                )}
                              </>
                            )}
                          </InputAdornment>
                        ),
                        error: !!emailToResetPasswordError,
                      }}
                    />
                  )}
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: "40px", mb: 2, padding: "13px 0" }}
                    onClick={(e) => {
                      if (isSendEmailSuccess) {
                        navigate(APP_ROUTES.LOGIN);
                      } else {
                        handleResetPassword(e);
                      }
                    }}
                    style={{
                      fontWeight: "bold",
                      backgroundColor: COLOR.Primary,
                      textTransform: "none",
                      boxShadow: "none",
                      fontSize: "17px",
                      borderRadius: '6px'
                    }}
                  >
                    {isSendEmailSuccess
                      ? "Back to login page"
                      : "Reset password"}
                  </Button>
                </Box>
              <Box
                style={{
                  // width: '70%',
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                sx={{
                  mt: 1,
                  ml: 1,
                  mr: 1,
                  width: "100%",
                  px: { lg: "193px", md: "55px", xs: "24px" },
                }}
              >
                
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
