import axios from "axios";
import { APP_ROUTES } from "../utils/constants";
import { Agenda, ExerciseAgenda, ExerciseVideo, Meeting, User } from "./models";

const BASE_API_URL = process.env.REACT_APP_API_URL;

const getToken = (): Promise<string> => {
  console.log(`getToken Call`);
  const tokenString = sessionStorage.getItem("token") ?? "";
  const userToken = JSON.parse(tokenString);
  console.log(`getToken userToken ${userToken}`);
  return userToken;
};

export async function login(user: User): Promise<any | null> {
  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/auth/login",
      data: {
        //username -> email
        email: user.email,
        password: user.password,
      },
    });
    if (!response?.data?.access_token) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data.access_token : null;
  } catch (err:any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    return err.response.data;
  }
}

//User API
export async function createUsers(user: User): Promise<any> {
  console.log(`api createUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);
  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/users",
      data: user,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err?.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getUsers(): Promise<User[] | null> {
  console.log(`api getUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/users",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getUsersByPage(
  limit: number,
  page: number,
  email: string,
  organization: string,
  name: string,
  role: string
) {
  console.log(`api getUsersByPage()`);
  const token = getToken();
  console.log(`api token ${token}`);

  console.log("email", email);
  console.log("organization", organization);
  console.log("name", name);
  console.log("role", role);

  try {
    const response = await axios({
      method: "get",
      url:
        BASE_API_URL +
        "/users/paginate?limit=" +
        limit +
        "&page=" +
        page +
        "&filter.email=$ilike:" +
        email +
        "&filter.organization=$ilike:" +
        organization +
        "&filter.name=$ilike:" +
        name +
        "&filter.role=$" + (role === 'super_admin'? 'eq' : role === 'admin'? 'eq' : 'ilike') + ":" +
        role,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getUserByLogin(): Promise<User | null> {
  console.log(`api getUserByLogin()`);
  const token = getToken();
  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/users/my-profile",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getUserById(id: number): Promise<User[] | null> {
  console.log(`api getUserById()`);
  const token = getToken();
  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/users/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function updateUsers(user: User): Promise<User | null> {
  console.log(`api updateeUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);
  console.log(`api user ${user}`);

  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/users/" + user.id,
      data: user,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function updatePassword(user: User): Promise<User | null> {
  console.log(`api updatePassword()`);
  const token = getToken();
  console.log(`api token ${token}`);
  const data = {
    password: user.password,
    newpassword: user.confirmPassword,
  };
  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/users/my-profile/change-password",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return null;
  }
}

export async function resetPassword(
  password: string,
  email: string,
  token: string
) {
  console.log(`api resetPassword()`);
  const data = {
    email: email,
    token: token,
    password: password,
  };
  try {
    console.log('email in api', email)
    console.log('token in api', token)
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/auth/change-password",
      data: data,
    });

    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    return err.response.data;
  }
}

export async function resendEmail(email: string) {
  console.log(`api resnedEmail()`);
  const data = {
    email: email,
  };
  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/auth/resend-change-password",
      data: data,
    });

    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function deleteUsers(id: number): Promise<boolean | null> {
  console.log(`api deleteUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "delete",
      url: BASE_API_URL + "/users/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

//Meeting API
export async function getMeeting(): Promise<Meeting[] | null> {
  console.log(`api getMeeting()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/meetings",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getMeetingByPage(
  limit: number,
  page: number,
  title: string,
  zoomMeetingID: string,
  status: string
) {
  console.log(`api getMeetingByPage()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url:
        BASE_API_URL +
        "/meetings/paginate?page=" +
        page +
        "&limit=" +
        limit +
        "&filter.title=$ilike:" +
        title +
        "&filter.zoomMeetingID=$ilike:" +
        zoomMeetingID +
        "&filter.status=$ilike:" +
        status,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getMeetingByID(id: number): Promise<Meeting | null> {
  console.log(`api getUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/meetings/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function createMeeting(meeting: Meeting): Promise<Meeting | null> {
  console.log(`api createMeeting()`);
  const token = getToken();
  console.log(`api token ${token}`);
  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/meetings",
      data: meeting,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function updateMeeting(meeting: Meeting): Promise<Meeting | null> {
  console.log(`api updateMeeting()`);
  const token = getToken();
  console.log(`api token ${token}`);
  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/meetings/" + meeting.id,
      data: meeting,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function deleteMeeting(id: number): Promise<boolean | null> {
  console.log(`api createUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "delete",
      url: BASE_API_URL + "/meetings/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return false;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return false;
  }
}

//Agenda API
export async function getAgendaByMeeting(
  meetingID: number
): Promise<Agenda[] | null> {
  console.log(`api getUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/meetings/" + meetingID + "/agendas",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function createAgenda(agenda: Agenda): Promise<Agenda | null> {
  console.log(`api createAgenda()`);
  const token = getToken();
  console.log(`api token ${token}`);
  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/meetings/" + agenda.meetingID + "/agendas",
      data: agenda,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function updateAgenda(agenda: Agenda): Promise<Agenda | null> {
  console.log(`api updateAgenda()`);
  const token = getToken();
  console.log(`api token ${token}`);
  try {
    const response = await axios({
      method: "post",
      url:
        BASE_API_URL + "/meetings/" + agenda.meetingID + "/agendas/" + agenda.id,
      data: agenda,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function deleteAgenda(
  meetingId: number,
  agendaId: number
): Promise<boolean | null> {
  console.log(`api deleteAgenda()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "delete",
      url: BASE_API_URL + "/meetings/" + meetingId + "/agendas/" + agendaId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return false;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return false;
  }
}

export async function getAllExerciseVideo(): Promise<ExerciseVideo[] | null> {
  console.log(`api getAllExerciseVideo()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/exercise-videos",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    if (response && response.data) {
      response.data.forEach((el: ExerciseVideo) => {
        if (el.videoPath) {
          el.videoUrl = `${BASE_API_URL}/exercise-videos/file/${el.id}/stream`;
        }
      });
      return response.data;
    }
    return null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getExerciseVideoByPage(
  limit: number,
  page: number,
  title: string,
  typeVideo: string
) {
  console.log(`api getExerciseVideoByPage()`);
  const token = getToken();
  console.log(`api token ${token}`);

  console.log("typeVideo", typeVideo);
  const filterCondition = typeVideo === 'uploadVideo' ? "&filter.videoPath=$not:$eq:" : typeVideo === 'youtubeUrl' ? "&filter.youtubeUrl=$not:$eq:" : "";

  try {
    const response = await axios({
      method: "get",
      url:
        BASE_API_URL + "/exercise-videos/paginate?limit=" + limit + "&page=" + page + "&filter.title=$ilike:" + 
        (title ? title : "") + filterCondition,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    if (response && response.data) {
      response.data.data.forEach((el: ExerciseVideo) => {
        if (el.videoPath) {
          el.videoUrl = `${BASE_API_URL}/exercise-videos/file/${el.id}/stream`;
        }
      });
      return response.data;
    }
    return null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getThumbnailExerciseVideo(id: number) {
  console.log(`api getThumbnailExerciseVideo()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/exercise-videos/thumbnail/" + id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return "";
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url ? url : "";
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return "";
  }
}

export async function createVideoThumbnail(
  videoThumbnail: File,
  videoId: number
): Promise<string> {
  console.log(`api createVideoThumbnail()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    console.log("-----------------------------");
    console.log("videoThumbnail in api", videoThumbnail);
    const formData = new FormData();
    formData.append("thumbnail", videoThumbnail);
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/exercise-videos/thumbnail/" + videoId,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return "";
    }

    console.log("response.data", response.data);
    return response.data ? response.data : "";
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return "";
  }
}

export async function getPublicExerciseVideo(): Promise<
  ExerciseVideo[] | null
> {
  console.log(`api getPublicExerciseVide()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/exercise-videos/public",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    if (response && response.data) {
      response.data.forEach((el: ExerciseVideo) => {
        if (el.videoPath) {
          el.videoUrl = `${BASE_API_URL}/exercise-videos/file/${el.id}/stream`;
        }
      });
      return response.data;
    }
    return null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function updatePublicExerciseVideo(
  publicVideo: boolean,
  videoId: number
) {
  console.log(`api getPublicExerciseVide()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "post",
      url: BASE_API_URL + "/exercise-videos/public/" + videoId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        isPublic: publicVideo,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getAllExerciseVideoByID(
  exerciseVideoID: number
): Promise<ExerciseVideo | null> {
  console.log(`api getAllExerciseVideoByID()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/exercise-videos/" + exerciseVideoID,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function createExerciseVideo(
  exerciseVideo: ExerciseVideo
): Promise<ExerciseVideo | null> {
  console.log(`api createExerciseVideo()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    let response: any;
    if (exerciseVideo.youtubeUrl) {
      response = await axios({
        method: "post",
        url: BASE_API_URL + "/exercise-videos/youtube",
        data: exerciseVideo,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      const formData = new FormData();
      formData.append("video", exerciseVideo.fileData);
      formData.append("title", exerciseVideo.title);
      formData.append("isPublic", exerciseVideo.isPublic + "");
      formData.append("duration", exerciseVideo.duration + "");
      response = await axios({
        method: "post",
        url: BASE_API_URL + "/exercise-videos/file",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    }

    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function updateExerciseVideo(
  exerciseVideo: ExerciseVideo,
  videofileData: File,
  youtubeUrl: string
): Promise<ExerciseVideo | null> {
  console.log(`api updateExerciseVideo()`);
  const token = getToken();
  console.log(`api token ${token}`);
  ///exercise-video/youtube
  ///exercise-video/file
  console.log("exerciseVideo", exerciseVideo);
  console.log("videofileData", videofileData);
  console.log("youtubeUrl", youtubeUrl);
  console.log("exerciseVideo.videoPath", exerciseVideo.videoPath);
  try {
    let response: any;
    if (
      (youtubeUrl !== "" || youtubeUrl !== null) &&
      (videofileData === null || videofileData === undefined) &&
      (exerciseVideo.videoPath === "" || exerciseVideo.videoPath === null)
    ) {
      console.log("youtube url");
      response = await axios({
        method: "post",
        url: BASE_API_URL + "/exercise-videos/youtube/" + exerciseVideo.id,
        data: exerciseVideo,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      console.log("upload video");
      const formData = new FormData();
      formData.append("video", exerciseVideo.fileData);
      formData.append("isPublic", exerciseVideo.isPublic + "");
      formData.append("title", exerciseVideo.title);
      formData.append("duration", exerciseVideo.duration + "");
      formData.append("active", exerciseVideo.active + "");
      response = await axios({
        method: "post",
        url: BASE_API_URL + "/exercise-videos/file/" + exerciseVideo.id,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    }

    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    console.log("response", response);
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function deleteExerciseVideo(
  exerciseVideoID: number
): Promise<boolean | null> {
  console.log(`api deleteExerciseVideo()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "delete",
      url: BASE_API_URL + "/exercise-videos/delete/" + exerciseVideoID,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return false;
    }
    return response ? response.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return false;
  }
}

export async function getExerciseAgendaByAgendaID(
  meetingID: number,
  agendaID: number
): Promise<ExerciseAgenda[] | null> {
  console.log(`api getUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url:
        BASE_API_URL +
        "/meetings/" +
        meetingID +
        "/agendas/" +
        agendaID +
        "/exercises",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function createExerciseAgenda(
  meetingID: number,
  agendaID: number,
  exerciseAgenda: ExerciseAgenda
): Promise<ExerciseAgenda | null> {
  console.log(`api getUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "post",
      url:
        BASE_API_URL +
        "/meetings/" +
        meetingID +
        "/agendas/" +
        agendaID +
        "/exercises",
      data: exerciseAgenda,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function deleteExerciseAgenda(
  meetingID: number,
  agendaID: number,
  exerciseAgendaID: number
): Promise<ExerciseAgenda[] | null> {
  console.log(`api getUsers()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "delete",
      url:
        BASE_API_URL +
        "/meetings/" +
        meetingID +
        "/agendas/" +
        agendaID +
        "/exercises/" +
        exerciseAgendaID,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}

export async function getOrganizations(): Promise<[] | null> {
  console.log(`api getOrganization()`);
  const token = getToken();
  console.log(`api token ${token}`);

  try {
    const response = await axios({
      method: "get",
      url: BASE_API_URL + "/users/organizations",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response?.data) {
      console.log("Something went wrong during signing in: ", response);
      return null;
    }
    return response ? response?.data.data : null;
  } catch (err: any) {
    console.log("getAuthenticatedUser, Something Went Wrong", err);
    if (err.response.data.statusCode === 401)
      window.location.href = APP_ROUTES.LOGIN;
    return err.response.data;
  }
}
