import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { User } from "../services/models";
import IconButton from "@mui/material/IconButton";
import { getUserByLogin, getOrganizations } from "../services/api";
import {
  Divider,
  FormHelperText,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { COLOR } from "../utils/constants";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
// import {escape} from 'lodash';

type UserDetailProp = {
  userData: User | null;
  onCloseDialog: Function;
  openSnackbar: Function;
  onUpdateUser: Function;
};

export default function UserDetail({
  userData,
  onCloseDialog,
  openSnackbar,
  onUpdateUser,
}: UserDetailProp) {
  const [saveText, setSaveText] = useState<string>("Save");
  const [title, setTitle] = useState<string>("Create User");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [roleError, setRoleError] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [organizationError, setOrganizationError] = useState<string>("");
  const [createSuccess, setCreateSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const organizationRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const roleRef = useRef<HTMLSelectElement>(null);

  const [emailFocused, setEmailFocused] = useState<boolean>(false);
  const [organizationFocused, setOrganizationFocused] = useState<boolean>(false);
  const [nameFocused, setNameFocused] = useState<boolean>(false);
  const [roleFocused, setRoleFocused] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [allOrganization, setAllOrganization] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  //check edit profile
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [userProfileId, setUserProfileId] = useState<number>(0);

  const [active, setActive] = useState<boolean>(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    console.log("validate...");
    if (email.trim() === "") {
      setEmailError("Email mumst be in format (Ex. yourname@example.com)");
      if(emailRef.current){
        emailRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
    if (organization.trim() === "") {
      setOrganizationError(
        "Organization must be more than 5 characters and no more than 50 characters"
      );
      if(email.trim() !== ''){
        if(organizationRef.current){
          organizationRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }
    }
    if (name.trim() === "") {
      setNameError(
        "Name must be more than 5 characters and no more than 50 characters"
      );
      if(email.trim() !== '' && organization.trim() !== ''){
        if(nameRef.current){
          nameRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }
    }
    if (role === "") {
      setRoleError("Select a role");
      if(email.trim() !== '' && organization.trim() !== '' && name.trim() !== ''){
        if(roleRef.current){
          roleRef.current.focus();
        }
      }
    }

    if (
      email.trim() !== "" &&
      organization.trim() !== "" &&
      name.trim() !== "" &&
      role.trim() !== ""
    ) {
      console.log("handleSubmit");
      
      setIsLoading(true);

      if (userId !== 0) {
        await onUpdateUser({
          email,
          password: "",
          confirmPassword: "",
          organization,
          name,
          role,
          id: userId,
          active: active,
        });
      } else {
        await onUpdateUser({
          email,
          password: "",
          confirmPassword: "",
          organization,
          name,
          role,
          id: 0,
          active: true,
          error: "",
          message: "",
          statusCode: 0,
        });
      }
      setIsLoading(false);
    }
  };

  const handleChangeRole = (event: any) => {
    setRole(event.target.value);
    setRoleError("");
  };

  const validateName = (value: string) => {
    if (value.match(/^[\u0E00-\u0E7F0-9a-zA-Z ']+$/)) {
      setName(value);
      if (value.trim().length < 5 || value.trim().length > 50) {
        setNameError(
          "Name must be more than 5 characters and no more than 50 characters"
        );
      } else {
        setNameError("");
      }
    }else{
      setNameError("Name must be only characters");
    }
  };

  const validateOrganization = (value: string) => {
    console.log("organization", organization);
    if (value.trim().length < 5 || value.trim().length > 50) {
      setOrganizationError(
        "Organization must be more than 5 characters and no more than 50 characters"
      );
    } else {
      setOrganizationError("");
    }
  };

  const validateEmail = (value: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(value.trim())) {
      setEmailError("Email mumst be in format (Ex. yourname@example.com)");
    } else {
      if(value.trim().length < 5 || value.trim().length > 50){
        setEmailError("Email must be more than 5 characters and no more than 50 characters");
      }else{
        setEmailError("");
      }
    }
  };

  const fetchUser = async () => {
    const user = await getUserByLogin();
    setUserProfileId(user!.id);

    if (userData && userData.id) {
      if (userData.id === user!.id) {
        setEditProfile(true);
      } else {
        setEditProfile(false);
      }
      setEditMode(true);
      setUserId(userData.id);
      setEmail(userData.email);
      setName(userData.name);
      setRole(userData.role);
      setOrganization(userData.organization);
      setActive(userData.active);
      setSaveText("Save");
      setTitle("Update User");

      setEmailError("");
      setNameError("");
      setRoleError("");
      setOrganizationError("");
    }
  };

  const fetchOrganization = async () => {
    const organizations = await getOrganizations();
    console.log("organizations", organizations);
    setAllOrganization(organizations ? organizations : []);
  };

  useEffect(() => {
    if (userData && userData.id) {
      fetchUser();
    } else {
      setEditMode(false);
      setSaveText("Add");
      setTitle("Create User");
    }
    fetchOrganization();
    setCreateSuccess(false);
  }, [userProfileId]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ my: "24px" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <IconButton
            onClick={() => (isLoading ? null : onCloseDialog())}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
            }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
        <Divider style={{ marginTop: "16px", marginBottom: "32px" }} />

        {!createSuccess ? (
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              required
              fullWidth
              disabled={isLoading}
              inputRef={emailRef}
              id="emailAddress"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
              error={!!emailError}
              helperText={emailError}
              onFocus={() => setEmailFocused(true)}
              onBlur={() => setEmailFocused(false)}
              InputLabelProps={{
                style:{
                  color: emailError ? COLOR.Error : emailFocused ? COLOR.Focused : COLOR.PlaceHolder,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {email && (
                      <>
                        {emailError ? (
                          <IconButton edge="end" onClick={() => {
                            setEmail("")
                            validateEmail('')
                          }
                            }>
                            <ErrorIcon style={{ color: COLOR.Error }} />
                          </IconButton>
                        ) : (
                          <IconButton edge="end" onClick={() => {
                            setEmail("")
                            validateEmail('');
                            }}>
                            <CancelIcon
                              style={{
                                display: isLoading
                                  ? "none"
                                  : 'flex',
                                justifyContent: "center",
                                alignItems: "center",
                                color: COLOR.PlaceHolder
                              }}
                            />
                          </IconButton>
                        )}
                      </>
                    )}
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: COLOR.White,
                  "&.Mui-focused fieldset": {
                    borderColor: emailError ? COLOR.Error : COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: emailError ? COLOR.Error : COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset": {
                    borderColor: emailError ? COLOR.Error : COLOR.Primary,
                  },
                },
                "& label.Mui-focused": {
                  color: emailError ? COLOR.Error : COLOR.Primary,
                },
                "& fieldset": {
                  borderColor: emailError ? COLOR.Error : COLOR.Border,
                  borderRadius: '6px'
                },
                "& .Mui-error": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLOR.Error,
                  },
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: COLOR.Error,
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
                "& .MuiFormLabel-asterisk":{
                  color: emailError ? COLOR.Error : emailFocused ? COLOR.Focused : COLOR.PlaceHolder,
                },
                mb: 0,
              }}
            />

            <Autocomplete
              id="combo-box-demo"
              options={allOrganization}
              value={organization}
              disabled={isLoading}
              clearIcon={organization ? <CancelIcon style={{color: COLOR.PlaceHolder}} /> : null}
              onInputChange={(event, newInputValue) => {
                setOrganization(newInputValue);
                validateOrganization(newInputValue);
              }}
              onFocus={() => setOrganizationFocused(true)}
              onBlur={() => setOrganizationFocused(false)}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organization"
                  required
                  inputRef={organizationRef}
                  error={!!organizationError}
                  helperText={organizationError}
                  InputLabelProps={{
                    style:{
                      color: organizationError ? COLOR.Error : organizationFocused ? COLOR.Focused : COLOR.PlaceHolder,
                    },
                  }}
                />
              )}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: COLOR.White,
                  "&.Mui-focused fieldset": {
                    borderColor: organizationError
                      ? COLOR.Error
                      : COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: organizationError ? COLOR.Error : COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset": {
                    borderColor: organizationError
                      ? COLOR.Error
                      : COLOR.Primary,
                  },
                },
                "& label.Mui-focused": {
                  color: organizationError ? COLOR.Error : COLOR.Primary,
                },
                "& fieldset": {
                  borderColor: organizationError ? COLOR.Error : COLOR.Border,
                  borderRadius: '6px'
                },
                "& .Mui-error": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLOR.Error,
                  },
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: COLOR.Error,
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
                "& .MuiFormLabel-asterisk":{
                  color: organizationError ? COLOR.Error : organizationFocused ? COLOR.Focused : COLOR.PlaceHolder,
                },
                mt: "32px",
                mb: 0,
              }}
            />

            <TextField
              required
              fullWidth
              disabled={isLoading}
              id="userName"
              label="Name"
              name="name"
              inputRef={nameRef}
              value={name}
              onChange={(e) => {
                validateName(e.target.value);
              }}
              error={!!nameError}
              helperText={nameError}
              onFocus={() => setNameFocused(true)}
              onBlur={() => setNameFocused(false)}
              InputLabelProps={{
                style:{
                  color: nameError ? COLOR.Error : nameFocused ? COLOR.Focused : COLOR.PlaceHolder,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {name && (
                      <>
                        {nameError ? (
                          <IconButton edge="end" onClick={() => setName("")}>
                            <ErrorIcon style={{ color: COLOR.Error }} />
                          </IconButton>
                        ) : (
                          <IconButton edge="end" onClick={() => setName("")}>
                            <CancelIcon
                              style={{
                                display: isLoading
                                  ? "none"
                                  : 'flex',
                                justifyContent: "center",
                                alignItems: "center",
                                color: COLOR.PlaceHolder
                              }}
                            />
                          </IconButton>
                        )}
                      </>
                    )}
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: COLOR.White,
                  "&.Mui-focused fieldset": {
                    borderColor: nameError ? COLOR.Error : COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: nameError ? COLOR.Error : COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset": {
                    borderColor: nameError ? COLOR.Error : COLOR.Primary,
                  },
                },
                "& label.Mui-focused": {
                  color: nameError ? COLOR.Error : COLOR.Primary,
                },
                "& fieldset": {
                  borderColor: nameError ? COLOR.Error : COLOR.Border,
                  borderRadius: '6px'
                },
                "& .Mui-error": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLOR.Error,
                  },
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: COLOR.Error,
                },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: `0 0 0 1000px ${COLOR.White} inset`,
                },
                "& .MuiFormLabel-asterisk":{
                  color: nameError ? COLOR.Error : nameFocused ? COLOR.Focused : COLOR.PlaceHolder,
                },
                mt: "32px",
                mb: 0,
              }}
            />

            <FormControl
              fullWidth
              style={{ margin: "32px 0px" }}
              disabled={isLoading}
              required
              error={!!roleError}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: COLOR.White,
                  "&.Mui-focused fieldset": {
                    borderColor: roleError ? COLOR.Error : COLOR.Primary,
                  },
                  "&:hover fieldset": {
                    borderColor: roleError ? COLOR.Error : COLOR.Border,
                  },
                  "&.Mui-focused:hover fieldset": {
                    borderColor: roleError ? COLOR.Error : COLOR.Primary,
                  },
                },
                "& label.Mui-focused": {
                  color: roleError ? COLOR.Error : COLOR.Primary,
                },
                "& fieldset": {
                  borderColor: roleError ? COLOR.Error : COLOR.Border,
                  borderRadius: '6px'
                },
                "& .Mui-error": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLOR.Error,
                  },
                },
                "& .MuiFormHelperText-root.Mui-error": {
                  color: COLOR.Error,
                },
              }}
            >
              <InputLabel 
                id="demo-simple-select-label"
                style={{
                  color: roleError ? COLOR.Error : roleFocused ? COLOR.Focused : COLOR.PlaceHolder,
                }}
                sx={{
                  "& .MuiFormLabel-asterisk":{
                    color: roleError ? COLOR.Error : roleFocused ? COLOR.Focused : COLOR.PlaceHolder,
                  },
                }}
              >
                Role
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                inputRef={roleRef}
                value={role}
                label="Role"
                onChange={handleChangeRole}
              >
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"super_admin"}>Super Admin</MenuItem>
              </Select>
              {roleError && <FormHelperText>{roleError}</FormHelperText>}
            </FormControl>

            <Grid
              container
              spacing={2}
              direction={isSmallScreen ? "column-reverse" : "row"}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={() => onCloseDialog()}
                  fullWidth
                  disabled={isLoading}
                  variant="outlined"
                  style={{
                    color: COLOR.Primary,
                    textTransform: "none",
                    borderColor: COLOR.Primary,
                    fontWeight: "bold",
                    fontSize: "17px",
                    borderRadius: '6px'
                  }}
                  sx={{ py: "12px" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  variant="contained"
                  style={{
                    color: COLOR.White,
                    backgroundColor: COLOR.Primary,
                    textTransform: "none",
                    fontWeight: "bold",
                    boxShadow: "none",
                    fontSize: "17px",
                    borderRadius: '6px'
                  }}
                  sx={{ py: "13px" }}
                >
                  {saveText}
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{ mt: 1 }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <MarkEmailUnreadIcon
              style={{
                color: COLOR.Success,
                fontSize: "80px",
                margin: "32px 0px",
              }}
            />
            <Typography
              variant="h5"
              style={{ fontWeight: "bold" }}
              sx={{ mb: "32px" }}
            >
              Please check your email
            </Typography>
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", color: COLOR.PlaceHolder }}
            >
              We've sent you a link in your email to set password
            </Typography>
            <Typography
              variant="body1"
              style={{ fontWeight: "bold", color: COLOR.PlaceHolder }}
            >
              just click the link to complete the register process.
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{
                marginBottom: "32px",
                color: COLOR.White,
                backgroundColor: COLOR.Primary,
                textTransform: "none",
                fontWeight: "bold",
                width: "50%",
                boxShadow: "none",
                fontSize: "17px",
                borderRadius: '6px'
              }}
              sx={{ mt: 3, mb: 2, py: "13px" }}
              onClick={() => {
                // setCreateSuccess(false);
                onCloseDialog({ created: true });
              }}
            >
              Okay
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}
